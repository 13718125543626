import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { container } from "../../../views/SocialDevelopment.styles";
import { mr10, mr30, mt35, my30 } from "../../sharedStyles";
import ErasableTextField from "../../ErasableTextField";
import SnackBarNotification from "../../SnackBarNotification";
import canvasService from "../../../service/canvasService";
import AlertDialog from "../../AlertDialog";

export default function IntegrationSetup({ integrationInfo, currentSchool }) {
  const [searchParams] = useSearchParams();
  const [alertDialogState, setAlertDialogState] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const methods = useForm();
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const onSubmit = async (data) => {
    try {
      const response = await canvasService.updateSchoolCanvasIntegration(
        data.canvas_domain,
        data.canvas_sub_account_id,
        data.canvas_root_account_id,
        data.canvas_client_id,
        data.canvas_client_secret,
        data.canvas_redirect_url
      );
      if (response) {
        window.open(
          `${response.canvas_domain}/login/oauth2/auth?client_id=${response.canvas_client_id}&response_type=code&redirect_uri=${response.canvas_redirect_url}`
        );
      }
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const checkExportStatus = async () => {
    const response = await canvasService.getExportStatus();

    if (response.message === "Canvas Data Export Completed") {
      setSnackBarOpen({
        message: "Successfully exported to Canvas",
        severity: "success",
        open: true,
      });
      setInProgress(false);
    } else if (response.message === "Canvas Data Export In Progress") {
      setInProgress(true);
      setTimeout(() => {
        checkExportStatus();
      }, 1000);
    } else {
      setInProgress(false);
    }
  };

  const handleCanvasExport = async () => {
    try {
      const res = await canvasService.exportCsv();

      if (res) {
        checkExportStatus();
      } else {
        setSnackBarOpen({
          message: "Error connecting to Canvas",
          severity: "error",
          open: true,
        });
      }
    } catch (error) {
      setSnackBarOpen({
        message: error.response.data.message || "Error connecting to Canvas",
        severity: "error",
        open: true,
      });
    }
  };

  const handleCanvasDisconnect = async () => {
    try {
      const res = await canvasService.disconnect();

      if (res.message === "Canvas integration disconnected") {
        window.location.reload();
      }
    } catch (error) {
      setSnackBarOpen({
        message: "Error disconnecting school",
        severity: "error",
        open: true,
      });
    }
  };

  const handlePause = async () => {
    try {
      const res = await canvasService.updateSettings({
        canvas_integration_active: !integrationInfo?.canvas_integration_active,
      });

      if (res.message === "Canvas Updated") {
        window.location.reload();
      }
    } catch (error) {
      setSnackBarOpen({
        message: "Error pausing integration",
        severity: "error",
        open: true,
      });
    }
  };

  useEffect(() => {
    checkExportStatus();
  }, []);

  useEffect(() => {
    if (searchParams.get("success")) {
      setSnackBarOpen({
        open: true,
        message: "Canvas integration successful",
        severity: "success",
      });
    }
  }, [searchParams]);

  return (
    <Box sx={{ ...my30, ...mr30, justifyContent: "center" }}>
      <Box sx={[container, { borderTop: "none" }]}>
        <FormProvider {...methods}>
          <form
            style={{ minWidth: "500px" }}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Box sx={mt35} container>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={10}>
                  <ErasableTextField
                    id="canvas_domain"
                    label="Canvas Domain URL"
                    fullWidth
                    isRequired
                    defaultValue={integrationInfo?.canvas_domain}
                    disabled={integrationInfo}
                  />
                </Grid>

                <Grid item xs={10}>
                  <ErasableTextField
                    id="canvas_sub_account_id"
                    label="Canvas Sub Account SIS ID"
                    fullWidth
                    type="text"
                    defaultValue={currentSchool?.canvas_sub_account_id}
                    disabled={integrationInfo}
                    isRequired
                  />
                </Grid>

                <Grid item xs={10}>
                  <ErasableTextField
                    id="canvas_root_account_id"
                    label="Canvas Root Account ID"
                    fullWidth
                    type="text"
                    defaultValue={integrationInfo?.canvas_root_account_id}
                    disabled={integrationInfo}
                    isRequired
                  />
                </Grid>

                <Grid item xs={10}>
                  <ErasableTextField
                    id="canvas_client_id"
                    label="Client ID"
                    fullWidth
                    type="text"
                    defaultValue={integrationInfo?.canvas_client_id}
                    disabled={integrationInfo}
                    isRequired
                  />
                </Grid>

                <Grid item xs={10}>
                  <ErasableTextField
                    id="canvas_client_secret"
                    label="Client Secret"
                    fullWidth
                    type="text"
                    defaultValue={integrationInfo?.canvas_client_secret}
                    disabled={integrationInfo}
                    isRequired
                  />
                </Grid>

                <Grid item xs={10}>
                  <ErasableTextField
                    id="canvas_redirect_url"
                    label="Client Redirect URL"
                    fullWidth
                    type="text"
                    defaultValue={integrationInfo?.canvas_redirect_url}
                    disabled={integrationInfo}
                    isRequired
                  />
                </Grid>

                <Grid item sm={8}>
                  <Stack direction="row">
                    {integrationInfo ? (
                      <Button
                        variant="outline"
                        color="primary"
                        onClick={() => setAlertDialogState(true)}
                      >
                        Disconnect
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        sx={mr10}
                        variant="contained"
                        color="primary"
                        disabled={integrationInfo}
                      >
                        Save & Connect
                      </Button>
                    )}
                    {integrationInfo && (
                      <>
                        <Button
                          variant="outline"
                          color="primary"
                          sx={mr10}
                          disabled={
                            !integrationInfo?.canvas_integration_active ||
                            inProgress
                          }
                          onClick={() => handleCanvasExport()}
                        >
                          {inProgress ? (
                            <span>Exporting...</span>
                          ) : (
                            "Manual Export"
                          )}
                        </Button>
                        <Button
                          variant="outline"
                          color="primary"
                          onClick={() => handlePause()}
                        >
                          {integrationInfo?.canvas_integration_active
                            ? "Pause integration"
                            : "Resume integration"}
                        </Button>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>

        <SnackBarNotification
          message={snackBarOpen.message}
          open={snackBarOpen.open}
          severity={snackBarOpen.severity}
          handleClose={() => setSnackBarOpen(false)}
        />

        <AlertDialog
          isOpen={alertDialogState}
          handleClose={() => setAlertDialogState(false)}
          handleConfirm={() => handleCanvasDisconnect()}
        />
      </Box>
    </Box>
  );
}
