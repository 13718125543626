import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cancelButton, submitButton } from "./FormBuilder.style";
import FieldSet from "./Components/FieldSet/FieldSet";
import FormContainer from "./Components/FormContainer";
import { FormBuilderContext } from "./FormBuilderContext";
import FieldProperties from "./Components/FieldProperties/FieldProperties";
import FormRenderer from "../FormRenderer/FormRenderer";
import admissionFormService from "../../../../service/admissionFormService";
import { Form } from "./Models/Form";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import FormSettings from "./FormSettings";
import {
  admissionFormUnsavedKey,
  sortSectionsAndFields,
} from "./FormBuilderUtils";
import AlertDialog from "../../../AlertDialog";

export default function FormBuilder() {
  const [searchParams, setSearchParams] = useSearchParams();
  const formBuilderContext = useContext(FormBuilderContext);
  const {
    form,
    currentPageIndex,
    updateForm,
    updateCurrentPageIndex,
    updateCurrentSectionIndex,
    unsavedChanges,
    saveChanges,
    selectField,
  } = formBuilderContext;
  const [showPreview, setShowPreview] = useState(false);
  const [alertDialogState, setAlertDialogState] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [formSaving, setFormSaving] = useState(false);
  const snackbarContext = useContext(SnackbarContext);
  const [fieldsMap, setFieldsMap] = useState();
  const [fetchedForm, setFetchedForm] = useState(null);

  const handleKeyDown = (event) => {
    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        // Add cases for other shortcuts you want to block
        case "b": // Ctrl+B or Command+B
        case "i": // Ctrl+I or Command+I
        case "u": // Ctrl+U or Command+U
          event.preventDefault();
          break;
        default:
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    // Get text from clipboard
    const text = (event.clipboardData || window.clipboardData).getData(
      "text/plain"
    );
    // Insert text at current cursor position
    document.execCommand("insertText", false, text);
  };

  useEffect(() => {
    if (searchParams.get("form_id") && !showPreview) {
      (async () => {
        const response = await admissionFormService.fetchForm(
          searchParams.get("form_id")
        );
        response.data.admission_form_pages[0].form_sections =
          sortSectionsAndFields(
            response.data.admission_form_pages[0].form_sections
          );
        const formObj = new Form(response.data);
        updateForm(formObj);
        setFetchedForm(formObj);
        saveChanges();
      })();
    }
  }, [searchParams.get("form_id"), showPreview]);

  const handleConditions = (conditionNodes, currentElement, type) => {
    conditionNodes.forEach((node) => {
      if (node.conditionNodes) {
        handleConditions(node.conditionNodes, currentElement, type);
      }

      if (!node.expression) {
        return;
      }

      const { operand1, operand2 } = node.expression;

      if (type === "validity") {
        if (operand1) {
          const dependee1 = fieldsMap.get(operand1).element;
          dependee1.properties.validityDependents.push(currentElement.fieldId);
          currentElement.properties.validityDependee.push(operand1);
        }
        if (operand2) {
          const dependee2 = fieldsMap.get(operand2).element;
          dependee2.properties.validityDependents.push(currentElement.fieldId);
          currentElement.properties.validityDependee.push(operand2);
        }
      } else if (type === "visibility") {
        if (operand1) {
          const dependee1 = fieldsMap.get(operand1).element;
          dependee1.properties.visibilityDependents.push(
            currentElement.fieldId
          );
          currentElement.properties.visibilityDependee.push(operand1);
        }
        if (operand2) {
          const dependee2 = fieldsMap.get(operand2).element;
          dependee2.properties.visibilityDependents.push(
            currentElement.fieldId
          );
          currentElement.properties.visibilityDependee.push(operand2);
        }
      }
    });
  };

  const saveForm = async (doNotSet) =>
    new Promise((resolve, reject) => {
      form.formPages[currentPageIndex].sections.forEach((section) => {
        section.fields.forEach((field) => {
          handleConditions(
            field.properties.validityCondition,
            field,
            "validity"
          );
          handleConditions(
            field.properties.visibilityCondition,
            field,
            "visibility"
          );
        });
      });
      const transformedData = new Form(form).serialize();
      setFormSaving(true);
      saveChanges();
      if (!form.id) {
        admissionFormService
          .create(transformedData, searchParams.get("school_year"))
          .then((response) => {
            setFormSaving(false);
            response.data.admission_form_pages[0].form_sections =
              sortSectionsAndFields(
                response.data.admission_form_pages[0].form_sections
              );
            if (!doNotSet) updateForm(new Form(response.data));
            searchParams.set("form_id", response.data.id);
            setSearchParams(searchParams);
            snackbarContext.setSnackbar({
              message: "Form saved.",
              severity: "success",
              open: true,
            });
            resolve(response);
          })
          .catch((err) => {
            setFormSaving(false);
            snackbarContext.setSnackbar({
              message: "Failed to save form.",
              severity: "error",
              open: true,
            });
            reject(err);
          });
      } else {
        admissionFormService
          .updateForm(transformedData)
          .then((response) => {
            setFormSaving(false);
            response.data.admission_form_pages[0].form_sections =
              sortSectionsAndFields(
                response.data.admission_form_pages[0].form_sections
              );
            if (!doNotSet) updateForm(new Form(response.data));
            snackbarContext.setSnackbar({
              message: "Form saved.",
              severity: "success",
              open: true,
            });
            resolve(response);
          })
          .catch((err) => {
            setFormSaving(false);
            snackbarContext.setSnackbar({
              message: "Failed to save form.",
              severity: "error",
              open: true,
            });
            reject(err);
          });
      }
    });

  const togglePreview = async () => {
    updateCurrentPageIndex(0);
    updateCurrentSectionIndex(0);
    if (showSettings) {
      setShowSettings(false);
    } else if (!showPreview) {
      await saveForm(true);
      setShowPreview(true);
      updateForm(null);
      form.formPages[currentPageIndex].sections.forEach((section) => {
        section.fields.forEach((field) => {
          handleConditions(
            field.properties.validityCondition,
            field,
            "validity"
          );
          handleConditions(
            field.properties.visibilityCondition,
            field,
            "visibility"
          );
        });
      });
      selectField(null);
    } else {
      updateCurrentPageIndex(0);
      setShowPreview(false);
    }
  };

  const showFormSettings = () => {
    setShowPreview(false);
    setShowSettings(true);
  };

  const updateFormName = (e) => {
    const formUpdate = { ...form };
    formUpdate.name = e.target.textContent;
    updateForm(formUpdate);
  };

  // const changeFormType = (formType) => {
  //   if (formType === FormType.INQUIRY) {
  //     const formData = new Form(inquiryFormPreset);
  //     updateForm(formData);
  //   } else {
  //     const formUpdate = { ...form };
  //     formUpdate.type = formType;
  //     updateForm(formUpdate);
  //   }
  // };

  useEffect(() => {
    if (form)
      setFieldsMap(
        new Map(
          form.formPages.flatMap((page) =>
            page.sections.flatMap((section) =>
              section.fields.map((field, index) => [
                field.fieldId,
                { element: field, index },
              ])
            )
          )
        )
      );
  }, [form]);

  // handle page leave
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (unsavedChanges) {
      localStorage.setItem(admissionFormUnsavedKey, true);

      const unloadCallback = (event) => {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = "";
        return "";
      };

      window.addEventListener("beforeunload", unloadCallback);
      return () => {
        window.removeEventListener("beforeunload", unloadCallback);
      };
    }
    localStorage.removeItem(admissionFormUnsavedKey);
  }, [unsavedChanges]);

  const onCancel = () => {
    setAlertDialogState(false);
    if (!fetchedForm) updateForm(new Form());
    else {
      updateForm(fetchedForm);
    }
  };

  return (
    <Grid container pr={1}>
      <Grid container item md={12} py={4}>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <Typography
            contentEditable
            onBlur={updateFormName}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            fontSize={24}
            color="black"
          >
            {form?.name}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={1.5}
          sm={1.5}
          md={1.5}
          lg={1}
          justifyContent="center"
        >
          <Button
            onClick={() => showFormSettings()}
            variant="contained"
            sx={submitButton}
            disabled={formSaving}
          >
            Settings
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={1.5}
          sm={1.5}
          md={1.5}
          lg={1}
          justifyContent="center"
        >
          <Button
            onClick={togglePreview}
            variant="contained"
            sx={submitButton}
            disabled={formSaving}
          >
            {showPreview || showSettings ? "Builder" : "Preview"}
          </Button>
        </Grid>
        {!showPreview && (
          <Grid item xs={4} sm={4} md={4} lg={5} container justifyContent="end">
            <Button
              variant="contained"
              sx={cancelButton}
              onClick={() => setAlertDialogState(true)}
            >
              Cancel
            </Button>
            <Button
              onClick={saveForm}
              variant="contained"
              sx={submitButton}
              disabled={formSaving}
            >
              {formSaving ? "Saving..." : "Save"}
            </Button>
          </Grid>
        )}
      </Grid>
      {!showPreview && !showSettings && (
        <Grid item md={12} container>
          <Grid
            item
            xs={2.5}
            sm={2.5}
            md={2.5}
            lg={2.5}
            sx={{ borderRight: "1px solid black" }}
          >
            <FieldSet />
          </Grid>
          <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5}>
            {/* <Box>
              <Grid container>
                <Grid item md={4} p={2}>
                  <FormControl fullWidth>
                    <InputLabel id="formTypeLabel">Form Type</InputLabel>
                    <Select
                      labelId="formTypeLabel"
                      id="formType"
                      label="Form Type"
                      placeholder="Select"
                      value={form.type}
                      sx={{
                        height: "2.5rem",
                        "& .MuiTypography-root": { fontSize: "14px" },
                      }}
                      onChange={(e) => changeFormType(e.target.value)}
                    >
                      <MenuItem key={1} value={FormType.INQUIRY}>
                        <Typography textAlign="left">Inquiry</Typography>
                      </MenuItem>
                      <MenuItem key={2} value={FormType.PROCESS}>
                        <Typography textAlign="left">Process</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={8} />
              </Grid>
            </Box> */}

            <Grid item md={12} height="100%">
              <FormContainer />
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            sx={{ borderLeft: "1px solid black" }}
          >
            <FieldProperties />
          </Grid>
        </Grid>
      )}
      {showPreview && <FormRenderer isFormBuilderView />}
      {showSettings && <FormSettings />}
      <AlertDialog
        isOpen={alertDialogState}
        handleClose={() => setAlertDialogState(false)}
        handleConfirm={() => onCancel()}
      />
    </Grid>
  );
}
