const codeBarInnerContainer = {
  alignItems: "center",
  justifyContent: "center",
};

const codeBarButton = {
  backgroundColor: "#EBF0F1",
  borderRadius: "10px",
  minWidth: "40px",
  width: "auto",
  height: "40px",
  textTransform: "none",
  fontSize: "27px",
  color: "#79747E",
  fontWeight: "bold",
  "&:not(:last-of-type)": {
    mr: "10px",
  },
};

const codeBarButtonActive = (codeColor) => ({
  ...codeBarButton,
  backgroundColor: codeColor,
  boxShadow: "1px 2px 4px #bbb8b8",
  "&:hover": {
    backgroundColor: codeColor,
  },
});

export { codeBarInnerContainer, codeBarButton, codeBarButtonActive };
