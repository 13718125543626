import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import EmergencyContactTable from "./EmergencyContactTable";
import reportsService from "../../../service/reportsService";
import PrintLayout from "../PrintLayoutComponents/PrintLayout";
import { orderedGradeLevels as gradeOrder } from "../../../utils/sortedGradeLevels";

export default function EmergencyInformation() {
  const [students, setStudents] = useState([]);
  const params = useParams();
  const [schoolName] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const sortBy = searchParams.get("sort_by") || "last_name";

  const groups = (() => {
    switch (sortBy) {
      case "grade_level":
        return Array.from(
          new Set(students.map((student) => student.grade_level))
        )
          .map((gradeLevel) => ({
            grade_level: gradeLevel,
            group_name: `Grade: ${gradeLevel}`,
            students: students.filter(
              (student) => student.grade_level === gradeLevel
            ),
          }))
          .sort(
            (a, b) =>
              gradeOrder.indexOf(a.grade_level) -
              gradeOrder.indexOf(b.grade_level)
          );
      case "homeroom_teacher":
        return Array.from(new Set(students.map((s) => s.homeroom_teacher_id)))
          .map((teacherId) => {
            const studentsWithTeacher = students.filter(
              (s) => s.homeroom_teacher_id === teacherId
            );
            const { teacher } = studentsWithTeacher[0];

            return {
              teacher_last_name: teacher.last_name,
              group_name: `Homeroom Teacher: ${teacher.name_title || ""}. ${
                teacher.first_name
              } ${teacher.last_name}`,
              students: studentsWithTeacher,
            };
          })
          .sort((a, b) =>
            a.teacher_last_name.localeCompare(b.teacher_last_name)
          );
      default:
        return [{ students }];
    }
  })();

  useEffect(() => {
    (async () => {
      setLoading(true);
      reportsService
        .studentsEmergencyContacts({
          school_id: params.school_id,
        })
        .then((response) => {
          const studentsToRender = response.filter(
            (r) =>
              r.emergency_contacts.length > 0 ||
              r.emergency_guardians.length > 0
          );
          setStudents(studentsToRender);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    })();
  }, []);

  const generateBodyContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center">
          <Typography color="black" fontSize="20px" mt={5}>
            Loading...
          </Typography>
        </Box>
      );
    }

    if (students.length === 0) {
      return (
        <tr>
          <td>
            <Box textAlign="center" mt={3}>
              No emergency contacts found.
            </Box>
          </td>
        </tr>
      );
    }

    return groups?.map((group) => (
      <tr className="report-data" key={group.group_name || "default"}>
        <td className="report-content-cell" style={{ padding: "1rem 2rem" }}>
          {group.group_name && (
            <Typography
              fontWeight={700}
              fontSize={18}
              color="black"
              gutterBottom
              sx={{ textTransform: "uppercase" }}
            >
              {group.group_name}
            </Typography>
          )}
          {group.students.map((student) => (
            <div key={student.id}>
              <Grid container direction="column">
                <Typography fontWeight={700} fontSize={18} color="black">
                  {student.first_name} {student.last_name}
                </Typography>
                {Boolean(student.teacher) && (
                  <Typography fontSize={14} color="black">
                    D.O.B:{" "}
                    {student.date_of_birth
                      ? new Date(student.date_of_birth).toLocaleString(
                          "en-US",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )
                      : ""}{" "}
                    {sortBy !== "grade_level" &&
                      ` | Grade: ${student.grade_level}`}
                    {sortBy !== "homeroom_teacher" &&
                      ` | Homeroom Teacher: ${
                        student.teacher.name_title || ""
                      }. ${student.teacher.first_name} ${
                        student.teacher.last_name
                      }`}
                  </Typography>
                )}
                <Grid py={2}>
                  <EmergencyContactTable
                    emergencyContacts={student.emergency_contacts}
                    emergencyGuardians={student.emergency_guardians}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </td>
      </tr>
    ));
  };

  return (
    <PrintLayout
      bodyContent={generateBodyContent()}
      headerContent="Emergency Information"
      footerContent={schoolName}
    />
  );
}
