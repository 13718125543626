import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useFormContext } from "react-hook-form";
import { cursorPointer } from "./sharedStyles";

// eslint-disable-next-line import/no-unresolved
import "react-international-phone/style.css";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    // Since the main purpose of this validation is to prevent invalid numbers and sanitize them,
    // we should use the "possible" check instead of "strict", otherwise it will be pretty hard to
    // keep dependencies up to date with new area codes created by many countries and regions.
    return phoneUtil.isPossibleNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (e) {
    return false;
  }
};

// Include aditional countries that are not in the defaultCountries array
const additionalCountries = [["Northern Mariana Islands", "mp", "1"]];

// Re-sort the countries by name
const allCountries = [...defaultCountries, ...additionalCountries].sort(
  (a, b) => a[0].localeCompare(b[0])
);

export default function PhoneInput({
  id,
  defaultValue,
  isRequired,
  autoSave,
  onType,
  setValue: setValueProp,
  ...restProps
}) {
  const formContext = useFormContext();
  const setValue = setValueProp || formContext?.setValue;
  const [nationalNumber, setNationalNumber] = useState(null);
  const [startedEditing, setStartedEditing] = useState(false);
  const [number, setNumber] = useState(defaultValue || "");

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      value: number,
      countries: allCountries,
      forceDialCode: true,
      onChange: (data) => {
        setNumber(data.phone);

        if (autoSave && startedEditing && isPhoneValid(data.phone)) {
          setValue(id, data.phone, { shouldDirty: autoSave });
        } else if (onType) {
          onType(data);
        }
      },
    });

  const realNumber = `+${country?.dialCode}` === number ? null : number;

  const error =
    (isRequired && !nationalNumber?.length) ||
    (nationalNumber?.length && !isPhoneValid(inputValue));

  const clearInput = () => {
    setValue(id, "", { shouldDirty: autoSave });
    setNationalNumber(null);
    setNumber("");
  };

  useEffect(() => {
    if (defaultValue) setNumber(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!autoSave) {
      setValue(id, realNumber);
    }
  }, [autoSave, realNumber]);

  useEffect(() => {
    try {
      const phoneNumber = phoneUtil.parse(
        realNumber,
        country.iso2.toUpperCase()
      );
      setNationalNumber(phoneNumber.getNationalNumber().toString());
    } catch (e) {} // eslint-disable-line no-empty
  }, [realNumber]);

  useEffect(() => {
    if (inputValue && isPhoneValid(inputValue)) {
      setNumber(inputValue);

      if (isPhoneValid(inputValue)) {
        setValue(id, inputValue, { shouldDirty: autoSave });
      }
    }
  }, [inputValue]);

  return (
    <TextField
      variant="outlined"
      color="primary"
      value={inputValue}
      onFocus={() => setStartedEditing(true)}
      onChange={handlePhoneValueChange}
      type="tel"
      error={error}
      inputRef={inputRef}
      required={isRequired}
      InputProps={{
        readOnly: restProps.readOnly,
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", marginLeft: "-8px" }}
          >
            <Select
              MenuProps={{
                style: {
                  height: "300px",
                  width: "360px",
                  top: "10px",
                  left: "-34px",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                width: "max-content",
                fieldset: {
                  display: "none",
                },
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => (
                <FlagImage iso2={value} style={{ display: "flex" }} />
              )}
              disabled={restProps.disabled || restProps.readOnly}
            >
              {allCountries.map((c) => {
                const countryItem = parseCountry(c);
                return (
                  <MenuItem key={countryItem.iso2} value={countryItem.iso2}>
                    <FlagImage
                      iso2={countryItem.iso2}
                      style={{ marginRight: "8px" }}
                    />
                    <Typography marginRight="8px">
                      {countryItem.name}
                    </Typography>
                    <Typography color="gray">
                      +{countryItem.dialCode}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
        endAdornment:
          inputValue &&
          !restProps.disabled &&
          !isRequired &&
          !restProps.readOnly ? (
            <InputAdornment position="end">
              <CancelOutlinedIcon sx={cursorPointer} onClick={clearInput} />
            </InputAdornment>
          ) : (
            ""
          ),
      }}
      {...restProps}
    />
  );
}
