import { Box, Container } from "@mui/material";
import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { reportCardContainer } from "../../ReportCard/ReportCard.styles";
import CanvasTabs from "./CanvasTabs";
import Authorize from "../../Authorize";

export default function Canvas() {
  const [, currentSchool, canvasPermission] = useOutletContext();

  if (canvasPermission) {
    return (
      <Authorize permission={canvasPermission}>
        <Container maxWidth={false} variant="header">
          <Container maxWidth="xl">
            <CanvasTabs />

            <Box sx={reportCardContainer}>
              <Outlet context={[currentSchool, canvasPermission]} />
            </Box>
          </Container>
        </Container>
      </Authorize>
    );
  }
}
