import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSearchParams } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  loader,
  mt15,
  my30,
  rightDrawerContainer,
  textRight,
} from "../../../sharedStyles";
import { QuickBarContext } from "../../../../context/QuickBarContext";
import CanvasCourseRow from "./CanvasCourseRow";
import AlertDialog from "../../../AlertDialog";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import courseService from "../../../../service/courseService";
import CanvasCloneCourseForm from "./CanvasCloneCourseForm";
import {
  canvasCoursesAddBtn,
  canvasCoursesContainer,
  canvasCoursesListContainer,
  canvasCoursesTableHead,
} from "./CanvasCourses.styles";
import canvasCoursesService from "../../../../service/canvasCoursesService";
import CanvasCourseNew from "./CanvasCourseNew";
import CanvasCourseEdit from "./CanvasCourseEdit";
import CanvasSearch from "./CanvasSearch";

export default function CanvasCourses() {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [canvasCourses, setCanvasCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState(false);
  const [cloneCourseDrawer, setCloneCanvasCourse] = useState(null);
  const [create, setCreate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [loading, setLoading] = useState(false);

  const snackbarContext = useContext(SnackbarContext);
  const quickBarContext = useContext(QuickBarContext);

  const getAEConnectCourses = async () => {
    const response = await courseService.fetchAllCourses({
      params: {
        order: "asc",
        orderBy: "name",
      },
    });
    if (response.data) {
      setCourses(response.data);
    }
  };

  const handleCloseCloneDrawer = () => () => {
    setCloneCanvasCourse(false);
    quickBarContext.hideCloneCanvasCourse();
  };

  const getCourses = async () => {
    setLoading(true);
    const response = await canvasCoursesService.fetchCanvasCourses({
      params: {
        order,
        orderBy,
        query,
      },
    });
    if (response) {
      setCanvasCourses(response);
    }
    setLoading(false);
  };

  const handleDeleteCourse = async () => {
    const response = await canvasCoursesService.deleteCanvasCourse(
      activeCourse.id
    );

    if (response) {
      setActiveCourse(false);
      setDeleteDialogOpen(false);

      snackbarContext.setSnackbar({
        message: "Course deleted.",
        severity: "success",
        open: true,
      });
    }
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={{ visibility: "hidden" }} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (quickBarContext.cloneCanvasCourse) {
      setCloneCanvasCourse(true);
    }
  }, [quickBarContext.cloneCanvasCourse]);

  useEffect(() => {
    if (snackbarContext.snackbar.open) {
      getAEConnectCourses();
      getCourses();
    }
  }, [activeCourse, snackbarContext.snackbar.open]);

  useEffect(() => {
    getAEConnectCourses();
    getCourses();
  }, [activeCourse, order, orderBy, query]);

  return (
    <Box sx={my30}>
      <Stack direction="row">
        <Box sx={canvasCoursesListContainer}>
          <Box>
            <Box sx={textRight}>
              <Button
                onClick={() => {
                  setCreate(true);
                  setActiveCourse(false);
                }}
                sx={canvasCoursesAddBtn}
                startIcon={<AddIcon />}
              >
                Add Canvas Course
              </Button>
            </Box>

            <CanvasSearch
              query={query}
              setQuery={setQuery}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
          </Box>
          {loading ? (
            <CircularProgress sx={loader} size={100} />
          ) : (
            <Box>
              {canvasCourses.length > 0 ? (
                <TableContainer>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={canvasCoursesTableHead}
                          onClick={() => handleRequestSort("name")}
                        >
                          <Typography color="black">
                            Name {renderSortChevron("name")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={canvasCoursesTableHead}
                          onClick={() => handleRequestSort("sis_id")}
                        >
                          <Typography color="black">
                            SIS ID {renderSortChevron("sis_id")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={canvasCoursesTableHead}
                          onClick={() => handleRequestSort("classes")}
                          align="center"
                        >
                          <Typography
                            sx={{ justifyContent: "flex-end" }}
                            color="black"
                          >
                            Classes {renderSortChevron("classes")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {canvasCourses.map((course) => (
                        <CanvasCourseRow
                          key={course.id}
                          course={course}
                          setActiveCourse={setActiveCourse}
                          setCreate={setCreate}
                          activeCourse={activeCourse}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography sx={mt15} align="center">
                  No courses found in the current school year
                </Typography>
              )}
            </Box>
          )}
        </Box>
        {create && (
          <Box sx={canvasCoursesContainer}>
            <CanvasCourseNew
              courses={courses}
              setCreate={setCreate}
              setActiveCourse={setActiveCourse}
            />
          </Box>
        )}

        {activeCourse && (
          <Box sx={canvasCoursesContainer}>
            <CanvasCourseEdit
              setDeleteDialogOpen={setDeleteDialogOpen}
              courses={courses}
              activeCourse={activeCourse}
              snackbarContext={snackbarContext}
            />
          </Box>
        )}
      </Stack>

      <Drawer anchor="right" open={cloneCourseDrawer}>
        <Box sx={rightDrawerContainer}>
          <CanvasCloneCourseForm
            handleCloseDrawer={handleCloseCloneDrawer()}
            snackbarContext={snackbarContext}
          />
        </Box>
      </Drawer>

      <AlertDialog
        isOpen={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={handleDeleteCourse}
      />
    </Box>
  );
}
