import {
  Alert,
  AppBar,
  Button,
  Grid,
  Snackbar,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { centerContent, submitButton } from "./RegistrationForm.style";
import { font32, formTitle } from "../../sharedStyles";
import { navBarTypography } from "../../TopNavBar/TopNavBar.style";
import theme from "../../../utils";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import Authorize from "../../Authorize";
import admissionFormService from "../../../service/admissionFormService";
import { FormBuilderContext } from "./FormBuilder/FormBuilderContext";
import { Form } from "./FormBuilder/Models/Form";
import FormRenderer from "./FormRenderer/FormRenderer";
import { sortSectionsAndFields } from "./FormBuilder/FormBuilderUtils";

function InquiryFormRender({ schoolYear }) {
  const params = useParams();
  const { token } = params;
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const navigate = useNavigate();
  const formBuilderContext = useContext(FormBuilderContext);
  const {
    form,
    // eslint-disable-next-line no-unused-vars
    currentSectionIndex,
    // eslint-disable-next-line no-unused-vars
    currentPageIndex,
    updateForm,
    // eslint-disable-next-line no-unused-vars
    selectedFieldId,
  } = formBuilderContext;
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: "",
    severity: null,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen({ message: "", open: false });
  };

  const onFormSaved = () => {
    setShowConfirmationScreen(true);
  };

  useEffect(() => {
    (async () => {
      const response = await admissionFormService.fetchByToken(token);
      response.data.admission_form_pages =
        response.data.admission_form_pages.map((page) => {
          const updatedPage = page;
          updatedPage.form_sections = sortSectionsAndFields(page.form_sections);
          return updatedPage;
        });
      const formObj = new Form(response.data);
      updateForm(formObj);
    })();
  }, []);

  return (
    <div>
      {showConfirmationScreen ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={centerContent}
        >
          <Grid item xs={12}>
            <Typography sx={font32}>Thank you for registering</Typography>
            <Grid item xs={12} textAlign="center" mt={2}>
              <Button sx={submitButton} onClick={() => navigate(0)}>
                Register Another Student
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container>
            <AppBar>
              <Toolbar variant="dense">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs ml={3}>
                    <Typography noWrap component="div" sx={navBarTypography}>
                      {schoolYear?.school?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Grid container justifyContent="center" spacing={2} pt={7}>
              <Grid
                item
                xs={12}
                mx={() =>
                  (useMediaQuery(theme.breakpoints.up("lg")) && 35) || "unset"
                }
                mt={4}
                display="flex"
              >
                <Typography sx={formTitle} px={3}>
                  Inquiry Form
                </Typography>
              </Grid>

              {form && (
                <Grid item xs={12} textAlign="-webkit-center">
                  <FormRenderer isInquiryFormView onSaved={onFormSaved} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Snackbar
            open={snackBarOpen.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={snackBarOpen.severity}>
              {snackBarOpen.message}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}

export default function InquiryForm({
  schoolYear = null,
  needAuthorization = true,
}) {
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);
  const viewPermission = hasAnyPermissionType(APP_PERMISSIONS.ADMISSIONS);
  const managePermission = hasPermission(
    APP_PERMISSIONS.ADMISSIONS,
    PERMISSION_TYPES.MANAGE
  );
  return needAuthorization ? (
    <Authorize permission={viewPermission}>
      <InquiryFormRender
        schoolYear={schoolYear}
        managePermission={managePermission}
      />
    </Authorize>
  ) : (
    <InquiryFormRender
      schoolYear={schoolYear}
      managePermission={managePermission}
    />
  );
}
