import React, { useState, useRef, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Box, Stack, Button, Typography } from "@mui/material";
import {
  formHeader,
  formButtons,
  formTitle,
  formContainer,
} from "../../sharedStyles";
import Recipients from "./Recipients";
import Content from "./Content";
import Attachments from "./Attachments";
import {
  formHeaderAlert,
  formTitleAlert,
  formButtonsAlert,
} from "./NewMessage.styles";
import messagesService from "../../../service/messagesService";
import Preview from "./Preview";

export default function NewMessage({
  handleMessagesDrawer,
  schoolId,
  setSnackBarOpen,
  messageStudentId,
  messageParentIds,
  draft,
}) {
  const [isAlert, setIsAlert] = useState(draft?.is_alert || false);
  const [recipientsValue, setRecipientsValue] = useState(
    draft?.draft_recipients ? JSON.parse(draft?.draft_recipients) : []
  );
  const [attachments, setAttachments] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(
    draft?.attachments_info || []
  );
  const [descriptionError, setDescriptionError] = useState(false);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);
  const methods = useForm();
  const params = useParams();
  const [previewText, setPreviewText] = useState("");
  const description = methods.watch("description") || "";
  const subject = methods.watch("subject") || "";
  const studentId = params.student_slug || messageStudentId;
  const parentIds = messageParentIds || [];

  const newMessageFormHeader = isAlert ? formHeaderAlert : formHeader;
  const newMessageFormTitle = isAlert ? formTitleAlert : formTitle;
  const newMessageFormButtons = isAlert ? formButtonsAlert : formButtons;

  const maxFileSize = 20000000;

  function SizeException(message) {
    return {
      message,
      error: "sizeException",
    };
  }

  const handleFileUploads = (e) => {
    const files = [...uploadedFiles];
    Array.prototype.forEach.call(e.target.files, (file, index) => {
      const fileWithId = file;
      const fileId =
        uploadedFiles.length > 0 ? index + uploadedFiles.at(-1).id + 1 : index;
      fileWithId.id = fileId;
      files.push(fileWithId);
    });

    try {
      if (files) {
        setUploadedFiles(files);
      }
    } catch (err) {
      if (err.error === "sizeException") {
        setSnackBarOpen({
          message: err.message,
          open: true,
          severity: "error",
        });
      }
    }
  };

  useEffect(() => {
    const body = description
      .replace("<p>", "")
      .replace("</p>", "")
      .replace("\n", "");
    if (subject?.length >= 160 || !body) setPreviewText(subject.slice(0, 160));
    else if (body) setPreviewText(body.slice(0, 160));
    else setPreviewText("");
  }, [subject, description]);

  const handleBodyValidation = (hasText) =>
    hasText || draft?.description
      ? setDescriptionError(false)
      : setDescriptionError(true);

  const onSubmit = async (data, _params) => {
    if (!descriptionError) {
      setLoading(true);
      const formData = new FormData();

      const formParams = {
        ...data,
        school_id: schoolId,
        sender_type: "Staff",
        is_alert: isAlert,
      };

      formParams.is_draft = _params.saveDraft || false;

      Object.keys(formParams).map((keyName) =>
        formData.append(keyName, formParams[keyName])
      );

      formData.append("recipients", JSON.stringify(recipientsValue));

      Array.prototype.forEach.call(uploadedFiles, (file) => {
        if (uploadedFiles.length > 10)
          throw new SizeException("Maximum of 10 files are permitted.");
        if (file.size > maxFileSize)
          throw new SizeException(`The file ${file.name} is bigger than 20Mb.`);
      });

      if (_params.saveDraft) {
        uploadedFiles.forEach((file) => {
          if (file instanceof File) {
            formData.append("attachments[]", file);
          } else {
            formData.append("attachments[]", file.id);
          }
        });

        if (draft) {
          formData.append("id", draft.id);

          const response = await messagesService.updateMessage(
            draft.id,
            formData
          );

          if (response.data) {
            setSnackBarOpen({
              open: true,
              message: "Saved as draft",
            });

            handleMessagesDrawer(false);
            setLoading(false);
          }
        } else {
          const response = await messagesService.createMessage(formData);

          if (response.data) {
            setSnackBarOpen({
              open: true,
              message: "Saved as draft",
            });

            handleMessagesDrawer(false);
            setLoading(false);
          }
        }
      } else {
        if (draft) {
          formData.append("delete_draft", true);
          formData.append("draft_id", draft.id);
        }
        uploadedFiles.forEach((file) => {
          if (file instanceof File) {
            formData.append("attachments[]", file);
          } else {
            formData.append("attachments[]", file.id);
          }
        });
        const response = await messagesService.createMessage(formData);

        if (response.data) {
          setSnackBarOpen({
            open: true,
            message: formParams.is_draft ? "Saved as draft" : "Message sent",
          });

          handleMessagesDrawer(false);
          setLoading(false);
        }
      }
    } else {
      editorRef?.current?.focus();
    }
  };

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <Stack direction="row" sx={newMessageFormHeader}>
          <Typography sx={newMessageFormTitle}>
            {isAlert ? "New Alert" : "New Message"}
          </Typography>
          <Stack direction="row">
            <Button
              sx={newMessageFormButtons}
              onClick={() => handleMessagesDrawer(false)}
            >
              Cancel
            </Button>
            <Button
              sx={newMessageFormButtons}
              disabled={loading}
              onClick={() => {
                methods.handleSubmit(onSubmit)({
                  type: "submit",
                  saveDraft: true,
                });
              }}
            >
              {loading ? "Saving..." : "Save as Draft"}
            </Button>
            <Button sx={newMessageFormButtons} type="submit" disabled={loading}>
              {loading ? "Sending message..." : "Send"}
            </Button>
          </Stack>
        </Stack>

        <Box sx={formContainer}>
          <Recipients
            isAlert={isAlert}
            setIsAlert={setIsAlert}
            schoolId={schoolId}
            setRecipientsValue={setRecipientsValue}
            recipientsValue={recipientsValue}
            studentId={studentId}
            parentIds={parentIds}
            broadcast={draft?.broadcast || false}
            noReply={draft?.no_reply || false}
          />
          <Content
            isAlert={isAlert}
            handleBodyValidation={handleBodyValidation}
            descriptionError={descriptionError}
            editorRef={editorRef}
            subject={draft?.subject || ""}
            description={draft?.description || ""}
          />
          {previewText && (
            <Preview
              text={previewText}
              shortlink="http://example.com/messages/alert-message"
            />
          )}
          <Attachments
            handleFileUploads={handleFileUploads}
            attachments={attachments}
            setAttachments={setAttachments}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
        </Box>
      </form>
    </FormProvider>
  );
}
