import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import PhoneInput from "../../../../PhoneInput";
import { renderError } from "../../../../../utils/constants/forms";

export default function PhoneNumberRenderer({ field, properties, isReadOnly }) {
  const { t } = useTranslation("common", { keyPrefix: "phoneNumber" });
  const {
    formState: { errors, isSubmitted, isSubmitting },
    setError,
    clearErrors,
    watch,
    setValue,
  } = useFormContext();

  const value = watch(field.fieldId);

  // We require a valid phone number if `properties.isRequired=true`.
  // Otherwise, a invalid or incomplete phone number will be ignored as it will not be assigned
  // by PhoneInput component so it's value will be empty.
  useEffect(() => {
    if (!isReadOnly && (isSubmitting || isSubmitted) && properties.isRequired) {
      if (!value) {
        setError(field.fieldId, {
          type: "required",
          message: t("validPhoneNumberRequired"),
        });
      } else {
        clearErrors(field.fieldId);
      }
    }
  }, [value, isReadOnly, isSubmitting, isSubmitted, properties.isRequired]);

  return (
    <Box padding={1}>
      <PhoneInput
        id={field.fieldId}
        label={field.label}
        defaultValue={field.response || field.existing_data || ""}
        readOnly={isReadOnly}
        fullWidth
        isRequired={properties.isRequired}
        helperText={properties.supportingText}
        autoSave
        {...(properties.isRequired && {
          error: !!errors[field.fieldId],
        })}
        onType={() => {
          setValue(field.fieldId, "");
        }}
      />
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
