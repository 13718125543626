/* eslint-disable no-param-reassign */
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function DropdownListRenderer({
  field,
  properties,
  isReadOnly,
}) {
  const [selectedOptions, setSelectedOption] = useState(
    (properties.isMultiselect && field.response
      ? field.response.split(",")
      : field.response || field.existing_data) || []
  );

  const {
    register,
    setValue,
    formState: { errors, isSubmitted },
    trigger,
    control,
  } = useFormContext();

  const stateCountryDropdownData = (option) => option.valueId ?? option.value;

  useEffect(() => {
    if (isSubmitted) trigger(field.fieldId);
  }, [field.isValid]);
  return (
    <Box padding={1}>
      <FormControl error={errors[field.fieldId]} fullWidth>
        <InputLabel
          id={field.fieldId}
          shrink={properties.shrink || undefined}
          required={properties.isRequired}
          sx={{
            backgroundColor:
              properties.shrink &&
              !control.getFieldState(field.fieldId)?.isTouched
                ? "white"
                : "transparent",
          }}
        >
          {field.label}
        </InputLabel>
        <Select
          labelId={field.fieldId}
          label={field.label}
          multiple={properties.isMultiselect}
          value={selectedOptions}
          readOnly={isReadOnly}
          placeholder="Select"
          {...register(field.fieldId, {
            required: properties.isRequired && "This field is required",
            onChange: (event) => {
              if (properties.isMultiselect) {
                const sortedOptions = properties.options
                  .filter((op) => event.target.value.includes(op.value))
                  .map((op) => op.value);
                setSelectedOption(sortedOptions);
                setValue(field.fieldId, sortedOptions, {
                  shouldDirty: true,
                });
                field.response = sortedOptions.toString();
              } else {
                setSelectedOption(event.target.value);
                setValue(field.fieldId, event.target.value, {
                  shouldDirty: true,
                });
                field.response = event.target.value;
              }
            },
            validate: field.isValid
              ? () => true
              : () => properties.validityMessage || "Invalid input",
          })}
        >
          {properties.options.map((option) => (
            <MenuItem
              value={
                ["State", "Country"].includes(field.label)
                  ? stateCountryDropdownData(option)
                  : option.value
              }
              onClick={() => {
                if (properties.isMultiselect) {
                  const sortedOptions = properties.options
                    .filter((op) => option.value === op.value)
                    .map((op) => op.value);
                  setSelectedOption(sortedOptions);
                  setValue(field.fieldId, sortedOptions, {
                    shouldDirty: true,
                  });
                  field.response = sortedOptions.toString();
                } else {
                  setSelectedOption(option.value);
                  setValue(field.fieldId, option.value, {
                    shouldDirty: true,
                  });
                  field.response = option.value;
                }
              }}
              key={option.id}
            >
              <Typography textAlign="left">{option.value}</Typography>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{properties.supportingText}</FormHelperText>
      </FormControl>
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
