import React, { useState, useEffect, useContext } from "react";
import { useOutletContext } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Drawer,
  CircularProgress,
} from "@mui/material";
import {
  termGradeKlassHeadTerm,
  termGradeKlassHead,
} from "./TermGradeKlass.styles";
import { emptyMessageContainer } from "./TermGradeSkills.styles";
import { font22, rightDrawerContainer, loader } from "../../sharedStyles";
import TermGradeDetail from "./TermGradeDetail/TermGradeDetail";
import specialMarkService from "../../../service/specialMarkService";
import termGradeService from "../../../service/termGradeService";
import TermGradeKlassRow from "./TermGradeKlassRow";
import { QuickBarContext } from "../../../context/QuickBarContext";
import SnackBarNotification from "../../SnackBarNotification";
import reportCardsService from "../../../service/reportCardsService";
import subjectService from "../../../service/subjectService";

// eslint-disable-next-line no-unused-vars
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FBFAF9",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 11,
    borderRadius: "22px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FBFAF9",
    "&::before": {
      boxShadow: theme.shadows[2],
    },
  },
}));

export default function TermGradeKlass() {
  const [schoolId, subjectId, klassesIds, termId] = useOutletContext();
  const quickbarContext = useContext(QuickBarContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeGrade, setActiveGrade] = useState(null);
  const [loading, setLoading] = useState(false);
  const [termGrades, setTermGrades] = useState([]);
  const [currentTerm, setCurrentTerm] = useState([]);
  const [specialMarks, setSpecialMarks] = useState([]);
  const [overrideError, setOverrideError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [reportCards, setReportCards] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
    severity: "success",
  });
  const [subject, setSubject] = useState(null);

  const getTermGrades = async () => {
    setLoading(true);
    const response = await termGradeService.fetchTermGrades({
      params: {
        klasses_ids: klassesIds?.split(",").map((k) => Number(k)),
        subject_id: subjectId,
        school_id: schoolId,
        term_id: termId,
        prek: false,
      },
    });

    if (response.term_grades) {
      setTermGrades(response.term_grades);
      setCurrentTerm(response.term_grades[0]?.term);
      setLoading(false);
    }
  };

  const handleTermGradeDrawer = (
    studentData,
    termData,
    klassData,
    isReadOnly
  ) => {
    setDrawerOpen(true);
    setActiveGrade({
      termGrade: termData,
      readOnly: isReadOnly,
    });
  };

  const handleTermGradeDrawerClose = () => {
    setDrawerOpen(false);
    setActiveGrade(null);
  };

  const handleSackBarClose = () => {
    setSnackBarOpen({ open: false });
  };
  const handleTermGradesPost = async (data, termGradeId, postAll) => {
    let requestTermGrades = termGrades;
    if (postAll) {
      requestTermGrades = termGrades
        .filter(
          (ks) => ks.calculated_at <= ks.posted_at || ks.posted_grade === null
        )
        .map((ks) => ({
          id: ks.id,
          posted_grade: ks.overridden ? ks.posted_grade : ks.calculated_grade,
          comment: ks.comment,
          instructional_level: ks.instructional_level,
          overridden: ks.overridden,
          override_comment: ks.override_comment,
          override_incomplete: ks.override_incomplete,
          student_id: ks.student_id,
        }));
    }

    if (termGradeId) {
      requestTermGrades = termGrades
        .filter((ks) => ks.id === termGradeId)
        .map((ks) => ({
          id: ks.id,
          posted_grade: data.overridden
            ? data.overriddenGrade
            : ks.calculated_grade,
          comment: data.comment,
          instructional_level: data.instructional_level,
          overridden: data.overridden,
          override_comment: data.override_comment,
          override_incomplete: data.overrideIncomplete,
          student_id: ks.student_id,
        }));
    }

    // Check if the termGrade is published
    requestTermGrades = requestTermGrades
      .map((rtg) => {
        const reportCard = reportCards[rtg.student_id];

        if (reportCard && reportCard.is_published) {
          return null;
        }

        return rtg;
      })
      .filter((rtg) => rtg !== null);

    if (!overrideError && !commentError) {
      await termGradeService.saveGrades({ grades: requestTermGrades });
      await getTermGrades();
      setSnackBarOpen({
        open: true,
        message: "Term grades posted.",
        severity: "success",
      });
    }
  };

  const getReportCards = async () => {
    const response = await reportCardsService.fetchReportCards({
      term_id: termId,
    });

    if (response.data) {
      setReportCards(response.data);
    }
  };

  const getSubject = async () => {
    const response = await subjectService.fetchSubject(subjectId, schoolId);
    setSubject(response.data);
  };

  useEffect(() => {
    (async () => {
      await getTermGrades();
    })();
  }, [klassesIds, subjectId, termId]);

  useEffect(() => {
    (async () => {
      await getReportCards();
    })();
  }, [termId]);

  useEffect(() => {
    (async () => {
      const response = await specialMarkService.fetchSchoolSpecialMarks({
        params: {
          school_id: schoolId,
        },
      });
      if (response.data) {
        setSpecialMarks(response.data.special_marks);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (quickbarContext.postTermGrades === true) {
        await handleTermGradesPost(null, null, true);
        quickbarContext.cleanFirePostTermGrades();
      }
    })();
  }, [quickbarContext.postTermGrades]);

  useEffect(() => {
    if (subjectId && schoolId) {
      (async () => {
        await getSubject();
      })();
    }
  }, [subjectId]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  if (!loading && termGrades.length === 0) {
    return (
      <Box sx={emptyMessageContainer}>
        <Typography>No students for this class</Typography>
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={termGradeKlassHead} />
              <TableCell sx={termGradeKlassHead}>
                <Typography>Class</Typography>
              </TableCell>
              <TableCell sx={termGradeKlassHead} align="center">
                <Typography>
                  Student <br /> Level
                </Typography>
              </TableCell>
              <TableCell sx={termGradeKlassHeadTerm} align="center">
                <Typography sx={font22}>{currentTerm?.name}</Typography>
              </TableCell>
              <TableCell sx={termGradeKlassHead} />
              <TableCell sx={termGradeKlassHead}>
                {termGrades.filter(
                  (obj) => obj.klass?.subject?.needs_instructional_level
                ).length > 0 && <Typography>Instructional Level</Typography>}
              </TableCell>
              {subject?.name === "Language Arts" && (
                <TableCell sx={termGradeKlassHead}>
                  <Typography>Reading Level</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {termGrades.map((termGrade) => (
              <TermGradeKlassRow
                key={termGrade.id}
                termGrade={termGrade}
                termGrades={termGrades}
                setTermGrades={setTermGrades}
                handleTermGradeDrawer={handleTermGradeDrawer}
                specialMarks={specialMarks}
                reportCards={reportCards}
                getTermGrades={getTermGrades}
                subject={subject}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer anchor="right" open={drawerOpen}>
        <Box sx={rightDrawerContainer}>
          <TermGradeDetail
            handleTermGradeDrawerClose={handleTermGradeDrawerClose}
            activeGrade={activeGrade}
            specialMarks={specialMarks}
            handleTermGradesPost={handleTermGradesPost}
            setOverrideError={setOverrideError}
            overrideError={overrideError}
            commentError={commentError}
            setCommentError={setCommentError}
          />
        </Box>
      </Drawer>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        handleClose={handleSackBarClose}
        severity={snackBarOpen.severity}
      />
    </>
  );
}
