const classSchedulesTop = {
  justifyContent: "space-between",
  py: "20px",
};

const classSchedulesTitleSection = {
  display: "flex",
  alignItems: "baseline",
};

const classSchedulesTitle = {
  color: "black",
  fontSize: "28px",
};

const classSchedulesSubtitle = {
  color: "#3F464B",
  fontSize: "22px",
  mx: "20px",
};

const classSchedulesEdit = {
  color: "#5077A4",
  textDecoration: "none",
  fontSize: "14px",
};

const classSchedulesBtn = {
  textTransform: "none",
  mr: "10px",
  width: "120px",
  py: "10px",
  backgroundColor: "rgba(31, 31, 31, 0.12)",
  color: "#969595",
};

const classSchedulesBtnActive = {
  ...classSchedulesBtn,
  backgroundColor: "#5077A4",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#5981b0",
  },
};

const listName = {
  width: "250px",
};

const listTable = {
  color: "black",
};
const listHead = {
  borderBottom: "none",
  color: "#808080",
  cursor: "pointer",
  padding: 0,
  "& p": {
    fontSize: "14px",
    display: "flex",
    alignItems: "end",
    justifyContent: "flex-start",
  },
};

const listCell = {
  padding: 2,
  color: "black",
  paddingLeft: 0,
};

const listCellName = {
  ...listCell,
  "& p": {
    fontSize: "20px",
  },
};
const hidden = { visibility: "hidden" };

const settingsCell = {
  color: "#808080",
  cursor: "pointer",
};

const subjectsContainer = {
  pt: "30px",
  width: "20%",
  borderRight: "1px solid #E0E0E0",
  pr: "20px",
  mr: "20px",
};

const subjectTitle = (active) => ({
  color: active ? "#ffffff" : "black",
  fontSize: "20px",
  mb: "20px",
  cursor: "pointer",
  backgroundColor: active ? "#5077A4" : "transparent",
  padding: "10px",
  borderRadius: "5px",
});

export {
  classSchedulesTitle,
  classSchedulesSubtitle,
  classSchedulesEdit,
  classSchedulesTop,
  classSchedulesTitleSection,
  classSchedulesBtn,
  classSchedulesBtnActive,
  listName,
  listTable,
  listHead,
  listCell,
  listCellName,
  hidden,
  settingsCell,
  subjectsContainer,
  subjectTitle,
};
