import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import backgroundStyle from "./Reports.style";
import { QuickBarContext } from "../context/QuickBarContext";
import schoolsService from "../service/schoolsService";
import Authorize from "../components/Authorize";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import staffsService from "../service/staffsService";
import TermSelect from "../components/TermSelect";
import HomeroomTeacherSelect from "../components/Reports/StudentProgress/HomeroomTeacherSelect";
import TeacherSelect from "../components/Reports/ProgressReport/TeacherSelect";
import StudentSelect from "../components/Reports/StudentProgress/StudentSelect";
import ProgressStudentSelect from "../components/Reports/ProgressReport/StudentSelect";
import {
  blueOutlinedButton,
  formButtons,
  mt35,
} from "../components/sharedStyles";
import reportsService from "../service/reportsService";
import { SnackbarContext } from "../context/SnackbarContext";
import lookupService from "../service/lookupService";
import GradeLevelSelect from "../components/Reports/ProgressReport/GradeLevelSelect";
import AttendanceMonthSelect from "../components/Reports/Attendance/AttendanceMonthSelect";
import AttendanceSummaryViewSelect from "../components/Reports/AttendanceSummary/AttendanceSummaryViewSelect";

export default function Reports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedMenu, setSelectedMenu] = useState(1);
  const printComponent = useRef(null);
  const timeout = useRef();
  const quickBarContext = useContext(QuickBarContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [currentSchool, setCurrentSchool] = useState(null);
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);
  const [homeroomTeachers, setHomeroomTeachers] = useState([]);
  const [teacherStudents, setTeacherStudents] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState("select");
  const [selectedTeacherStudent, setSelectedTeacherStudent] = useState("all");
  const [schoolDaysByMonth, setSchoolDaysByMonth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("all");
  const [loading, setLoading] = useState(null);
  const params = useParams();
  const snackbarContext = useContext(SnackbarContext);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState();
  const [runReport, setRunReport] = useState(false);
  const sortBy = searchParams.get("sort_by") || "last_name";

  const manageAllStudentsPermission = hasPermission(
    APP_PERMISSIONS.ALL_STUDENTS,
    PERMISSION_TYPES.MANAGE
  );
  const reportRoutes = {
    1: `/school/${params.school_id}/reports`,
    2: `/school/${params.school_id}/reports/attendance`,
    3: `/school/${params.school_id}/reports/attendance-summary`,
    4: `/school/${params.school_id}/reports/birthdays`,
    5: `/school/${params.school_id}/reports/class-roster`,
    6: `/school/${params.school_id}/reports/emergency-info`,
    7: `/school/${params.school_id}/reports/enrollment`,
    8: `/school/${params.school_id}/reports/homeroom-roster`,
    9: `/school/${params.school_id}/reports/report-assignments`,
    10: `/school/${params.school_id}/reports/parent-contacts`,
    11: `/school/${params.school_id}/reports/school-calendar`,
    12: `/school/${params.school_id}/reports/student-progress`,
    13: `/school/${params.school_id}/reports/student-list`,
    14: `/school/${params.school_id}/reports/progress-report`,
  };

  const handleMissingAssignmentsCheckbox = (e) => {
    if (e.target.checked === true) {
      searchParams.set("showMissingAssignments", true);
      setSearchParams(searchParams);
    } else {
      searchParams.delete("showMissingAssignments");
      setSearchParams(searchParams);
    }
  };

  const getSchool = async () => {
    const response = await schoolsService.fetchSchool(params.school_id);

    setCurrentSchool(response.data);
  };

  const checkHomeRoomStudents = async () => {
    const teacherId = selectedTeacher === "select" ? null : selectedTeacher;
    const response = await staffsService.teacherHasHomeroomStudents({
      params: {
        school_id: params.school_id,
        teacher_id: searchParams.get("homeroomTeacherId") || teacherId,
        attendance_report: selectedMenu === 2 ? true : null,
      },
    });
    setTeacherStudents(response.homeroom_students_list);
    setLoading(false);
  };

  const getAssignmentReportStudents = async () => {
    const response = await staffsService.fetchStudents({
      params: {
        teacher_id:
          searchParams.get("teacherId") === "all"
            ? null
            : searchParams.get("teacherId"),
        term_id: searchParams.get("term"),
      },
    });

    if (response.data) {
      setTeacherStudents(response.data);
      setLoading(false);
    }
  };

  const loadGradeLevels = async () => {
    await lookupService.fetchGradeLevels().then((response) => {
      if (response?.data?.data) {
        const list = response.data.data.map(
          // eslint-disable-next-line no-shadow
          (gradeLevel) => gradeLevel.name
        );
        let gradeLevelsList = [...new Set(list)].sort();
        const gradeOrder = [
          "PK",
          "TK",
          "K",
          ...Array.from({ length: 12 }, (_, i) => (i + 1).toString()),
        ];
        gradeLevelsList = gradeOrder.filter((grade) =>
          gradeLevelsList.includes(grade)
        );

        setGradeLevels(gradeLevelsList);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    getSchool();
    loadGradeLevels();

    if (selectedMenu !== 9 && selectedMenu !== 1) {
      checkHomeRoomStudents();
    }
  }, [selectedTeacher, searchParams.get("homeroomTeacherId"), selectedMenu]);

  useEffect(() => {
    if (selectedMenu === 9) {
      getAssignmentReportStudents();
    }
  }, [searchParams.get("term"), searchParams.get("teacherId"), selectedMenu]);

  const handlePrint = useReactToPrint({
    content: () => printComponent.current.cloneNode(true),
    pageStyle: `
      @page { size: ${
        selectedMenu === 2 ? "landscape" : "auto"
      };  margin: 5mm; }
      @media print {
        .report-footer{
          visibility: visible;
        }
      }
    `,
  });

  const handleTeacherSelect = (value) => {
    setLoading(true);
    setSelectedTeacher(value);
    setSelectedTeacherStudent("all");
  };

  const handleStudentSelect = (value) => {
    clearTimeout(timeout.current);
    const studentId = value;
    setLoading(true);

    timeout.current = setTimeout(async () => {
      setSelectedTeacherStudent(studentId);
    }, 1000);
  };

  const handleMonthSelect = (value) => {
    setLoading(true);
    const month = value;
    setSelectedMonth(month);
  };

  useEffect(() => {
    if (quickBarContext.printReport) {
      handlePrint();
      quickBarContext.cleanPrintReport();
    }
  }, [quickBarContext.printReport]);

  useEffect(() => {
    if (location.pathname === reportRoutes["2"]) {
      setSelectedMenu(2);
    } else if (location.pathname === reportRoutes["3"]) {
      setSelectedMenu(3);
    } else if (location.pathname === reportRoutes["4"]) {
      setSelectedMenu(4);
    } else if (location.pathname === reportRoutes["5"]) {
      setSelectedMenu(5);
    } else if (location.pathname === reportRoutes["6"]) {
      setSelectedMenu(6);
    } else if (location.pathname === reportRoutes["7"]) {
      setSelectedMenu(7);
    } else if (location.pathname === reportRoutes["8"]) {
      setSelectedMenu(8);
    } else if (location.pathname === reportRoutes["9"]) {
      setSelectedMenu(9);
    } else if (location.pathname === reportRoutes["10"]) {
      setSelectedMenu(10);
    } else if (location.pathname === reportRoutes["11"]) {
      setSelectedMenu(11);
    } else if (location.pathname === reportRoutes["12"]) {
      setSelectedMenu(12);
    } else if (location.pathname === reportRoutes["13"]) {
      setSelectedMenu(13);
    } else if (location.pathname === reportRoutes["14"]) {
      setSelectedMenu(14);
    } else {
      setSelectedMenu(1);
    }
  }, [location.pathname]);

  const downloadParentContactsCSV = async () => {
    try {
      const response = await reportsService.parentContactsCSV();
      const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "parent_contacts.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      snackbarContext.setSnackbar({
        message: "CSV downloaded.",
        severity: "success",
        open: true,
      });
    } catch {
      snackbarContext.setSnackbar({
        message: "Failed to download CSV.",
        severity: "error",
        open: true,
      });
    }
  };

  const downloadStudentSchoolListCSV = async () => {
    try {
      const response = await reportsService.studentSchoolList({
        params: { term_id: searchParams.get("term"), csv: true },
      });
      const blob = new Blob([response], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "student-school-list.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      snackbarContext.setSnackbar({
        message: "CSV downloaded.",
        severity: "success",
        open: true,
      });
    } catch {
      snackbarContext.setSnackbar({
        message: "Failed to download CSV.",
        severity: "error",
        open: true,
      });
    }
  };

  const handleRunReport = () => {
    setRunReport(true);
  };

  const handleSortBy = (e) => {
    setSearchParams((prev) => {
      prev.set("sort_by", e.target.value);
      return prev;
    });
  };

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.REPORT_SCREEN)}>
      <Grid container minHeight="inherit" mb={2}>
        <Grid borderRight={1} item md={3}>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            sx={{ padding: "3rem" }}
            onNodeSelect={(e, nodeId) => {
              setSelectedMenu(Number(nodeId));
              navigate(reportRoutes[nodeId]);
              setSelectedTeacher("select");
            }}
            defaultExpanded={[String(selectedMenu)]}
            selected={String(selectedMenu)}
          >
            <TreeItem
              nodeId="1"
              label={<Typography fontWeight="bold">Reports</Typography>}
            >
              <TreeItem nodeId="2" label="Attendance" />
              <TreeItem nodeId="3" label="Attendance Summary" />
              <TreeItem nodeId="4" label="Birthdays" />
              <TreeItem nodeId="5" label="Class Roster" />
              <TreeItem nodeId="6" label="Emergency Contacts" />
              <TreeItem nodeId="7" label="Enrollment" />
              <TreeItem nodeId="8" label="Homeroom Roster" />
              <TreeItem nodeId="9" label="Assignments" />
              <TreeItem nodeId="10" label="Parent Contacts" />
              <TreeItem nodeId="11" label="School Calendar" />
              <TreeItem nodeId="12" label="Student Progress" />
              <TreeItem nodeId="13" label="Student List" />
              <TreeItem nodeId="14" label="Progress Report (9-12)" />
            </TreeItem>
          </TreeView>
        </Grid>
        <Grid item md={6} borderRight={1} sx={backgroundStyle}>
          {selectedMenu === 1 && (
            <Grid style={{ padding: "2rem 1rem 0 2rem" }}>
              <Typography fontSize={32} color="black">
                Reports
              </Typography>
              <Typography>
                Please select type of report from left menu to see detail
                reports.
              </Typography>
            </Grid>
          )}
          <div ref={printComponent}>
            <Outlet
              context={[
                currentSchool?.name,
                selectedTeacher,
                selectedTeacherStudent,
                setHomeroomTeachers,
                params,
                schoolDaysByMonth,
                setSchoolDaysByMonth,
                selectedMonth,
                selectedMenu,
                currentSchool?.org_id,
                loading,
                setLoading,
                selectedGradeLevel,
                runReport,
                setRunReport,
              ]}
            />
          </div>
        </Grid>
        {selectedMenu === 2 && (
          <Box mt={6} ml={6}>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="homeroom-teacher-label">
                  Homeroom Teacher
                </InputLabel>
                <Select
                  labelId="homeroom-teacher-label"
                  id="homeroom-teacher"
                  label="Homeroom Teacher"
                  value={selectedTeacher}
                  disabled={loading}
                  onChange={(e) => handleTeacherSelect(e.target.value)}
                >
                  <MenuItem value="select">All teachers</MenuItem>
                  {homeroomTeachers.map((ht) => (
                    <MenuItem key={ht.id} value={ht.id}>
                      {`${ht.first_name} ${ht.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box my={3}>
              <FormControl fullWidth>
                <InputLabel id="student-label">Student</InputLabel>
                <Select
                  labelId="student-label"
                  id="student"
                  label="Student"
                  value={selectedTeacherStudent}
                  disabled={loading}
                  onChange={(e) => handleStudentSelect(e.target.value)}
                >
                  <MenuItem value="all">All Students</MenuItem>
                  {teacherStudents?.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {`${s.last_name}, ${s.first_name}`} {s.middle_name || ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box my={3}>
              <AttendanceMonthSelect
                schoolDaysByMonth={schoolDaysByMonth}
                handleMonthSelect={handleMonthSelect}
                selectedMonth={selectedMonth}
                loading={loading}
                searchParams={searchParams}
              />
            </Box>
            <Box my={3}>
              <TermSelect
                schoolId={params.school_id}
                loading={loading}
                setLoading={setLoading}
                selectedMonth={selectedMonth}
                fetchUpcomingTerms
                defaultNull
                selectBox
                isAttendance
              />
            </Box>
          </Box>
        )}
        <Grid container item md={3}>
          {selectedMenu === 7 && (
            <Grid item md={8} pt={6} pl={4}>
              <TermSelect
                schoolId={params.school_id}
                fetchUpcomingTerms
                findCurrentTerm
              />
            </Grid>
          )}
          {selectedMenu === 8 && manageAllStudentsPermission && (
            <Box sx={{ width: "100%" }}>
              <HomeroomTeacherSelect
                schoolId={params.school_id}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
                hasAllTeachersOption
              />
            </Box>
          )}
          {selectedMenu === 6 && manageAllStudentsPermission && (
            <Box sx={{ width: "100%" }}>
              <Box md={8} pt={6} pl={4}>
                <Typography
                  fontSize={18}
                  color="black"
                  fontWeight="bold"
                  mb={2}
                >
                  Options
                </Typography>
                <RadioGroup onChange={handleSortBy} defaultValue={sortBy}>
                  <FormControlLabel
                    value="last_name"
                    control={<Radio />}
                    label="Sort by last name"
                  />
                  <FormControlLabel
                    value="grade_level"
                    control={<Radio />}
                    label="Sort by grade level"
                  />
                  <FormControlLabel
                    value="homeroom_teacher"
                    control={<Radio />}
                    label="Sort by homeroom teacher"
                  />
                </RadioGroup>
              </Box>
            </Box>
          )}
          {selectedMenu === 9 && (
            <Box sx={{ width: "100%" }}>
              <Grid item md={8} pt={6} pl={4}>
                <TermSelect
                  schoolId={params.school_id}
                  fetchUpcomingTerms
                  findCurrentTerm
                />
              </Grid>

              {manageAllStudentsPermission && (
                <TeacherSelect
                  schoolId={params.school_id}
                  setSelectedTeacherStudent={setSelectedTeacherStudent}
                  showAllAsDefault
                  hasAllTeachersOption
                />
              )}
              {teacherStudents?.length > 0 && (
                <StudentSelect
                  teacherStudents={teacherStudents}
                  setSelectedTeacherStudent={setSelectedTeacherStudent}
                  selectedTeacherStudent={selectedTeacherStudent}
                />
              )}
              <Grid item md={8} pt={3} pl={4}>
                <FormControlLabel
                  label="Show only missing assignments"
                  control={
                    <Checkbox
                      onChange={(e) => handleMissingAssignmentsCheckbox(e)}
                    />
                  }
                />
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                item
                md={8}
                pt={3}
                pl={4}
              >
                <Button
                  sx={formButtons}
                  disabled={loading}
                  onClick={() => handleRunReport()}
                >
                  Run report
                </Button>
              </Grid>
            </Box>
          )}
          {selectedMenu === 12 && (
            <Box sx={{ width: "100%" }}>
              <Grid item md={8} pt={6} pl={4}>
                <TermSelect
                  schoolId={params.school_id}
                  fetchUpcomingTerms
                  findCurrentTerm
                  selectBox
                />
              </Grid>

              <HomeroomTeacherSelect
                schoolId={params.school_id}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
              />
              <StudentSelect
                teacherStudents={teacherStudents}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
                selectedTeacherStudent={selectedTeacherStudent}
              />
            </Box>
          )}

          {selectedMenu === 8 && (
            <Box width="100%" p={4}>
              <Button
                variant="outlined"
                onClick={() => downloadParentContactsCSV()}
                sx={blueOutlinedButton}
              >
                <Typography fontSize={15}>Download CSV</Typography>
              </Button>
            </Box>
          )}

          {selectedMenu === 13 && (
            <Box sx={{ width: "100%" }}>
              <Grid item md={8} pt={6} pl={4}>
                <TermSelect
                  schoolId={params.school_id}
                  fetchUpcomingTerms
                  findCurrentTerm
                />
              </Grid>

              <Box sx={mt35} p={4}>
                <Button
                  variant="outlined"
                  onClick={() => downloadStudentSchoolListCSV()}
                  sx={blueOutlinedButton}
                >
                  <Typography fontSize={15}>Download CSV</Typography>
                </Button>
              </Box>
            </Box>
          )}

          {selectedMenu === 14 && (
            <Box sx={{ width: "100%" }}>
              <TermSelect
                schoolId={params.school_id}
                findCurrentTerm
                show={false}
              />

              {manageAllStudentsPermission && (
                <TeacherSelect
                  schoolId={params.school_id}
                  setSelectedTeacherStudent={setSelectedTeacherStudent}
                  hasAllTeachersOption
                />
              )}

              <ProgressStudentSelect
                teacherStudents={teacherStudents}
                setSelectedTeacherStudent={setSelectedTeacherStudent}
                selectedTeacherStudent={selectedTeacherStudent}
              />

              <GradeLevelSelect
                gradeLevels={gradeLevels}
                selectedGradeLevel={selectedGradeLevel}
                setSelectedGradeLevel={setSelectedGradeLevel}
              />
            </Box>
          )}

          {selectedMenu === 3 && (
            <Box mt={6} ml={6}>
              <AttendanceSummaryViewSelect
                schoolDaysByMonth={schoolDaysByMonth}
                handleMonthSelect={handleMonthSelect}
                selectedMonth={selectedMonth}
                loading={loading}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                setLoading={setLoading}
                schoolId={params.school_id}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Authorize>
  );
}
