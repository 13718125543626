import React, { useRef, useState, useEffect, useContext } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Input,
  Button,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  accountVerticalContainer,
  academicsImg,
  replaceButton,
  profilePicContainer,
  fieldsContainer,
} from "./StudentInfoAccount.style";
import ErasableTextField from "../../ErasableTextField";
import { danger } from "../../sharedStyles";
import lookupService from "../../../service/lookupService";
import { convertBase64 } from "../../../utils/constants/utils";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import { fetchGraduationPlans } from "../../../service/graduationPlanService";
import { sortedGradeLevels } from "../../../utils/sortedGradeLevels";

function GeneralInfoAccount({ student }) {
  const [gradeLevels, setGradeLevels] = useState([]);
  const [proofsOfBirth, setProofsOfBirth] = useState([]);
  const [graduationPlans, setGraduationPlans] = useState([]);
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const [profilePictureSrc, setProfilePictureSrc] = useState(
    "/assets/default_avatar.jpg"
  );
  const fileInputRef = useRef(null);
  const { hasPermission } = useContext(PermissionsContext);

  const gradeLevel = watch("grade_level");
  const graduationPlanId = watch("graduation_plan_id");

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_ACCOUNT,
    PERMISSION_TYPES.MANAGE
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePictureSrc(URL.createObjectURL(file));
      const imageData = await convertBase64(e.target.files[0]);
      setValue("profile_picture", imageData, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (student?.image_url) {
      setProfilePictureSrc(student.image_url.url);
    }
    lookupService.fetchGradeLevels().then((response) => {
      setGradeLevels(response.data.data);
    });
    lookupService.fetchProofsOfbirth().then((response) => {
      setProofsOfBirth(response.data.data);
    });
    register("profile_picture");

    setValue("first_name", student.first_name);
    setValue("last_name", student.last_name);
    setValue("graduation_plan_id", student.graduation_plan_id || "");
  }, [student]);

  useEffect(() => {
    // Fetch graduation plans when the component mounts
    fetchGraduationPlans()
      .then((response) => {
        setGraduationPlans(response.data);
      })
      .catch((error) => {
        console.error("Error fetching graduation plans:", error);
      });
  }, []);

  const showGraduationPlan = ["9", "10", "11", "12"].includes(gradeLevel);

  return (
    <Grid container item marginY={5} xs={12} sm={12} md={12}>
      <Grid item xs={12} sm={12} md={9} sx={accountVerticalContainer}>
        <Grid
          container
          item
          direction="column"
          rowSpacing={3}
          sx={fieldsContainer}
        >
          <Grid container item direction="column" rowSpacing={8}>
            <Grid container item columnSpacing={2} rowSpacing={2}>
              <Grid container item sm={3}>
                <TextField
                  id="aeId"
                  label="AE ID"
                  defaultValue={student.ae_id}
                  disabled
                />
              </Grid>
              <Grid container item sm={12} xs={12} md={12} spacing={2}>
                <Grid container item sm={11} xs={11} md={6}>
                  <TextField
                    id="legalName"
                    label="Full Legal Name"
                    fullWidth
                    disabled
                    defaultValue={student.legal_name}
                    {...register("legal_name")}
                  />
                </Grid>
                <Grid item sm={11} xs={11} md={3}>
                  <TextField
                    id="preferred_name"
                    label="Preferred Name"
                    fullWidth
                    defaultValue={student.preferred_name}
                    {...register("preferred_name")}
                    disabled={!managePermission}
                  />
                </Grid>
              </Grid>
              <Grid container item sm={12} xs={12} md={12} spacing={2}>
                <Grid item sm={3}>
                  <TextField
                    id="first_name"
                    label="First Name"
                    defaultValue={student.first_name}
                    error={errors.first_name}
                    disabled
                    required
                    {...register("first_name", {
                      required: "This field is required.",
                    })}
                  />
                  {errors.first_name && (
                    <Typography sx={danger}>This field is required.</Typography>
                  )}
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    id="middle_name"
                    label="Middle Name"
                    defaultValue={student.middle_name}
                    disabled
                    {...register("middle_name")}
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    id="last_name"
                    label="Last Name"
                    defaultValue={student.last_name}
                    error={errors.last_name}
                    disabled
                    required
                    {...register("last_name", {
                      required: "This field is required.",
                    })}
                  />
                  {errors.last_name && (
                    <Typography sx={danger}>This field is required.</Typography>
                  )}
                </Grid>
                <Grid item sm={2}>
                  <TextField
                    id="generation_name"
                    label="Gen. Name"
                    defaultValue={student.generation_name}
                    {...register("generation_name")}
                  />
                </Grid>
              </Grid>
              <Grid container item sm={12} xs={12} md={12} spacing={2}>
                <Grid item sm={2}>
                  <DesktopDatePicker
                    id="date_of_birth"
                    label="Date of Birth"
                    inputFormat="MM/DD/YYYY"
                    value={moment
                      .utc(student.date_of_birth)
                      .format(dateTimeFormats.MMDDYYYY)
                      .toString()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        disabled
                        sx={{ pointerEvents: "none" }}
                        error={Boolean(errors.date_of_birth)}
                      />
                    )}
                    disableOpenPicker
                  />
                  {errors.date_of_birth && (
                    <Typography sx={danger}>
                      {errors.date_of_birth.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={5}>
                  <ErasableTextField
                    id="place_of_birth"
                    label="Place of Birth"
                    defaultValue={student.place_of_birth}
                    fullWidth
                    disabled={!managePermission}
                  />
                </Grid>
                <Grid container item sm={2}>
                  <FormControl fullWidth required>
                    <InputLabel id="proofOfBirthLabel">
                      Proof of Birth
                    </InputLabel>
                    <Select
                      labelId="proofOfBirthLabel"
                      id="proof_of_birth"
                      label="Proof of Birth"
                      error={!!errors.proof_of_birth?.message}
                      defaultValue={student.proof_of_birth}
                      {...register("proof_of_birth", {
                        required: "This field is required.",
                      })}
                      disabled={!managePermission}
                    >
                      {proofsOfBirth.map((proofOfBirth) => (
                        <MenuItem
                          key={proofOfBirth.id}
                          value={proofOfBirth.name}
                        >
                          {proofOfBirth.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errors.proof_of_birth && (
                    <Typography sx={danger}>
                      {errors.proof_of_birth.message}
                    </Typography>
                  )}
                </Grid>
                <Grid container item sm={3} alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={student.proof_of_birth_verified}
                        {...register("proof_of_birth_verified")}
                        disabled={!managePermission}
                      />
                    }
                    label="Proof of birth verified"
                  />
                </Grid>
              </Grid>
              <Grid container item sm={12} xs={12} md={12} spacing={5}>
                {gradeLevels.length > 0 && (
                  <Grid container item sm={2}>
                    <FormControl fullWidth required>
                      <InputLabel id="gradeLevelLabel">Grade Level</InputLabel>
                      <Select
                        labelId="gradeLevelLabel"
                        id="grade_level"
                        label="Grade level"
                        error={
                          !!errors.grade_level?.message || gradeLevel === "null"
                        }
                        defaultValue={student.grade_level}
                        {...register("grade_level", {
                          required: "This field is required.",
                        })}
                        disabled={!managePermission}
                      >
                        {sortedGradeLevels(gradeLevels, true).map((gl) => (
                          <MenuItem key={gl.id} value={gl.name}>
                            {gl.name === "null" ? "" : gl.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.grade_level ||
                      (gradeLevel === "null" && (
                        <Typography sx={danger}>
                          This field is required.
                        </Typography>
                      ))}
                  </Grid>
                )}
                <Grid item sm={4}>
                  <ErasableTextField
                    id="state_id"
                    label="State ID"
                    maxLength={10}
                    defaultValue={student.state_id}
                    fullWidth
                    disabled={!managePermission}
                  />
                </Grid>
              </Grid>
              {showGraduationPlan && graduationPlans?.length > 0 && (
                <Grid container item sm={12} xs={12} md={12} spacing={5}>
                  <Grid container item sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="graduationPlanLabel">
                        Graduation Plan
                      </InputLabel>
                      <Select
                        labelId="graduationPlanLabel"
                        id="graduation_plan_id"
                        label="Graduation Plan"
                        value={graduationPlanId || ""}
                        {...register("graduation_plan_id")}
                        disabled={!managePermission}
                      >
                        <MenuItem value="">-- No graduation plan --</MenuItem>
                        {graduationPlans.map((plan) => (
                          <MenuItem
                            key={plan.id}
                            value={plan.id}
                            disabled={!plan.active}
                          >
                            {plan.name}&nbsp;
                            {!plan.active && (
                              <Typography sx={{ ...danger, display: "inline" }}>
                                (Inactive)
                              </Typography>
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={11} md={3}>
        <Grid
          item
          xs={12}
          ml={{ sx: "10%", sm: "5%" }}
          sx={profilePicContainer}
        >
          <img alt="profile" src={profilePictureSrc} style={academicsImg} />
          {managePermission && (
            <>
              <Button
                variant="outlined"
                sx={replaceButton}
                onClick={handleButtonClick}
              >
                Replace
              </Button>
              <Input
                type="file"
                inputRef={fileInputRef}
                style={{ display: "none" }}
                accept=".jpeg, .jpg, .png"
                onChange={handleProfilePictureChange}
                disabled={!managePermission}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GeneralInfoAccount;
