import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Stack, Typography } from "@mui/material";
import {
  messagesRow,
  messagesRowTimestamp,
  messagesAttachIcon,
  messagesInfoContainer,
  messagesRowDescription,
} from "../../../views/Messages.styles";
import { font14 } from "../../sharedStyles";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import moment from "../../../utils/constants/momentConfig";
import { draftsAttachmentContainer } from "./DraftsRow.styles";

export default function DraftsRow({ message }) {
  const draftRecipients = JSON.parse(message.draft_recipients);
  const draftRecipientsCount = draftRecipients.length;
  const recipients = draftRecipients.map(
    (recipient, index) =>
      `${recipient.name}${index < draftRecipientsCount - 1 ? ", " : ""}`
  );
  const formattedBody = message.description.replace(/(<([^>]+)>)/gi, "");

  return (
    <Box sx={messagesRow}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row">
          <Box sx={messagesInfoContainer}>
            <Typography sx={font14}>
              <strong>{message.subject}</strong>
            </Typography>
            <Typography sx={font14}>To: {recipients}</Typography>
            <Typography sx={font14}>{message.recipient.email}</Typography>
          </Box>
          <Box sx={messagesRowDescription}>
            <Typography>{formattedBody.replaceAll("&nbsp;", "")}</Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography sx={messagesRowTimestamp}>
            {moment(message.created_at).format(dateTimeFormats.LLL)}
          </Typography>
          <Box sx={draftsAttachmentContainer}>
            {message.has_attachments && (
              <AttachFileIcon sx={messagesAttachIcon} />
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
