const recipientsContainer = {
  borderBottom: "1px solid #BFC7CA",
  mb: "10px",
};

const recipientsTitle = {
  fontSize: "18px",
};

const autocompleteLabel = {
  mr: "15px",
};

const recipientsCount = {
  my: "5px",
  cursor: "pointer",
  color: "#2196F3",
};

export {
  recipientsContainer,
  recipientsTitle,
  autocompleteLabel,
  recipientsCount,
};
