import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useContext, useEffect, memo, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import schoolDayService from "../../../service/schoolDayService";
import { boxStyle, dayStyle } from "./SchoolDays.styles";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";

function SchoolDaysItem({
  schoolDay,
  setSnackBarOpen,
  dayTemplates,
  schoolDayDesignations,
}) {
  const shouldShowSchoolDay = typeof schoolDay === "object";
  const [addCommentMode, setAddCommentMode] = useState(
    schoolDay.comment && schoolDay.comment !== ""
  );
  const [comment, setComment] = useState(schoolDay.comment);
  const [selectedDesignation, setSelectedDesignation] = useState(
    schoolDay?.day_template || schoolDay?.school_day_designation || ""
  );
  const [shouldShowRemoteLearning, setShouldShowRemoteLearning] =
    useState(false);
  const [remoteLearning, setRemoteLearning] = useState(
    schoolDay?.remote_learning
  );
  const { hasPermission } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const managePermission = hasPermission(
    APP_PERMISSIONS.SCHOOL_DAYS,
    PERMISSION_TYPES.MANAGE
  );

  const designationInfo = useMemo(() => {
    const schoolDayDesignationSelected = Object.prototype.hasOwnProperty.call(
      selectedDesignation || {},
      "category"
    );

    const isDayTemplate = dayTemplates.some(
      (dayTemplate) =>
        dayTemplate.id === selectedDesignation?.id &&
        !schoolDayDesignationSelected
    );

    const isSchoolDayDesignation = schoolDayDesignations?.some(
      (_designation) =>
        _designation.category === "school_day" &&
        _designation.id === selectedDesignation?.id &&
        schoolDayDesignationSelected
    );

    const isKnownDesignation = isDayTemplate || isSchoolDayDesignation;

    return {
      color: isKnownDesignation ? "grey" : "red",
      shouldShowRemote: !!selectedDesignation && isKnownDesignation,
    };
  }, [selectedDesignation, dayTemplates, schoolDayDesignations]);

  const boxColor = () => {
    if (!shouldShowSchoolDay) return "grey";
    return designationInfo.color;
  };

  const handleChange = async (event) => {
    setLoading(true);
    const selected = event.target.value;
    setSelectedDesignation(selected);
    let params = {};
    const schoolDayDesignationSelected = Object.prototype.hasOwnProperty.call(
      selected,
      "category"
    );
    if (schoolDayDesignationSelected) {
      params = {
        ...schoolDay,
        school_day_designation_id: selected.id,
        day_template_id: null,
        remote_learning: remoteLearning,
      };
    } else {
      params = {
        ...schoolDay,
        school_day_designation_id: null,
        day_template_id: selected.id,
        remote_learning: remoteLearning,
      };
    }
    try {
      const response = await schoolDayService.update(params);

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "School day updated",
        });
      }
    } catch (error) {
      setSelectedDesignation(
        selectedDesignation || schoolDay?.day_template || null
      );
      setSnackBarOpen({
        open: true,
        message:
          error?.response?.data?.errors?.designation[0] ||
          error?.response?.data?.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCommentChange = async (event) => {
    setComment(event.target.value);
  };

  const handleCommentBlur = async (event) => {
    setLoading(true);

    const params = {
      ...schoolDay,
      comment: event.target.value,
    };

    try {
      const response = await schoolDayService.update(params);

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "Comment updated",
        });
      }
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message:
          error?.response?.data?.errors?.designation[0] ||
          error?.response?.data?.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setAddCommentMode(true);
  };

  const handleRemoteLearningChange = async (event) => {
    setLoading(true);
    setRemoteLearning(event.target.checked);

    const schoolDayDesignationSelected = Object.prototype.hasOwnProperty.call(
      selectedDesignation,
      "category"
    );

    let params = {};
    if (schoolDayDesignationSelected) {
      params = {
        ...schoolDay,
        school_day_designation_id: selectedDesignation.id,
        day_template_id: null,
        remote_learning: event.target.checked,
      };
    } else {
      params = {
        ...schoolDay,
        school_day_designation_id: null,
        day_template_id: selectedDesignation.id,
        remote_learning: event.target.checked,
      };
    }

    try {
      const response = await schoolDayService.update(params);

      if (response.data) {
        setSnackBarOpen({
          open: true,
          message: "School day updated",
        });
      }
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message:
          error?.response?.data?.errors?.designation[0] ||
          error?.response?.data?.message,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDesignation && dayTemplates && schoolDayDesignations) {
      setShouldShowRemoteLearning(designationInfo.shouldShowRemote);
    }
  }, [
    selectedDesignation,
    dayTemplates,
    schoolDayDesignations,
    designationInfo.shouldShowRemote,
  ]);

  useEffect(() => {
    if (schoolDay?.day_template && dayTemplates.length) {
      const matched = dayTemplates.find(
        (dt) => dt.id === schoolDay.day_template.id
      );
      setSelectedDesignation(matched || "");
    } else if (
      schoolDay?.school_day_designation &&
      schoolDayDesignations.length
    ) {
      const matched = schoolDayDesignations.find(
        (sd) => sd.id === schoolDay.school_day_designation.id
      );
      setSelectedDesignation(matched || "");
    }
  }, [schoolDay, dayTemplates, schoolDayDesignations]);

  return (
    <Grid item xs={1.7} mt={2} sx={shouldShowSchoolDay ? dayStyle : {}}>
      <Box sx={boxStyle(boxColor())}>
        {shouldShowSchoolDay && (
          <Box justifyContent="space-between" display="flex">
            <Typography alignContent="center" pt={boxColor() === "red" ? 1 : 0}>
              {new Date(schoolDay.date).getUTCDate()}
            </Typography>
            {shouldShowRemoteLearning && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={remoteLearning}
                    disabled={!managePermission || loading}
                    onChange={handleRemoteLearningChange}
                  />
                }
                label={
                  <Typography fontSize="0.7rem">Remote Learning</Typography>
                }
                labelPlacement="start"
              />
            )}
          </Box>
        )}
        {shouldShowSchoolDay && (
          <Grid container direction="column" spacing={2} py={1}>
            <Grid item md={12}>
              <FormControl fullWidth size="small" sx={{ maxWidth: "140px" }}>
                <Select
                  value={selectedDesignation || ""}
                  onChange={handleChange}
                  disabled={!managePermission || loading}
                >
                  {schoolDayDesignations?.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      {option.name}{" "}
                      {option.category === "school_day" && "(School Day)"}
                    </MenuItem>
                  ))}
                  <MenuItem disabled value="">
                    Day Templates
                  </MenuItem>
                  {dayTemplates.map((dayTemplate) => (
                    <MenuItem
                      key={dayTemplate.id}
                      value={dayTemplate}
                      sx={{
                        paddingLeft: 4,
                      }}
                    >
                      {dayTemplate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} display="flex" justifyContent="end">
              {addCommentMode ? (
                <FormControl fullWidth size="small" sx={{ width: "100%" }}>
                  <TextField
                    disabled={loading}
                    value={comment}
                    onChange={handleCommentChange}
                    onBlur={handleCommentBlur}
                    placeholder="Comment"
                    inputProps={{
                      sx: { padding: "0.5rem" },
                    }}
                  />
                </FormControl>
              ) : (
                <IconButton onClick={handleAdd} disabled={!managePermission}>
                  <AddIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
}

export default memo(SchoolDaysItem);
