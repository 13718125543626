const canvasCoursesListContainer = {
  width: "35%",
  borderRight: "1px solid #BFC7CA",
  pr: "20px",
  mr: "20px",
};

const canvasCoursesContainer = {
  width: "65%",
  p: "30px",
};

const canvasCoursesAddBtn = {
  textTransform: "none",
  color: "#2196F3",
};

const canvasCoursesTableHead = {
  cursor: "pointer",
  "& p": {
    display: "flex",
    alignItems: "center",
  },
};

export {
  canvasCoursesListContainer,
  canvasCoursesContainer,
  canvasCoursesAddBtn,
  canvasCoursesTableHead,
};
