import axios from "../utils/axios-util";

const notificationsEndpoint = "/api/notifications";

const listNotifications = async (params) => {
  const { data } = await axios.get(`${notificationsEndpoint}`, params);
  return data;
};

const fetchNotification = async (id) => {
  const { data } = await axios.get(`${notificationsEndpoint}/${id}`);
  return data;
};

const checkForUnreadNotifications = async () => {
  const { data } = await axios.get(`${notificationsEndpoint}/check_unread`);
  return data;
};

const readAllNotifications = async () => {
  const { data } = await axios.get(`${notificationsEndpoint}/check_unread`);
  return data;
};

export default {
  listNotifications,
  fetchNotification,
  checkForUnreadNotifications,
  readAllNotifications,
};
