import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Box,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import { loader, submitButton } from "../sharedStyles";
import {
  notificationDetails,
  notificationDetailsBackBtn,
} from "../../views/Notifications.styles";
import notificationsService from "../../service/notificationsService";
import AssignmentMissingGuardian from "./Body/AssignmentMissingGuardian";
import AssignmentMissingStudent from "./Body/AssignmentMissingStudent";
import ProgressReport from "./Body/ProgressReport";

export default function Details() {
  const { t } = useTranslation("notifications");
  const params = useParams();
  const notificationId = params.notification_id;
  const [notification, setNotification] = useState();

  const getNotification = async () => {
    const response = await notificationsService.fetchNotification(
      notificationId
    );

    if (response) {
      setNotification(response);
    }
  };

  const getEventBody = () => {
    switch (notification.event_name) {
      case "assignment_missing_guardian":
        return <AssignmentMissingGuardian notification={notification} />;
      case "assignment_missing_student":
        return <AssignmentMissingStudent notification={notification} />;
      case "progress_report":
        return <ProgressReport notification={notification} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (notificationId) getNotification();
  }, [notificationId]);

  if (!notification) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Container maxWidth={false} variant="header" sx={notificationDetails}>
      <Container maxWidth="xl">
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mt={3}
        >
          <Grid item xs={9}>
            <Typography className="category">
              {t(notification.event_category, { keyPrefix: "categories" })}
            </Typography>
            <Typography variant="h5">
              {notification.event_name === "report_card_download"
                ? notification.data.title
                : t("name", { keyPrefix: `events.${notification.event_name}` })}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography align="right" sx={notificationDetailsBackBtn}>
              <Button onClick={() => window.history.back()}>
                {t("goBack")}
              </Button>
            </Typography>
          </Grid>
        </Grid>

        <Box m={3}>
          <Box pb={3}>
            <Typography variant="body" spacing={2}>
              {notification.event_name === "report_card_download" &&
              notification.data?.error
                ? notification.data?.error
                : t("description", {
                    keyPrefix: `events.${notification.event_name}`,
                    data: notification.data,
                  })}
            </Typography>
          </Box>
          {notification.data?.download_url && (
            <Box pb={3}>
              <Typography variant="body" spacing={2}>
                <Button
                  variant="contained"
                  sx={submitButton}
                  href={notification.data.download_url}
                  download
                >
                  Download
                </Button>
              </Typography>
            </Box>
          )}

          <Divider color="black" />

          <Box pt={3}>{getEventBody()}</Box>
        </Box>
      </Container>
    </Container>
  );
}
