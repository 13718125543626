import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
/* eslint-disable no-restricted-syntax */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Pagination,
  PaginationItem,
  Typography,
  Badge,
} from "@mui/material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  useSearchParams,
  Link as RouterLink,
  useParams,
  useNavigate,
} from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { submitButton } from "../FormBuilder/FormBuilder.style";
import { FormBuilderContext } from "../FormBuilder/FormBuilderContext";
import FormSectionRenderer from "./FormSectionRenderer";
import admissionFormService from "../../../../service/admissionFormService";
import dataTypeClassMap from "../FormBuilder/Models/Fields/dataTypeClassMap";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import { Form } from "../FormBuilder/Models/Form";
import { buttonWithStartIcon, formTitle, loader } from "../../../sharedStyles";
import { QuickBarContext } from "../../../../context/QuickBarContext";
import { sortSectionsAndFields } from "../FormBuilder/FormBuilderUtils";
import DeleteFormResponse from "../../DeleteFormResponse";
import { PrintFormLayout } from "./PrintFormLayout/PrintFormLayout";
import appConfigService from "../../../../service/appConfigService";

export default function FormRenderer({
  submitButtonRef,
  onSaved,
  studentId,
  isInquiryFormView = false,
  isFormBuilderView = false,
  isDataView = false,
  isGuardianView = false,
  returnUrl,
  returnPrompt,
  formLocked,
}) {
  const { t } = useTranslation("guardian", { keyPrefix: "admissions" });
  const printComponent = useRef(null);
  const quickBarContext = useContext(QuickBarContext);
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const [hcaptchaSiteKey, setHcaptchaSiteKey] = useState(null);
  const params = useParams();
  const guardianId = params.id;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formBuilderContext = useContext(FormBuilderContext);
  const snackbarContext = useContext(SnackbarContext);

  const { form, currentPageIndex, updateForm, updateCurrentPageIndex } =
    formBuilderContext;

  const methods = useForm({ mode: "onSubmit" });
  const {
    control,
    formState: { errors },
    trigger,
  } = methods;
  const formValues = useWatch({ control });

  const [pageErrorCounts, setPageErrorCounts] = useState([]);

  const [fieldsMap, setFieldsMap] = useState();
  const [loading, setLoading] = useState(false);
  const previousValuesRef = useRef(formValues);
  const [failedValidation, setFailedValidation] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  let parentSectionIndex = -1;
  let emergencyContactSectionIndex = -1;

  const onVerify = (e) => {
    if (e) {
      setToken(token);
      setSubmitDisabled(false);
    }
  };

  const formattedParams = () => {
    let paramsUrl = "?";
    const processId = searchParams.get("process_id");
    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }

    return paramsUrl;
  };

  const evaluateValidity = async (field) => {
    for (const dependentId of field.properties.validityDependents) {
      const dependent = fieldsMap.get(dependentId).element;
      let script = "";
      let variables = "";
      for (const dependeeId of dependent.properties.validityDependee) {
        const dependee = fieldsMap.get(dependeeId).element;
        const dependeeValue =
          dependee.type === "date-field"
            ? new Date(formValues[dependee.fieldId]).getTime()
            : formValues[dependee.fieldId];

        variables = `${variables} \n var ${dependee.fieldId}= '${dependeeValue}' `;
      }
      script += variables;
      script = `${script} \n return ${dependent.properties.validityConditionJS}`;

      try {
        // eslint-disable-next-line no-new-func
        const fun = new Function(script);
        const valid = fun();

        const { fieldIndex, sectionIndex, pageIndex } =
          fieldsMap.get(dependentId);
        const formUpdate = { ...form };
        formUpdate.formPages[pageIndex].sections[sectionIndex].fields[
          fieldIndex
        ].isValid = valid;

        if (formValues[dependentId !== undefined]) {
          formUpdate.formPages[pageIndex].sections[sectionIndex].fields[
            fieldIndex
          ].response = formValues[dependentId];
        }
        updateForm(formUpdate);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const evaluateVisibility = async (field) => {
    for (const dependentId of field.properties.visibilityDependents) {
      const dependent = fieldsMap.get(dependentId).element;
      let script = "";
      let variables = "";
      for (const dependeeId of dependent.properties.visibilityDependee) {
        const dependee = fieldsMap.get(dependeeId).element;
        variables = `${variables} \n var ${dependee.fieldId}= '${
          formValues[dependee.fieldId]
        }' `;
      }
      script += variables;
      script = `${script} \n return ${dependent.properties.visibilityConditionJS}`;

      try {
        // eslint-disable-next-line no-new-func
        const fun = new Function(script);
        const visible = fun();

        const { fieldIndex, sectionIndex, pageIndex } =
          fieldsMap.get(dependentId);
        const formUpdate = { ...form };
        formUpdate.formPages[pageIndex].sections[sectionIndex].fields[
          fieldIndex
        ].isVisible = visible;
        if (!visible) {
          methods.unregister(
            formUpdate.formPages[pageIndex].sections[sectionIndex].fields[
              fieldIndex
            ].fieldId
          );
        }
        updateForm(formUpdate);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onFieldValueChange = (field) => {
    evaluateVisibility(field);
    if (field.isVisible) evaluateValidity(field);
  };

  const fetchHcaptchaConfig = async () => {
    appConfigService
      .fetchHcaptchaConfig()
      .then((response) => {
        if (response.data) {
          setHcaptchaSiteKey(response.data.site_key);
        }
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: t("failedToFetchHcaptchaConfig"),
          severity: "error",
          open: true,
        });
      });
  };

  const handlePrint = useReactToPrint({
    content: () => printComponent.current,
    pageStyle: `
      @page { size: "auto";  margin: 5mm; }
      @media print {
        .report-footer{
          visibility: visible;
        }
      }
    `,
    onAfterPrint: () => {
      setIsReadyToPrint(false);
    },
  });

  const initiatePrint = () => {
    setIsReadyToPrint(true);
    setTimeout(() => {
      if (printComponent.current) {
        handlePrint();
      }
    }, 300);
  };

  const onSubmit = (data) => {
    if (isFormBuilderView) return;

    const formResponse = {};
    formResponse.admission_form_id = form.id;
    formResponse.field_responses_attributes = [];
    formResponse.custom_field_responses = [];

    Object.keys(data).forEach((key) => {
      const fieldElement = fieldsMap.get(key).element;
      const fieldResponse = {};
      fieldResponse[`${fieldElement.dataType}`] = dataTypeClassMap[
        fieldElement.dataType
      ](data[key]);
      fieldResponse.form_field_id =
        fieldElement.sourceFieldId || fieldElement.id;
      formResponse.field_responses_attributes.push(fieldResponse);
    });

    const isLastPage = form.formPages.length === currentPageIndex + 1;
    const hasError = Object.keys(errors).length > 0;
    const partiallyFilled = !isLastPage && !hasError;

    admissionFormService
      .saveFormResponse(
        formResponse,
        searchParams.get("element_instance_id"),
        partiallyFilled,
        studentId
      )
      .then((response) => {
        if (onSaved) {
          onSaved(response.data.id);
        } else {
          snackbarContext.setSnackbar({
            message: t("formSubmitted"),
            severity: "success",
            open: true,
          });
          if (isGuardianView && !partiallyFilled) {
            navigate(
              `/guardian/${guardianId}/students/${studentId}/home/enrollments/checklist${formattedParams()}`
            );
          }
        }
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: t("failedToSubmitForm"),
          severity: "error",
          open: true,
        });
      });
  };

  const handleFields = (clicked, onlyValidations = false, hasErrors = {}) => {
    const fieldIds = hasErrors;

    if (fieldIds.length) {
      const newPageErrorCounts = form.formPages.map((_page) => {
        const count = _page.sections.reduce((acc, section) => {
          const matchingFieldsCount = section.fields.filter((field) =>
            fieldIds.includes(field.fieldId)
          ).length;
          return acc + matchingFieldsCount;
        }, 0);
        return count;
      });
      setPageErrorCounts(newPageErrorCounts);

      const isLastPage = form?.formPages?.length === currentPageIndex + 1;
      if (clicked && newPageErrorCounts[currentPageIndex] === 0) {
        if (!isLastPage && !onlyValidations) {
          updateCurrentPageIndex(currentPageIndex + 1);
        }
        if (!onlyValidations) {
          methods.handleSubmit(onSubmit)();
        }
      }
    } else {
      const zeroPageErrorCounts = form.formPages.map(() => 0);
      setPageErrorCounts(zeroPageErrorCounts);

      const isLastPage = form?.formPages?.length === currentPageIndex + 1;
      if (clicked && zeroPageErrorCounts[currentPageIndex] === 0) {
        if (!isLastPage && !onlyValidations) {
          updateCurrentPageIndex(currentPageIndex + 1);
        }
        if (!onlyValidations) {
          methods.handleSubmit(onSubmit)();
        }
      }
    }
  };

  const processFieldValidations = async () => {
    await form?.formPages?.forEach(async (_page, index) => {
      if (index === currentPageIndex) {
        const validFields = [];
        await _page.sections.forEach(async (section) => {
          await section.fields.forEach(async (field) => {
            await evaluateValidity(field);
            if (isGuardianView && Object.keys(formValues).length === 0) {
              formValues[field.fieldId] = field.response || "";
            }
            validFields.push(trigger(field.fieldId));
          });
        });

        Promise.all(validFields).then((res) => {
          const hasPassedValidation = res.every((element) => element === true);

          if (hasPassedValidation) {
            handleFields(true);
          } else {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            setFailedValidation(true);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (searchParams.get("form_id") && !isDataView) {
      setLoading(true);
      updateForm(null);
      (async () => {
        const response = await admissionFormService.getFormRender(
          searchParams.get("form_id")
        );
        setLoading(false);
        response.data.admission_form_pages =
          response.data.admission_form_pages.map((page) => {
            const updatedPage = page;
            updatedPage.form_sections = sortSectionsAndFields(
              page.form_sections
            );
            return updatedPage;
          });

        const formObj = new Form(response.data);
        updateForm(formObj);
      })();
    }
  }, [searchParams.get("form_id")]);

  useEffect(() => {
    const isPreviousValuesNotEmpty =
      Object.keys(previousValuesRef.current).length !== 0;

    if (isPreviousValuesNotEmpty && !isInquiryFormView) {
      const changedValues = Object.entries(formValues).filter(
        ([key, value]) =>
          previousValuesRef.current[key] !== value &&
          previousValuesRef.current[key] !== undefined &&
          form.formPages[currentPageIndex]?.sections
            .flatMap((section) => section.fields.map((field) => field.fieldId))
            .includes(key)
      );
      changedValues.forEach(([key]) => {
        const changedField = form.formPages[currentPageIndex]?.sections
          .flatMap((section) => section.fields)
          .find((field) => field.fieldId === key);
        if (changedField && changedField.properties?.isRequired) {
          onFieldValueChange(changedField);
        }
      });
    }
    previousValuesRef.current = formValues;
  }, [formValues, currentPageIndex, form]);

  useEffect(() => {
    if (fieldsMap && form) {
      form.formPages[currentPageIndex].sections.forEach((section) => {
        section.fields.forEach((element) => {
          evaluateValidity(element);
          evaluateVisibility(element);
        });
      });
    }
  }, [fieldsMap]);

  useEffect(() => {
    if (form?.id && !fieldsMap) {
      if (form.properties.enableCaptcha && !isInquiryFormView) {
        setSubmitDisabled(true);
      } else {
        setSubmitDisabled(false);
      }
      setFieldsMap(
        new Map(
          form.formPages.flatMap((_page, pageIndex) =>
            _page.sections.flatMap((section, sectionIndex) =>
              section.fields.map((field, fieldIndex) => [
                field.fieldId,
                { element: field, fieldIndex, sectionIndex, pageIndex },
              ])
            )
          )
        )
      );
    }
    fetchHcaptchaConfig();
  }, [form]);

  useEffect(() => {
    if (failedValidation || Object.keys(errors).length > 0) {
      handleFields(false, true, Object.keys(errors));
    }
  }, [failedValidation, Object.keys(errors).length]);

  useEffect(() => {
    if (quickBarContext.printReport) {
      initiatePrint();
      quickBarContext.cleanPrintReport();
    }
  }, [quickBarContext.printReport]);

  if (loading || !form) {
    return <CircularProgress color="inherit" size={100} sx={loader} />;
  }

  if (isReadyToPrint) {
    return (
      <>
        <PrintFormLayout
          ref={printComponent}
          formValues={formValues}
          fieldsMap={fieldsMap}
          form={form}
          dataTypeClassMap={dataTypeClassMap}
        />
        <Backdrop
          sx={(theme) => ({
            color: "#fff",
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: "rgba(10, 10, 10, 0.9)",
          })}
          open={isReadyToPrint}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        style={{
          width: "100%",
          backgroundColor: !isInquiryFormView ? "#ffffff" : "inherit",
        }}
        onSubmit={
          isInquiryFormView || isDataView
            ? methods.handleSubmit(onSubmit)
            : null
        }
      >
        {isGuardianView || isDataView ? (
          <>
            <Grid py={2} ml={3} container>
              <Link
                to={
                  isDataView
                    ? returnUrl
                    : `/guardian/${guardianId}/students/${studentId}/home/enrollments/checklist${formattedParams()}`
                }
                underline="none"
                component={RouterLink}
              >
                <Button
                  variant="text"
                  startIcon={<ArrowBackIosIcon size="small" />}
                  sx={buttonWithStartIcon}
                >
                  {t("returnTo")} {isDataView ? returnPrompt : t("checklist")}
                </Button>
              </Link>
            </Grid>
            <Box>
              <Grid py={2} container justifyContent="center">
                <Typography color="black" sx={formTitle}>
                  {form?.name}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="center"
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23) !important",
                    borderWidth: "1px",
                  },
                }}
              >
                {form.formPages[currentPageIndex]?.sections.map(
                  (section, index) => {
                    if (section.isParentInfo) {
                      parentSectionIndex += 1;
                    }
                    if (section.isEmergencyContactInfo) {
                      emergencyContactSectionIndex += 1;
                    }
                    return (
                      !section.formBuilderOnly && (
                        <Grid
                          item
                          xs={12}
                          display="grid"
                          justifyContent="center"
                          key={section.id}
                        >
                          <FormSectionRenderer
                            section={section}
                            sectionIndex={index}
                            isReadOnly={formLocked}
                            formattedView={
                              isGuardianView ||
                              isDataView ||
                              isFormBuilderView ||
                              isInquiryFormView
                            }
                            guardianView={isGuardianView}
                            parentSectionIndex={parentSectionIndex}
                            emergencyContactSectionIndex={
                              emergencyContactSectionIndex
                            }
                          />
                        </Grid>
                      )
                    );
                  }
                )}
              </Grid>
            </Box>
          </>
        ) : (
          form.formPages.map((_page, pageIndex) =>
            _page.sections.map(
              (section, sectionIndex) =>
                !section.formBuilderOnly && (
                  <Box
                    key={`page_${_page.id}-section_${section.id}`}
                    display={
                      pageIndex !== currentPageIndex
                        ? "none !important"
                        : "flex"
                    }
                    justifyContent="center"
                  >
                    <FormSectionRenderer
                      section={section}
                      sectionIndex={sectionIndex}
                      formattedView={
                        isGuardianView ||
                        isDataView ||
                        isFormBuilderView ||
                        isInquiryFormView
                      }
                      isReadOnly={isDataView}
                    />
                  </Box>
                )
            )
          )
        )}
        {form && (
          <Grid container justifyContent="center" pb={3}>
            {!isInquiryFormView &&
              form.properties.enableCaptcha &&
              hcaptchaSiteKey !== null && (
                <Grid
                  item
                  xs={12}
                  display="grid"
                  justifyContent="center"
                  justifyItems="center"
                  py={2}
                >
                  <Divider sx={{ width: "800px", mb: 2 }} />
                  <HCaptcha
                    sitekey={hcaptchaSiteKey}
                    onVerify={(e) => onVerify(e)}
                    ref={captchaRef}
                  />
                </Grid>
              )}

            {form.formPages.length > 1 && (
              <Pagination
                page={currentPageIndex + 1}
                count={form.formPages.length}
                onChange={(e, v) => updateCurrentPageIndex(v - 1)}
                {...(!isInquiryFormView && {
                  hideNextButton: true,
                  hidePrevButton: true,
                  size: "large",
                  variant: "outlined",
                })}
                renderItem={(item) => {
                  const pageIndex = item.page - 1;
                  const errorCount = pageErrorCounts[pageIndex] || 0;

                  return (
                    <Badge
                      color="error"
                      badgeContent={errorCount > 0 ? errorCount : null}
                    >
                      <PaginationItem {...item} />
                    </Badge>
                  );
                }}
              />
            )}

            {isGuardianView && (
              <Box ml={3}>
                <Button
                  ref={submitButtonRef}
                  variant="contained"
                  sx={{
                    ...submitButton,
                    display: submitButtonRef && "none",
                  }}
                  type="button"
                  disabled={submitDisabled}
                  onClick={processFieldValidations}
                >
                  {form.formPages.length === currentPageIndex + 1
                    ? t("submit")
                    : t("next")}
                </Button>
              </Box>
            )}
            {isFormBuilderView && (
              <Box ml={3}>
                {form.formPages.length !== currentPageIndex + 1 && (
                  <Button
                    ref={submitButtonRef}
                    variant="contained"
                    sx={{
                      ...submitButton,
                      display: submitButtonRef && "none",
                    }}
                    type="button"
                    disabled={submitDisabled}
                    onClick={processFieldValidations}
                  >
                    {t("next")}
                  </Button>
                )}
              </Box>
            )}

            {((isDataView && !formLocked) || isInquiryFormView) && (
              <Button
                ref={submitButtonRef}
                type="submit"
                variant="contained"
                sx={{ ...submitButton, display: submitButtonRef && "none" }}
                disabled={submitDisabled}
              >
                {t("submit")}
              </Button>
            )}
          </Grid>
        )}
      </form>

      {form && searchParams.get("form_response_id") && (
        <Grid container justifyContent="center" pb={3}>
          <Grid>
            <DeleteFormResponse
              formResponseId={searchParams.get("form_response_id")}
              typeDeleteToConfirm={false}
            >
              <Button color="error" variant="contained">
                {t("delete")}
              </Button>
            </DeleteFormResponse>
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
}
