import axios from "../utils/axios-util";

const fetchConfigsEndpoint = "/api/app_configs";

const fetchConfig = async () => {
  const { data } = await axios.get(`${fetchConfigsEndpoint}`);
  return data;
};

const fetchHcaptchaConfig = async () => {
  const { data } = await axios.get(`${fetchConfigsEndpoint}/hcaptcha`);
  return data;
};

export default {
  fetchConfig,
  fetchHcaptchaConfig,
};
