import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Check, Edit } from "@mui/icons-material";
import schoolsService from "../../../../service/schoolsService";
import { SnackbarContext } from "../../../../context/SnackbarContext";

export default function CanvasSettingsAbbreviation({ currentSchool }) {
  const methods = useForm();
  const snackbarContext = useContext(SnackbarContext);

  const abbreviationEmpty =
    currentSchool?.abbreviation === null || currentSchool?.abbreviation === "";
  const [edit, setEit] = useState(!!abbreviationEmpty);

  const handleChange = async () => {
    if (edit === false) {
      setEit(true);
    } else {
      const schoolAbbreviation = methods.getValues("schoolAbbreviation");
      const response = await schoolsService.updateSchoolAbbreviation(
        currentSchool.id,
        {
          schoolAbbreviation,
        }
      );

      if (response) {
        setEit(false);
        snackbarContext.setSnackbar({
          open: true,
          message: "Updated successfully",
          severity: "success",
        });
      }
    }
  };

  useEffect(() => {
    methods.setValue("schoolAbbreviation", currentSchool?.abbreviation);
  }, [currentSchool]);

  return (
    <FormProvider {...methods}>
      <form>
        <FormControl sx={{ width: "25ch" }} variant="outlined">
          <InputLabel>School Abbreviation</InputLabel>
          <OutlinedInput
            id="schoolAbbreviation"
            label="School Abbreviation"
            type="text"
            name="abbreviation"
            {...methods.register("schoolAbbreviation")}
            defaultValue={currentSchool?.abbreviation}
            endAdornment={
              methods.watch("schoolAbbreviation") && (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleChange()} edge="end">
                    {edit ? <Check /> : <Edit />}
                  </IconButton>
                </InputAdornment>
              )
            }
            disabled={!edit}
            fullWidth
          />
        </FormControl>
      </form>
    </FormProvider>
  );
}
