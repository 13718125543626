import React from "react";
import { Box, Typography } from "@mui/material";
import listContainer from "./RecipientDetailsList.styles";

export default function RecipientDetailsList({ recipients }) {
  return (
    <Box sx={listContainer}>
      {recipients.map((recipient) => (
        <Typography key={recipient.id}>
          {`${recipient.name} ${
            recipient.email ? `(${recipient.email})` : ""
          } (${recipient.type})`}
        </Typography>
      ))}
    </Box>
  );
}
