import React, { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CanvasCoursesForm from "./CanvasCoursesForm";
import canvasCoursesService from "../../../../service/canvasCoursesService";
import { SnackbarContext } from "../../../../context/SnackbarContext";

export default function CanvasCourseNew({
  courses,
  setActiveCourse,
  setCreate,
}) {
  const [selectedKlasses, setSelectedKlasses] = useState([]);
  const methods = useForm();
  const snackbarContext = useContext(SnackbarContext);

  const onSubmit = async (data) => {
    try {
      const response = await canvasCoursesService.createCanvasCourse({
        ...data,
        klasses: selectedKlasses,
      });

      if (response) {
        snackbarContext.setSnackbar({
          message: "Course created.",
          severity: "success",
          open: true,
        });

        setActiveCourse(response.data);
        setCreate(false);
      }
    } catch (error) {
      snackbarContext.setSnackbar({
        message: error.response.data.errors[0] || "Error creating course.",
        severity: "error",
        open: true,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <CanvasCoursesForm
        onSubmit={onSubmit}
        courses={courses}
        selectedKlasses={selectedKlasses}
        setSelectedKlasses={setSelectedKlasses}
      />
    </FormProvider>
  );
}
