import axios from "../utils/axios-util";

const messagesEndpoint = "/api/messages";

const fetchRecipients = async (params) => {
  const { data } = await axios.get(`${messagesEndpoint}/recipients`, params);

  return data;
};

const createMessage = async (params) => {
  const { data } = await axios.post(`${messagesEndpoint}`, params);

  return data;
};

const updateMessage = async (id, message) => {
  const { data } = await axios.put(`${messagesEndpoint}/${id}`, message);

  return data;
};

const createMessageAttachments = async (id, body) => {
  await axios.post(`${messagesEndpoint}/${id}/attachments`, body);
};

const listSentMessages = async (params) => {
  const { data } = await axios.get(`${messagesEndpoint}`, params);

  return data;
};

const fetchConversation = async (conversationKey) => {
  const { data } = await axios.get(
    `${messagesEndpoint}/conversation/${conversationKey}`
  );

  return data;
};

const fetchMessageAttachment = async (id) => {
  const { data } = await axios.get(`${messagesEndpoint}/${id}/attachments`);

  return data;
};

const createReply = async (params) => {
  const { data } = await axios.post(`${messagesEndpoint}/create_reply`, params);

  return data;
};

const deleteMessage = async (messageId) => {
  const { data } = await axios.put(
    `${messagesEndpoint}/delete_message/${messageId}`
  );

  return data;
};

const deleteConversation = async (conversationKey) => {
  const { data } = await axios.put(
    `${messagesEndpoint}/delete_conversation/${conversationKey}`
  );

  return data;
};

const readMessages = async (params) => {
  const { data } = await axios.put(`${messagesEndpoint}/read_messages`, params);

  return data;
};

const checkForUnreadMessages = async (params) => {
  const { data } = await axios.get(
    `${messagesEndpoint}/check_for_unread_messages`,
    params
  );
  return data;
};

const recipientsMessages = async (recipientsIds) => {
  const { data } = await axios.get(`${messagesEndpoint}/recipients_messages`, {
    params: { recipient_ids: recipientsIds },
  });

  return data;
};

const recipientDetails = async (params) => {
  const { data } = await axios.get(
    `${messagesEndpoint}/recipient_details`,
    params
  );

  return data;
};

export default {
  fetchRecipients,
  createMessage,
  listSentMessages,
  fetchConversation,
  createReply,
  deleteMessage,
  deleteConversation,
  createMessageAttachments,
  fetchMessageAttachment,
  readMessages,
  checkForUnreadMessages,
  recipientsMessages,
  recipientDetails,
  updateMessage,
};
