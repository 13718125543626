const canvasCoursesFormSyncIcon = {
  color: "#ACACAC",
  width: "70px",
  height: "70px",
  border: "1px solid #ACACAC",
  borderRadius: "5%",
  p: "10px",
};

const canvasCoursesFormContainer = {
  width: "700px",
  p: "30px",
  margin: "auto",
};

const canvasCourseFormSubmitBtn = {
  width: "100%",
  mb: "15px",
};

export {
  canvasCoursesFormSyncIcon,
  canvasCoursesFormContainer,
  canvasCourseFormSubmitBtn,
};
