import React, { useContext, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  CircularProgress,
  Grid,
  Button,
  Box,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  FormControl,
  DialogContentText,
} from "@mui/material";
import { useSearchParams, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Edit } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StudentsTable } from "../components";
import studentsService from "../service/studentsService";
import { pageHeaderTitle, loader } from "../components/sharedStyles";
import TermSelect from "../components/TermSelect";
import {
  cancelButtonStyle,
  editButton,
  filterButton,
  filtersBorder,
  studentListContainer,
} from "./StudentList.style";
import teachersService from "../service/staffsService";
import StaffType from "../utils/constants/staffTypeEnum";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import { PermissionsContext } from "../context/PermissionsContext";
import { sortedGradeLevels } from "../utils/sortedGradeLevels";

const CssSearchField = styled(TextField)({
  "& .MuiInput-root": {
    marginRight: "17px",
    "&:before": {
      borderBottom: "1px solid #22282C !important",
    },
  },
});

export default function StudentList({ isCompact = false }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = React.useState(
    searchParams.get("direction") || "asc"
  );
  const [orderBy, setOrderBy] = React.useState(
    searchParams.get("sort") || "student"
  );
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(searchParams.get("page") || 1);
  const [query, setQuery] = React.useState(searchParams.get("query") || "");
  const [students, setStudents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(searchParams.get("count") || 25);
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [showClearButton, setShowClearButton] = React.useState(
    searchParams.get("query") || false
  );
  const [loading, setLoading] = useState(false);
  const [gradeLevel, setGradeLevel] = useState("all");
  const [levels, setLevels] = useState(["all"]);
  const params = useParams();
  const schoolId = params.school_id;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allStudentIds, setAllStudentIds] = React.useState([]);
  const [totalStudents, setTotalStudents] = React.useState(0);
  const [enrollmentDateDialogOpen, setEnrollmentDateDialogOpen] =
    useState(false);
  const [homeroomTeacherDialogOpen, setHomeroomTeacherDialogOpen] =
    useState(false);
  const [gradeLevelDialogOpen, setGradeLevelDialogOpen] = useState(false);
  /* eslint-disable no-unused-vars */
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);
  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_LIST,
    PERMISSION_TYPES.MANAGE
  );

  const open = Boolean(anchorEl);

  const handleEditClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (data) => {
    searchParams.set("query", data.search);
    setQuery(data.search);
    setSearchParams(searchParams);
  };

  const handleQuickFindChange = (event) => {
    if (event.target.value) setShowClearButton(true);
    else setShowClearButton(false);
  };

  const handleClickOnClearButton = () => {
    setValue("search", "");
    setShowClearButton(false);
    handleSearch(getValues());
  };

  const handleStudentFilterClick = (value) => {
    if (value === gradeLevel) return;
    setGradeLevel(value);
    setSelected([]);
  };

  const handleOptionSelect = (option) => {
    handleClose();
    switch (option) {
      case "enrollment_date":
        setEnrollmentDateDialogOpen(true);
        break;
      case "homeroom_teacher":
        setHomeroomTeacherDialogOpen(true);
        break;
      case "grade_level":
        setGradeLevelDialogOpen(true);
        break;
      default:
    }
  };

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const response = await studentsService.fetchAllStudents(
        { params: { school_id: schoolId, term_id: searchParams.get("term") } },
        page,
        orderBy,
        order,
        query,
        count,
        "",
        true,
        gradeLevel
      );
      setStudents(response.data);
      setLevels([...new Set(response.pagination.grade_levels)]);
      setTotalPages(response.pagination.total_pages);
      setAllStudentIds(response.all_student_ids);
      setTotalStudents(response.pagination.total_count);
    } catch (error) {
      console.error("Failed to fetch students:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEnrollmentDateConfirm = async (newDate) => {
    try {
      await studentsService.bulkEditStudents(
        selected,
        "enrollment_date",
        newDate
      );
      console.log(
        `Updated enrollment date to ${newDate} for ${selected.length} students`
      );
      fetchStudents();
      setSelected([]);
    } catch (error) {
      console.error("Failed to update enrollment date:", error);
    }
    setEnrollmentDateDialogOpen(false);
  };

  const handleHomeroomTeacherConfirm = async (newTeacherId) => {
    try {
      await studentsService.bulkEditStudents(
        selected,
        "homeroom_teacher_id",
        newTeacherId
      );
      fetchStudents();
      setSelected([]);
    } catch (error) {
      console.error("Failed to update homeroom teacher:", error);
    }
    setHomeroomTeacherDialogOpen(false);
  };

  const handleGradeLevelConfirm = async (newGradeLevel) => {
    try {
      await studentsService.bulkEditStudents(
        selected,
        "grade_level",
        newGradeLevel
      );
      fetchStudents();
      setSelected([]);
    } catch (error) {
      console.error("Failed to update grade level:", error);
    }
    setGradeLevelDialogOpen(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await studentsService.fetchAllStudents(
        { params: { school_id: schoolId, term_id: searchParams.get("term") } },
        page,
        orderBy,
        order,
        query,
        count,
        "",
        true,
        gradeLevel
      );
      const queryPage = searchParams.get("page") || 1;
      const lastPage = response.pagination.total_pages;
      const currentPage = queryPage > lastPage ? lastPage : queryPage;
      setStudents(response.data);
      setLevels([...new Set(response.pagination.grade_levels)]);
      setTotalPages(lastPage);
      setPage(currentPage);
      setLoading(false);
      setAllStudentIds(response.all_student_ids);
      setTotalStudents(response.pagination.total_count);
    })();

    setOrder(searchParams.get("direction") || "asc");
    setOrderBy(searchParams.get("sort") || "student");
    setQuery(searchParams.get("query") || "");
  }, [
    page,
    order,
    orderBy,
    query,
    count,
    searchParams.get("term"),
    gradeLevel,
  ]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <div>
      <Container
        maxWidth={false}
        variant="header"
        sx={studentListContainer(isCompact)}
      >
        <Stack
          direction={isCompact ? "column" : "row"}
          alignItems={isCompact ? "baseline" : "center"}
          justifyContent={isCompact ? "start" : "space-between"}
        >
          <Typography sx={pageHeaderTitle}>Students</Typography>

          {!isCompact && (
            <Stack direction="row">
              <form
                onSubmit={handleSubmit(handleSearch)}
                onChange={handleQuickFindChange}
              >
                <CssSearchField
                  label="Quick Find"
                  variant="standard"
                  color="primary"
                  fullWidth={!!isCompact}
                  defaultValue={query}
                  {...register("search")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickOnClearButton}
                          sx={cancelButtonStyle(showClearButton)}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                        <IconButton type="submit">
                          <SearchIcon size="small" color="primary" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>

              <TermSelect
                schoolId={params.school_id}
                fetchUpcomingTerms
                findCurrentTerm
                showDefault
              />
            </Stack>
          )}
        </Stack>
        <Grid item xs={7} sx={filtersBorder}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Button
              sx={filterButton(gradeLevel === "all")}
              size="small"
              onClick={() => handleStudentFilterClick("all")}
            >
              All
            </Button>
            {sortedGradeLevels(levels).map((level) => (
              <Button
                sx={filterButton(gradeLevel === level)}
                size="small"
                key={level}
                onClick={() => handleStudentFilterClick(level)}
              >
                {level}
              </Button>
            ))}
            {selected.length > 0 && managePermission && !isCompact && (
              <Box sx={editButton}>
                <Button
                  size="small"
                  onClick={handleEditClick}
                  startIcon={<Edit />}
                >
                  Edit
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={() => handleOptionSelect("enrollment_date")}
                  >
                    Enrollment Date
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleOptionSelect("homeroom_teacher")}
                  >
                    Homeroom Teacher
                  </MenuItem>
                  <MenuItem onClick={() => handleOptionSelect("grade_level")}>
                    Grade Level
                  </MenuItem>
                </Menu>
                <EnrollmentDateDialog
                  open={enrollmentDateDialogOpen}
                  onClose={() => setEnrollmentDateDialogOpen(false)}
                  onConfirm={handleEnrollmentDateConfirm}
                  selectedCount={selected.length}
                />
                <HomeroomTeacherDialog
                  open={homeroomTeacherDialogOpen}
                  onClose={() => setHomeroomTeacherDialogOpen(false)}
                  onConfirm={handleHomeroomTeacherConfirm}
                  selectedCount={selected.length}
                />
                <GradeLevelDialog
                  open={gradeLevelDialogOpen}
                  onClose={() => setGradeLevelDialogOpen(false)}
                  onConfirm={handleGradeLevelConfirm}
                  selectedCount={selected.length}
                />
                {`(${selected.length} students selected. `}{" "}
                <Box
                  sx={{
                    display: "inline",
                    color: "#2196F3",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelected(allStudentIds)}
                >
                  &nbsp;Select all filtered students.
                </Box>
                )
              </Box>
            )}
          </Box>
          {!isCompact && (
            <Box sx={{ marginLeft: "auto", paddingLeft: "16px" }}>
              <span style={{ fontSize: "14px" }}>
                <span style={{ fontWeight: "500" }}>{totalStudents} </span>
                students
              </span>
            </Box>
          )}
        </Grid>
        <StudentsTable
          isCompact={isCompact}
          students={students}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          selected={selected}
          order={order}
          orderBy={orderBy}
          setSelected={setSelected}
          setPage={setPage}
          totalPages={totalPages}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          page={page}
          gradeLevel={gradeLevel}
          count={count}
          setCount={setCount}
          totalStudents={totalStudents}
        />
      </Container>
    </div>
  );
}

export function EnrollmentDateDialog({
  open,
  onClose,
  onConfirm,
  selectedCount,
}) {
  const [enrollmentDate, setEnrollmentDate] = useState(null);

  const handleDateChange = (newDate) => {
    setEnrollmentDate(newDate);
  };

  const handleConfirm = () => {
    if (enrollmentDate) {
      // Format the date as YYYY-MM-DD for the backend
      const formattedDate = enrollmentDate.format("YYYY-MM-DD");
      onConfirm(formattedDate);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Enrollment Date</DialogTitle>
      <DialogContent>
        <DesktopDatePicker
          label="Enrollment Date"
          inputFormat="MM/DD/YYYY"
          value={enrollmentDate}
          onChange={handleDateChange}
          renderInput={(params) => (
            <TextField {...params} fullWidth margin="normal" />
          )}
        />
        <Typography variant="body2">
          This will update the enrollment date for {selectedCount} selected
          students.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={!enrollmentDate}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function HomeroomTeacherDialog({
  open,
  onClose,
  onConfirm,
  selectedCount,
}) {
  const [teacherId, setTeacherId] = useState("");
  const [teachers, setTeachers] = useState([]);
  const params = useParams();

  useEffect(() => {
    const loadTeachers = async () => {
      const response = await teachersService.fetchAllStaffs({
        params: { school_id: params.school_id, staff_type: StaffType.TEACHER },
      });
      setTeachers(response);
    };

    loadTeachers();
  }, [params.school_id]);

  const handleTeacherChange = (event) => {
    setTeacherId(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Homeroom Teacher</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="homeroom_teacher_label">Homeroom Teacher</InputLabel>
          <Select
            labelId="homeroom_teacher_label"
            id="homeroom_teacher"
            label="Homeroom Teacher"
            value={teacherId}
            onChange={handleTeacherChange}
          >
            <MenuItem disabled value="">
              Homeroom Teacher
            </MenuItem>
            {teachers.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {`${option.first_name} ${option.last_name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body2">
          This will update the homeroom teacher for {selectedCount} selected
          students.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onConfirm(teacherId)}
          variant="contained"
          disabled={!teacherId}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function GradeLevelDialog({ open, onClose, onConfirm, selectedCount }) {
  const [gradeLevel, setGradeLevel] = useState("");
  const gradeLevels = [
    "PK",
    "TK",
    "K",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Grade Level</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="gradeLevel"
          select
          label="New Grade Level"
          fullWidth
          variant="standard"
          value={gradeLevel}
          onChange={(e) => setGradeLevel(e.target.value)}
        >
          {gradeLevels.map((grade) => (
            <MenuItem key={grade} value={grade}>
              {grade}
            </MenuItem>
          ))}
        </TextField>
        <Typography variant="body2">
          This will update the grade level for {selectedCount} selected
          students.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!gradeLevel}
          onClick={() => onConfirm(gradeLevel)}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function ConfirmFiltersDialog({
  open,
  onClose,
  onConfirm,
  title,
  content,
  selectedCount,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <DialogContentText>
          This will clear the selection of {selectedCount} students.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
