import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import CanvasCoursesForm from "./CanvasCoursesForm";
import canvasCoursesService from "../../../../service/canvasCoursesService";
import { loader } from "../../../sharedStyles";

export default function CanvasCourseEdit({
  setDeleteDialogOpen,
  courses,
  activeCourse,
  snackbarContext,
}) {
  const [selectedKlasses, setSelectedKlasses] = useState([]);
  const methods = useForm();
  const [canvasCourse, setCanvasCourse] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCourse = async () => {
    setLoading(true);

    const response = await canvasCoursesService.fetchCanvasCourse(
      activeCourse.id
    );

    if (response) {
      setCanvasCourse(response);
      setLoading(false);
      methods.reset(); // to update form fields as we change courses
    }
  };

  const onSubmit = async (data) => {
    const response = await canvasCoursesService.updateCanvasCourse({
      ...data,
      id: activeCourse.id,
      klasses: selectedKlasses,
    });

    if (response) {
      snackbarContext.setSnackbar({
        message: "Course updated.",
        severity: "success",
        open: true,
      });
    }
  };

  useEffect(() => {
    getCourse();
  }, [activeCourse]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  if (canvasCourse) {
    return (
      <FormProvider {...methods}>
        <CanvasCoursesForm
          onSubmit={onSubmit}
          courses={courses}
          activeCourse={canvasCourse}
          selectedKlasses={selectedKlasses}
          setSelectedKlasses={setSelectedKlasses}
          setDeleteDialogOpen={setDeleteDialogOpen}
        />
      </FormProvider>
    );
  }
}
