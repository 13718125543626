import React, { useState, useEffect, useContext } from "react";
import {
  useParams,
  useSearchParams,
  Link as RouterLink,
  useOutletContext,
} from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Drawer,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  pageHeaderTitle,
  mr10,
  py30,
  mr30,
  rightDrawerContainer,
  loader,
  black,
  gcLogoContainer,
} from "../components/sharedStyles";
import {
  divider,
  subjectsListTable,
  subjectsListHead,
  subjectsListCell,
  subjectsListCellName,
  tableContainer,
  hidden,
} from "./MySubjects.styles";
import {
  klassesListName,
  klassesListSubject,
  klassesListDescription,
  klassesSettingsCell,
  customOrderSwitchers,
} from "./MyKlasses.styles";
import klassesService from "../service/klassesService";
import employmentService from "../service/employmentService";
import KlassForm from "../components/Klass/KlassForm";
import gcLogoPath from "../utils/constants/googleClassroom";
import TermSelect from "../components/TermSelect";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import Authorize from "../components/Authorize";
import ListPagination from "../components/ListPagination";
import MyKlassesDroppable from "../components/MyKlasses/MyKlassesDroppable";
import { SnackbarContext } from "../context/SnackbarContext";
import AlertDialog from "../components/AlertDialog";

export default function MyKlasses() {
  const snackbarContext = useContext(SnackbarContext);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const schoolId = params.school_id;
  const [
    klassesDrawerStatus,
    setSnackBarOpen,
    teacher,
    ,
    ,
    ,
    ,
    ,
    user,
    ,
    ,
    currentSchool,
  ] = useOutletContext();
  const [klasses, setKlasses] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const page = searchParams.get("page") || 1;
  const count = searchParams.get("count") || 50;
  const [totalKlasses, setTotalKlasses] = useState(0);
  const [klassesDrawerStatusEdit, setKlassesDrawerStatusEdit] = useState(false);
  const [activeKlass, setActiveKlass] = useState(null);
  const [menuAnchors, setMenuAnchors] = useState({});
  const [loading, setLoading] = useState(false);
  const { hasPermission } = useContext(PermissionsContext);
  const [customizeOrder, setCustomizeOrder] = useState(false);
  const [orderPreference, setOrderPreference] = useState(null);
  const hasOrderPreference = Object.keys(orderPreference || {}).length > 0;
  const orderBy =
    searchParams.get("sort") || (hasOrderPreference ? "custom" : "name");
  const order = searchParams.get("direction") || "asc";
  const useCustomOrder = orderBy === "custom";
  const termId = searchParams.get("term");
  const [confirmClearOrderPreferences, setConfirmClearOrderPreferences] =
    useState(false);

  const managePermission = hasPermission(
    APP_PERMISSIONS.MY_KLASSES,
    PERMISSION_TYPES.MANAGE
  );

  const setSearchParamsWithPrev = (newParams) => {
    setSearchParams((prevParams) => {
      Object.entries(newParams).forEach(([key, value]) => {
        prevParams.set(key, value);
      });

      return prevParams;
    });
  };

  const handleClick = (event, id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleClose = (id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const handleGoogleLogin = async (klassId) => {
    if (!user.has_google_access) {
      const redirectParams = {
        klass_id: klassId,
        school_id: schoolId,
        user_id: user.id,
      };
      const encodedParams = btoa(JSON.stringify(redirectParams));

      window.location.replace(`/auth/google_oauth2?state=${encodedParams}`);
    } else {
      window.location.replace(
        `/school/${schoolId}/klasses/${klassId}/class-select`
      );
    }
  };

  const getKlassesOrderPreference = async () => {
    setOrderPreference(null);
    const response = await employmentService.fetchCurrentEmployment();
    setOrderPreference(
      response?.data?.employment?.klasses_order_preference?.[termId] || {}
    );
  };

  const getKlasses = async () => {
    if (!klassesDrawerStatus && !klassesDrawerStatusEdit) {
      setLoading(true);
    }

    const response = await klassesService.fetchAllKlasses({
      params: {
        school_id: schoolId,
        subject_id: null,
        order,
        orderBy,
        count,
        page,
        term_id: termId,
        prek: false,
        paginated: true,
        roster_only: true,
      },
    });

    if (response.data) {
      setKlasses(response.data.klasses);
      setTotalKlasses(response.pagination.total_count);
      const lastPage = response.pagination.total_pages;
      setTotalPages(lastPage);
    }
    setLoading(false);
  };

  const getActiveKlass = async (klassId) => {
    const response = await klassesService.fetchKlass(klassId);
    if (response.data) {
      if (termId) {
        response.data.klass_schedule_details =
          response.data.klass_schedule_details.filter(
            (detail) => detail.klass_schedule.term_id === Number(termId)
          );
        setActiveKlass(response.data);
      } else {
        setActiveKlass(response.data);
      }
    }
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={hidden} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";

    setSearchParamsWithPrev({
      sort: property,
      direction,
      page: 1,
    });
  };

  const handleKlassesDrawer = (status, klassId = null) => {
    handleClose(klassId);

    setKlassesDrawerStatusEdit(status);
    if (status === false) {
      setActiveKlass(null);
    } else if (klassId) {
      getActiveKlass(klassId);
    }
  };

  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const klassLink = (klass) => {
    if (hasAnyPermissionType(APP_PERMISSIONS.KLASSES_STUDENTS)) {
      return `/school/${schoolId}/subjects/${klass.subject_id}/students?classes=${klass.id}&term=${termId}`;
    }

    if (hasAnyPermissionType(APP_PERMISSIONS.KLASSES_ASSIGNMENTS)) {
      return `/school/${schoolId}/subjects/${klass.subject_id}/assignments?classes=${klass.id}&term=${termId}`;
    }

    if (hasAnyPermissionType(APP_PERMISSIONS.KLASSES_GRADEBOOK)) {
      return `/school/${schoolId}/subjects/${klass.subject_id}/gradebook?classes=${klass.id}&term=${termId}`;
    }

    if (hasAnyPermissionType(APP_PERMISSIONS.KLASSES_TERM_GRADES)) {
      return `/school/${schoolId}/subjects/${klass.subject_id}/term-grade/class-grade?classes=${klass.id}&term=${termId}`;
    }

    return ``;
  };

  const updateKlassesOrder = async (orderedIds) => {
    try {
      await employmentService.updateKlassesOrder({
        term_id: termId,
        ordered_klasses_ids: orderedIds,
      });

      setOrderPreference(orderedIds);

      snackbarContext.setSnackbar({
        open: true,
        message: "Classes order saved.",
        severity: "success",
      });
    } catch (error) {
      snackbarContext.setSnackbar({
        open: true,
        message: "Error saving classes order.",
        severity: "error",
      });
    }
  };

  const handleChangePage = (event, value) => {
    setSearchParamsWithPrev({
      page: value,
    });
  };

  const handleUseCustomOrderChange = (event) => {
    setSearchParamsWithPrev({
      page: 1,
      sort: event.target.checked ? "custom" : "name",
    });
  };

  const handleResetOrderingPreferences = async () => {
    await updateKlassesOrder([]);
    setKlasses(null);
    getKlasses();
    setOrderPreference(null);
    setCustomizeOrder(false);
    setConfirmClearOrderPreferences(false);
  };

  useEffect(() => {
    getKlassesOrderPreference();
  }, [searchParams, customizeOrder]);

  useEffect(() => {
    if (
      termId !== "select" &&
      !klassesDrawerStatusEdit &&
      !customizeOrder &&
      orderPreference
    ) {
      getKlasses();
    }
  }, [searchParams, klassesDrawerStatusEdit, customizeOrder, orderPreference]);

  if (loading || !klasses) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.MY_KLASSES)}>
      <Box sx={tableContainer}>
        <Grid container justifyContent="between" alignItems="center">
          <Grid item md={4}>
            <Typography sx={pageHeaderTitle}>Classes</Typography>
          </Grid>
          <Grid item md={6} />
          <Grid
            item
            md={2}
            container
            alignItems="center"
            sx={{ height: "55px" }}
          >
            <TermSelect
              schoolId={schoolId}
              fetchUpcomingTerms
              findCurrentTerm
              showDefault
            />
          </Grid>
        </Grid>
        <Divider sx={divider} />
      </Box>
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              <Box sx={customOrderSwitchers}>
                {!customizeOrder && hasOrderPreference && (
                  <FormControlLabel
                    label="Use my custom order"
                    control={
                      <Switch
                        checked={useCustomOrder}
                        onChange={handleUseCustomOrderChange}
                        color="info"
                      />
                    }
                  />
                )}

                {Object.keys(orderPreference || {}).length > 0 &&
                  customizeOrder && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setConfirmClearOrderPreferences(true)}
                      sx={{ marginRight: "10px" }}
                    >
                      Clear order preferences
                    </Button>
                  )}

                {klasses?.length > 0 && (
                  <Button
                    variant={customizeOrder ? "contained" : "outlined"}
                    size="small"
                    onClick={() => {
                      setSearchParamsWithPrev({
                        page: 1,
                        sort: "custom",
                      });
                      setCustomizeOrder((prev) => !prev);
                    }}
                    color={customizeOrder ? "info" : "inherit"}
                  >
                    {customizeOrder ? "Save Order" : "Manage order"}
                  </Button>
                )}
              </Box>

              {klasses?.length > 0 && customizeOrder && (
                <MyKlassesDroppable
                  klasses={klasses}
                  setKlasses={setKlasses}
                  orderPreference={orderPreference}
                  setOrderPreference={setOrderPreference}
                  updateKlassesOrder={updateKlassesOrder}
                />
              )}

              {klasses?.length > 0 && !customizeOrder && (
                <>
                  <TableContainer sx={py30}>
                    <Table sx={subjectsListTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={subjectsListHead(false, orderBy === "name")}
                            onClick={() => handleRequestSort("name")}
                          >
                            <Typography sx={klassesListName}>
                              NAME {renderSortChevron("name")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={subjectsListHead(false, orderBy === "abbr")}
                            onClick={() => handleRequestSort("abbr")}
                          >
                            <Typography sx={mr10}>
                              ABBREVIATION {renderSortChevron("abbr")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={subjectsListHead(false, orderBy === "subject")}
                            onClick={() => handleRequestSort("subject")}
                          >
                            <Typography sx={klassesListSubject}>
                              SUBJECT / COURSE {renderSortChevron("subject")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={subjectsListHead(
                              false,
                              orderBy === "description"
                            )}
                            onClick={() => handleRequestSort("description")}
                          >
                            <Typography sx={klassesListDescription}>
                              DESCRIPTION {renderSortChevron("description")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={subjectsListHead(
                              false,
                              orderBy === "assignments"
                            )}
                            onClick={() => handleRequestSort("assignments")}
                          >
                            <Typography>
                              ASSIGNMENTS {renderSortChevron("assignments")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={subjectsListHead(false, orderBy === "students")}
                            onClick={() => handleRequestSort("students")}
                          >
                            <Typography>
                              STUDENTS {renderSortChevron("students")}
                            </Typography>
                          </TableCell>
                          <TableCell sx={subjectsListHead} />
                          <TableCell sx={subjectsListHead} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {klasses.map((klass) => (
                          <TableRow hover key={klass.id}>
                            <TableCell sx={subjectsListCellName}>
                              <Link
                                to={klassLink(klass)}
                                underline="none"
                                component={RouterLink}
                              >
                                <Typography>
                                  <span>{klass.name}</span>
                                </Typography>
                              </Link>
                            </TableCell>
                            <TableCell sx={subjectsListCell()}>
                              <Typography>
                                <span>{klass.abbreviation}</span>
                              </Typography>
                            </TableCell>
                            <TableCell sx={subjectsListCell()}>
                              <Typography>{klass.subject.name}</Typography>
                            </TableCell>
                            <TableCell sx={subjectsListCell()}>
                              <Typography>{klass.description}</Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={subjectsListCell(true)}
                            >
                              <Typography sx={mr30}>
                                {klass.assignments_count}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={subjectsListCell(true)}
                            >
                              <Typography sx={mr30}>
                                {klass.students_count}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {klass.lms_id && (
                                <Box
                                  sx={gcLogoContainer}
                                  {...(klass.lms_link && {
                                    onClick: () =>
                                      window.open(klass.lms_link, "_blank"),
                                  })}
                                >
                                  <img src={gcLogoPath} alt="logo" />
                                </Box>
                              )}
                            </TableCell>
                            {managePermission && (
                              <TableCell sx={klassesSettingsCell}>
                                <IconButton
                                  sx={black}
                                  aria-label="more"
                                  id={`long-button-${klass.id}`}
                                  aria-controls={
                                    menuAnchors[klass.id]
                                      ? `long-menu-${klass.id}`
                                      : undefined
                                  }
                                  aria-expanded={
                                    menuAnchors[klass.id] ? "true" : undefined
                                  }
                                  aria-haspopup="true"
                                  onClick={(e) => handleClick(e, klass.id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id={`long-menu-${klass.id}`}
                                  anchorEl={menuAnchors[klass.id]}
                                  open={Boolean(menuAnchors[klass.id])}
                                  onClose={() => handleClose(klass.id)}
                                >
                                  {hasAnyPermissionType(
                                    APP_PERMISSIONS.EDIT_KLASS
                                  ) && (
                                    <MenuItem
                                      autoFocus={false}
                                      onClick={() => {
                                        handleKlassesDrawer(true, klass.id);
                                        handleClose(klass.id);
                                      }}
                                    >
                                      Edit Class
                                    </MenuItem>
                                  )}
                                  <MenuItem
                                    onClick={() => {
                                      handleGoogleLogin(klass.id);
                                      handleClose(klass.id);
                                    }}
                                  >
                                    Google Classroom
                                  </MenuItem>
                                </Menu>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <ListPagination
                    totalPages={totalPages}
                    handleChangePage={handleChangePage}
                    page={page}
                    count={count}
                    setCount={(counter) => {
                      setSearchParamsWithPrev({ count: counter });
                    }}
                    total={totalKlasses}
                  />
                </>
              )}

              {klasses && !klasses?.length && (
                <Typography align="center">No classes found.</Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Drawer anchor="right" open={klassesDrawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          {activeKlass ? (
            <KlassForm
              teacherId={teacher.id}
              schoolId={schoolId}
              handleKlassesDrawer={handleKlassesDrawer}
              activeKlass={activeKlass}
              setSnackBarOpen={setSnackBarOpen}
              termId={termId}
              currentSchool={currentSchool}
            />
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </Box>
      </Drawer>

      <AlertDialog
        isOpen={confirmClearOrderPreferences}
        handleClose={() => setConfirmClearOrderPreferences(false)}
        handleConfirm={handleResetOrderingPreferences}
      />
    </Authorize>
  );
}
