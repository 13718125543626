import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import admissionProcessService from "../../../../service/admissionProcessService";
import {
  buttonWithStartIcon,
  rightDrawerContainer,
} from "../../../sharedStyles";
import AddNote from "./AddNote";
import NoteItem from "./NoteItem";

export default function Notes() {
  const [addNoteDrawerOpen, setAddNoteDrawerOpen] = useState(false);
  const [processInstance] = useOutletContext();
  const [notes, setNotes] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [noteItem, setNoteItem] = useState(null);

  useEffect(() => {
    if (processInstance && !addNoteDrawerOpen) {
      const queryParams = { process_instance_id: processInstance.id };
      if (selectedAuthor) queryParams.staff_id = selectedAuthor;
      admissionProcessService.notes(queryParams).then((response) => {
        setNotes(response.data);
        setAuthors(response.data.map((d) => d.staff));
      });
    }
  }, [processInstance, addNoteDrawerOpen]);

  const flagNote = (note, index) => {
    admissionProcessService
      .flagNote({ note_id: note.id, flag: !note.flag })
      .then(() => {
        const clonedNotes = [...notes];
        clonedNotes[index].flag = !note.flag;
        setNotes(clonedNotes);
      });
  };

  return (
    <Grid container rowGap={2} alignItems="center">
      <Grid
        item
        md={11}
        pl="0 !important"
        container
        justifyContent="space-between"
        mb={1}
      >
        <Grid item md={2}>
          <Button
            sx={buttonWithStartIcon}
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setAddNoteDrawerOpen(true)}
          >
            Add Note
          </Button>
        </Grid>
        <Grid item md={2.5}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="author-filter-label">Filter by author</InputLabel>
            <Select
              labelId="author-filter-label"
              id="author-filter"
              label="Filter by author"
              value={selectedAuthor}
              onChange={(e) => setSelectedAuthor(e.target.value)}
            >
              {authors.map((author) => (
                <MenuItem
                  value={author.id}
                >{`${author.first_name} ${author.last_name}`}</MenuItem>
              ))}
              {/* Add more authors as needed */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item md={1} />
      {notes.map((note, index) => (
        <NoteItem
          note={note}
          flagNote={flagNote}
          index={index}
          key={note.id}
          setNoteItem={setNoteItem}
          setAddNoteDrawerOpen={setAddNoteDrawerOpen}
        />
      ))}
      <Drawer open={addNoteDrawerOpen} anchor="right">
        <Box sx={rightDrawerContainer}>
          <AddNote
            processInstanceId={processInstance?.id}
            setAddNoteDrawerOpen={setAddNoteDrawerOpen}
            noteItem={noteItem}
            setNoteItem={setNoteItem}
          />
        </Box>
      </Drawer>
    </Grid>
  );
}
