import React from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import canvasCourseRow from "./CanvasCourseRow.styles";

export default function CanvasCourseRow({
  course,
  setActiveCourse,
  activeCourse,
  setCreate,
}) {
  const handleOpenDrawer = () => {
    setActiveCourse(course);
    setCreate(false);
  };

  return (
    <TableRow
      onClick={() => handleOpenDrawer(course)}
      hover
      sx={canvasCourseRow(activeCourse.id === course.id)}
    >
      <TableCell>
        <Typography>{course.name}</Typography>
      </TableCell>
      <TableCell padding="none" align="center">
        <Typography>{course.canvas_sis_id}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{course.klasses_count}</Typography>
      </TableCell>
    </TableRow>
  );
}
