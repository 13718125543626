import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams, useOutletContext } from "react-router-dom";
import classScheduleService from "../../../service/classScheduleService";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import moment from "../../../utils/constants/momentConfig";
import { px0 } from "../../sharedStyles";
import {
  classSchedulesBtn,
  classSchedulesBtnActive,
  classSchedulesSubtitle,
  classSchedulesTitle,
  classSchedulesTitleSection,
  classSchedulesTop,
} from "./ClassScheduleDetails.styles";
import ClassScheduleListView from "./ClassScheduleListView";
import ClassSchedulesDayView from "./DayView/ClassSchedulesDayView";
import subjectService from "../../../service/subjectService";

export default function ClassScheduleDetails() {
  const params = useParams();
  const [activeViewMode, setActiveViewMode] = useState("class");
  const [classSchedule, setClassSchedule] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [dayTemplate, setDayTemplate] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classesFilter, setClassesFilter] = useState([]);
  const [teachersFilter, setTeachersFilter] = useState([]);
  const [studentsFilter, setStudentsFilter] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTrack, setSelectedTrack] = useState("");
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [changingClasses, setChangingClasses] = useState(false);

  const defaultSelectedParam = (param) => {
    if (searchParams.get(param) && searchParams.get(param) !== "0") {
      return searchParams.get(param);
    }
    return "";
  };

  const [selectedClass, setSelectedClass] = useState(
    defaultSelectedParam("class")
  );
  const [selectedTeacher, setSelectedTeacher] = useState(
    defaultSelectedParam("teacher")
  );
  const [selectedStudent, setSelectedStudent] = useState(
    defaultSelectedParam("student")
  );
  const [order, setOrder] = useState(searchParams.get("direction") || "asc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [drawerStatusEdit, setDrawerStatusEdit] = useState(false);
  const [
    // eslint-disable-next-line no-unused-vars
    selectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    setSelectedSchoolYear,
    // eslint-disable-next-line no-unused-vars
    termsDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    setSnackBarOpen,
    // eslint-disable-next-line no-unused-vars
    dayTemplateDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    schoolDaysDrawerStatus,
    // eslint-disable-next-line no-unused-vars
    classScheduleDrawerStatus,
    klassesDrawerStatus,
    bulkEnrollDrawerStatus,
    currentSchool,
  ] = useOutletContext();

  const isButtonActive = (viewMode) =>
    viewMode === activeViewMode ? classSchedulesBtnActive : classSchedulesBtn;

  const sortClasses = (_classSchedule) => {
    let list = [];
    if (orderBy === "name") {
      if (order === "asc") {
        list = _classSchedule?.klasses?.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      } else {
        list = _classSchedule?.klasses?.sort((a, b) =>
          a.name < b.name ? 1 : -1
        );
      }
    }
    if (orderBy === "subject") {
      if (order === "asc") {
        list = _classSchedule?.klasses?.sort((a, b) =>
          a.subject.name > b.subject.name ? 1 : -1
        );
      } else {
        list = _classSchedule?.klasses?.sort((a, b) =>
          a.subject.name < b.subject.name ? 1 : -1
        );
      }
    }

    if (list) {
      setClassSchedule({ ..._classSchedule, klasses: list });
    }
  };

  const getKlasses = async () => {
    const response = await classScheduleService.fetchKlasses(
      params.class_schedule_id,
      {
        params: {
          order,
          order_by: orderBy,
          subject_id: searchParams.get("subjectId"),
        },
      }
    );

    if (response.data) {
      setClasses(response.data);
      setLoading(false);
      setChangingClasses(false);
    }
  };

  const fetchAllSubjectsInTerm = async (termId) => {
    const response = await subjectService.fetchAllSubjectsInTerm({
      params: {
        school_id: params.school_id,
        term_id: termId,
        order: "asc",
        orderBy: "name",
      },
    });

    if (response.data.length > 0) {
      setSubjects(response.data);

      if (!searchParams.get("subjectId")) {
        searchParams.set("subjectId", response.data[0].id);
        setSearchParams(searchParams);
      }
    } else {
      setLoading(false);
    }
  };

  const fetchClassSchedule = async (dayChanged = false) => {
    setLoading(true);

    if (!bulkEnrollDrawerStatus && !drawerStatusEdit) {
      const response = await classScheduleService.fetchClassSchedule(
        params.school_id,
        params.class_schedule_id,
        searchParams.get("date"),
        selectedClass,
        selectedTeacher,
        selectedStudent,
        order,
        orderBy,
        selectedTrack
      );

      if (response.data) {
        setClassSchedule(response.data.klass_schedule);
        setPeriods(response.data.periods);
        setDayTemplate(response.data.day_template);
        // so it first loads the klass_schedule, then it fetches the subjects
        // so it loads in order and the subjects are already loaded when the klasses are fetched
        fetchAllSubjectsInTerm(response.data.klass_schedule.term_id);

        if (dayChanged) {
          setSelectedTrack(response.data.day_template?.tracks?.[0]);
        }
      }
    }
  };

  const fetchFilters = async () => {
    const response = await classScheduleService.fetchFiltersBySchedule(
      params.class_schedule_id,
      params.school_id
    );
    if (response.data) {
      setClassesFilter(response.data.klasses);
      setTeachersFilter(response.data.teachers);
      setStudentsFilter(response.data.students);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    sortClasses(classSchedule);
  }, [order, orderBy]);

  useEffect(() => {
    fetchClassSchedule();
  }, [
    selectedClass,
    selectedTeacher,
    selectedStudent,
    searchParams.get("sort"),
    searchParams.get("direction"),
    selectedTrack,
  ]);

  useEffect(() => {
    // just to keep the loading icon while the subjects are being fetched
    if (searchParams.get("subjectId")) {
      getKlasses();
    } else {
      setLoading(true);
    }
  }, [searchParams.get("subjectId")]);

  // SO: What I am doing here is ensuring to only refresh the subject class count
  // and the class list when the drawer is closed, to avoid unnecessary API calls

  // drawerStatusEdit = edit klass flyout
  // klassesDrawerStatus = new class flyout
  // bulkEnrollDrawerStatus = bulk enroll flyout
  const drawerStatus =
    drawerStatusEdit || klassesDrawerStatus || bulkEnrollDrawerStatus;

  // I'm using useRef to keep track of the previous drawer status
  const prevDrawerStatus = useRef(drawerStatus);

  useEffect(() => {
    if (prevDrawerStatus.current === true && drawerStatus === false) {
      // This means the drawer was open and is now closed
      fetchClassSchedule();
      getKlasses();
    }

    prevDrawerStatus.current = drawerStatus;
  }, [drawerStatus]);

  // EXPLANATION:
  // prevDrawerStatusRef keeps track of the previous value of drawerStatusEdit.
  // Inside useEffect, we check if the previous value was true (open) and the current value is false (closed).
  // If so, we trigger fetchClassSchedule() and getKlasses() to refresh the class list and subject class count.
  // Finally, we update prevDrawerStatusRef.current so that it reflects the latest state for the next render.

  return (
    <>
      <Stack direction="row" sx={classSchedulesTop}>
        {classSchedule && (
          <>
            <Box sx={classSchedulesTitleSection}>
              <Typography sx={classSchedulesTitle}>
                {classSchedule?.name}
              </Typography>
              <Typography sx={classSchedulesSubtitle}>
                {`${moment(classSchedule?.term?.start_date).format(
                  dateTimeFormats.MMMDYYYY
                )} -
                ${moment(classSchedule?.term?.end_date).format(
                  dateTimeFormats.MMMDYYYY
                )}`}
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => setActiveViewMode("class")}
                sx={isButtonActive("class")}
              >
                Class List
              </Button>
              <Button
                sx={isButtonActive("day")}
                onClick={() => setActiveViewMode("day")}
              >
                Day View
              </Button>
            </Box>
          </>
        )}
      </Stack>

      <Box sx={px0}>
        {activeViewMode === "class" ? (
          <ClassScheduleListView
            classSchedule={classSchedule}
            loading={loading}
            classes={classes}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            schoolId={params.school_id}
            drawerStatusEdit={drawerStatusEdit}
            setDrawerStatusEdit={setDrawerStatusEdit}
            schoolYearId={selectedSchoolYear?.id}
            currentSchool={currentSchool}
            subjects={subjects}
            setChangingClasses={setChangingClasses}
            changingClasses={changingClasses}
          />
        ) : (
          <ClassSchedulesDayView
            term={classSchedule?.term}
            periods={periods}
            dayTemplate={dayTemplate}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            classes={classesFilter}
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
            teachers={teachersFilter}
            selectedTeacher={selectedTeacher}
            setSelectedTeacher={setSelectedTeacher}
            students={studentsFilter}
            selectedStudent={selectedStudent}
            setSelectedStudent={setSelectedStudent}
            selectedTrack={selectedTrack}
            setSelectedTrack={setSelectedTrack}
            fetchFilters={fetchFilters}
          />
        )}
      </Box>
    </>
  );
}
