import React, { useState, useContext, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  attendanceTableHead,
  attendanceTableHeadName,
  attendanceTableHeadActions,
  attendanceTableHeadDay,
  attendanceTableHeadRow,
  attendanceTableHeadWeekDay,
  attendanceTableHeadDayInner,
  attendanceTableHeadDayContainer,
  attendanceTableBottom,
  badgeCell,
  daySelectContainer,
  attendanceTableHeadComment,
  attendanceQuickfillIcon,
  attendanceTableHeadKlass,
} from "./AttendanceTable.styles";
import { font45, loader } from "../sharedStyles";
import AttendanceTableRow from "./AttendanceTableRow";
import dateTimeFormats from "../../utils/constants/dateTimeFormats";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";
import DaySelect from "./DaySelect";
import attendanceService from "../../service/attendanceService";

export default function AttendanceTable({
  weekDays,
  currentDay,
  isDayView,
  activeCode,
  availableCodes,
  students,
  schoolDays,
  view,
  setCurrentDay,
  selectedTeacher,
  loading,
  isClassView,
  selectedKlasses,
  showUnenrolled,
  manageAllStudentsPermission,
  shouldShowTableForSubstitute,
  setUpdateStudents,
  selectedSubstituteTeacher,
  homeroomTeacherIds,
}) {
  const { hasPermission } = useContext(PermissionsContext);
  const [quickfill, setQuickfill] = useState(null);
  const [updateStudent, setUpdateStudent] = useState(null);

  const managePermission = hasPermission(
    APP_PERMISSIONS.ATTENDANCE,
    PERMISSION_TYPES.MANAGE
  );

  useEffect(() => {
    if (updateStudent) {
      // eslint-disable-next-line consistent-return
      students.forEach((student) => {
        if (student.id === updateStudent.id) {
          // eslint-disable-next-line no-param-reassign
          student.periods = updateStudent.periods;
          return true;
        }
      });
    }
  }, [updateStudent]);

  function getStudentsPresentMinutes() {
    // calculate presentMinutes for each student
    const studentsPresentMinutes = [];
    students.forEach((student) => {
      if (student.period_attendance) {
        let presentMinutes =
          student.periods[0]?.klasses[0]?.attendance[0]?.present_minutes || 0;
        const period = student.periods.find((p) =>
          p.klasses.find((k) => k.id === selectedKlasses[0])
        );
        const periodKlass = period.klasses.find(
          (k) => k.id === selectedKlasses[0]
        );

        if (period && periodKlass) {
          const currentCode = periodKlass?.attendance[0]?.code;

          if (!currentCode || currentCode === "-") {
            if (currentCode !== activeCode.code) {
              const minutes =
                Math.abs(
                  new Date(period.end_time) - new Date(period.start_time)
                ) / 60000;

              if (
                ["P", "T", "Tx"].includes(activeCode.code) &&
                !["P", "T", "Tx"].includes(currentCode)
              ) {
                presentMinutes += minutes;
              }
              if (
                ["A", "Ax", "-"].includes(activeCode.code) &&
                !["A", "Ax", "-", undefined].includes(currentCode)
              ) {
                if (presentMinutes > 0) {
                  presentMinutes -= minutes;
                }
              }
            }
          }
        }
        studentsPresentMinutes.push([student.id, presentMinutes]);
      }
    });

    return studentsPresentMinutes;
  }

  const handleQuickFill = (day) => {
    setQuickfill({ activeCode, date: day.format("YYYY-MM-DD") });

    if (
      isClassView &&
      day.format("YYYY-MM-DD") === currentDay.format("YYYY-MM-DD")
    ) {
      attendanceService
        .quickfill({
          code: activeCode.code,
          date: currentDay.format("YYYY-MM-DD"),
          teacher_id: selectedTeacher,
          klass_ids: selectedKlasses,
          students_present_minutes: getStudentsPresentMinutes(),
        })
        .then(() => {
          if (students[0].period_attendance) {
            setUpdateStudents(true);
          }
        });
    } else {
      attendanceService.quickfill({
        code: activeCode.code,
        date: day.format("YYYY-MM-DD"),
        teacher_id: selectedTeacher,
        show_unenrolled: showUnenrolled ? true : null,
        homeroom_teacher_ids: homeroomTeacherIds,
      });
    }
  };

  const renderTableHead = (day) => (
    <TableCell
      align="center"
      sx={attendanceTableHeadActions}
      key={day}
      {...(managePermission && {
        onClick: () => handleQuickFill(day),
      })}
    >
      <Box sx={attendanceTableHeadDay}>
        <Stack direction="row" sx={attendanceTableHeadDayInner}>
          <Box sx={attendanceTableHeadDayContainer(isClassView)}>
            <Typography sx={font45}>{day.format("DD")}</Typography>
            <Typography sx={attendanceTableHeadWeekDay}>
              {day.format("dddd")}
            </Typography>
          </Box>
          <Box sx={attendanceQuickfillIcon}>
            <SystemUpdateAltIcon sx={{ width: "20px", height: "20px" }} />
          </Box>
        </Stack>
      </Box>
    </TableCell>
  );

  const currentDayIsSchoolDay = () =>
    schoolDays.some(
      (day) =>
        day.date === currentDay.format(dateTimeFormats.YYYYMMDD) &&
        (day.school_day_designation?.category === "school_day" ||
          day.day_template_id !== null)
    );

  if (
    isClassView &&
    (students?.length === 0 || selectedKlasses?.length === 0)
  ) {
    return (
      <TableContainer sx={{ mt: "75px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={attendanceTableHeadRow}>
              <TableCell align="left" sx={attendanceTableHeadName}>
                <Box sx={daySelectContainer}>
                  <DaySelect
                    view={view}
                    currentDay={currentDay}
                    weekDays={weekDays}
                    setCurrentDay={setCurrentDay}
                    schoolDays={schoolDays}
                  />
                </Box>
              </TableCell>
              <TableCell sx={{ ...attendanceTableHead, pb: "8px" }}>
                No classes available for this day
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    );
  }

  if (isClassView && manageAllStudentsPermission && selectedTeacher === null) {
    return (
      <TableContainer sx={{ mt: "75px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={attendanceTableHeadRow}>
              <TableCell align="left" sx={attendanceTableHeadName}>
                <Box sx={daySelectContainer}>
                  <DaySelect
                    view={view}
                    currentDay={currentDay}
                    weekDays={weekDays}
                    setCurrentDay={setCurrentDay}
                    schoolDays={schoolDays}
                  />
                </Box>
              </TableCell>
              <TableCell sx={{ ...attendanceTableHead, pb: "8px" }}>
                Please select a teacher
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={attendanceTableHeadRow}>
            <TableCell align="left" sx={attendanceTableHeadName}>
              <Box sx={daySelectContainer}>
                <DaySelect
                  view={view}
                  currentDay={currentDay}
                  weekDays={weekDays}
                  setCurrentDay={setCurrentDay}
                  schoolDays={schoolDays}
                />
              </Box>
            </TableCell>
            <TableCell sx={{ ...attendanceTableHead, ...badgeCell }} />
            {isClassView && (
              <TableCell sx={attendanceTableHeadKlass}>Class</TableCell>
            )}
            {isDayView
              ? renderTableHead(currentDay)
              : weekDays.map((d) => renderTableHead(d))}
            <TableCell sx={attendanceTableHead}>
              {isDayView ? (
                <Typography sx={attendanceTableHeadComment}>
                  Comments
                </Typography>
              ) : (
                ""
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={10}>
                <CircularProgress sx={loader} size={100} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          currentDayIsSchoolDay &&
          (!isDayView ||
            (isDayView && shouldShowTableForSubstitute(currentDay))) && (
            <TableBody>
              {students.map((student) => (
                <AttendanceTableRow
                  key={`${student?.id}-${student?.klass_id}`}
                  student={student}
                  isDayView={isDayView}
                  weekDays={weekDays}
                  activeCode={activeCode}
                  availableCodes={availableCodes}
                  currentDay={currentDay}
                  quickfill={quickfill}
                  setQuickfill={setQuickfill}
                  schoolDays={schoolDays}
                  selectedTeacher={selectedTeacher}
                  isClassView={isClassView}
                  selectedKlasses={selectedKlasses}
                  showUnenrolled={showUnenrolled}
                  shouldShowTableForSubstitute={shouldShowTableForSubstitute}
                  setUpdateStudent={setUpdateStudent}
                  selectedSubstituteTeacher={selectedSubstituteTeacher}
                />
              ))}
              <TableRow sx={attendanceTableBottom}>
                <TableCell sx={attendanceTableHeadName} />
                <TableCell sx={attendanceTableHead} />
                <TableCell sx={attendanceTableHead} />
                <TableCell sx={attendanceTableHeadActions} />
                {!isDayView && (
                  <>
                    <TableCell sx={attendanceTableHeadActions} />
                    <TableCell sx={attendanceTableHeadActions} />
                    <TableCell sx={attendanceTableHeadActions} />
                    <TableCell sx={attendanceTableHeadActions} />
                  </>
                )}
              </TableRow>
            </TableBody>
          )
        )}
      </Table>
    </TableContainer>
  );
}
