export const orderedGradeLevels = [
  "null",
  "EC",
  "PS",
  "PK",
  "TK",
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const sortedGradeLevels = (grades = [], isObject = false) => {
  if (!Array.isArray(grades) || grades.length === 0) {
    return [];
  }

  return grades
    .filter((item) => {
      const value = isObject ? item?.name : item;
      return value && orderedGradeLevels.includes(value);
    })
    .sort((a, b) => {
      const aValue = isObject ? a.name : a;
      const bValue = isObject ? b.name : b;
      return (
        orderedGradeLevels.indexOf(aValue) - orderedGradeLevels.indexOf(bValue)
      );
    });
};
