import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Edit, SyncAlt } from "@mui/icons-material";
import {
  bgWhite,
  font20,
  mb20,
  mr15,
  mt15,
  mt35,
  my15,
} from "../../../sharedStyles";
import ErasableTextField from "../../../ErasableTextField";
import CanvasCoursesKlassRow from "./CanvasCoursesKlassRow";
import klassesService from "../../../../service/klassesService";
import {
  canvasCourseFormSubmitBtn,
  canvasCoursesFormContainer,
  canvasCoursesFormSyncIcon,
} from "./CanvasCoursesForm.styles";
import generateSisId from "../../../../utils/generateCanvasSisId";

export default function CanvasCoursesForm({
  onSubmit,
  activeCourse,
  setDeleteDialogOpen,
  courses,
  selectedKlasses,
  setSelectedKlasses,
}) {
  const { setValue, handleSubmit, register } = useFormContext();

  const [sisIdEdit, setSisIdEdit] = useState(false);
  const [klasses, setKlasses] = useState([]);
  const [selectedAeCourse, setSelectedAeCourse] = useState(
    activeCourse?.ae_connect_course_id || null
  );

  const allKlassesChecked = selectedKlasses.length === klasses.length;
  const [teachedByOneTeacher, setTeachedByOneTeacher] = useState(null);

  const getKlasses = async () => {
    const response = await klassesService.fetchYearKlasses({
      params: {
        course_id: selectedAeCourse,
      },
    });
    if (response.klasses) {
      setKlasses(response.klasses);
      setTeachedByOneTeacher(response.teached_by_one_teacher);

      if (response.teached_by_one_teacher && !activeCourse) {
        setSelectedKlasses(response.klasses);
      } else {
        setSelectedKlasses(
          activeCourse
            ? response.klasses?.filter((k) =>
                activeCourse?.canvas_course_klasses?.find(
                  (ck) => ck.klass_id === k.id
                )
              )
            : []
        );
      }
    }
  };

  useEffect(() => {
    setValue("canvas_term", "year");
  }, [activeCourse]);

  useEffect(() => {
    if (selectedAeCourse) {
      getKlasses();
    }
  }, [selectedAeCourse]);

  const generatedSisId = generateSisId(5);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box align="center" sx={mb20}>
        <SyncAlt sx={canvasCoursesFormSyncIcon} />
        <Typography>
          Manage the syncronization of AE-CONNECT <br /> classes to your Canvas
          course.
        </Typography>
      </Box>
      <Box sx={canvasCoursesFormContainer}>
        <ErasableTextField
          id="name"
          label="Name*"
          fullWidth
          isRequired
          type="text"
          defaultValue={activeCourse?.name || null}
        />

        <Stack
          sx={mt15}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography sx={mr15}>Canvas SIS ID</Typography>
          <FormControl variant="outlined">
            <Input
              label="SIS ID*"
              type="text"
              name="sis_id"
              sx={{ width: "190px" }}
              {...register("sis_id", { required: true })}
              defaultValue={activeCourse?.canvas_sis_id || generatedSisId}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setSisIdEdit(!sisIdEdit)}
                    edge="end"
                  >
                    {!sisIdEdit && <Edit />}
                  </IconButton>
                </InputAdornment>
              }
              disabled={!sisIdEdit}
            />
          </FormControl>
          {!sisIdEdit && (
            <input
              type="hidden"
              name="sis_id"
              value={activeCourse?.canvas_sis_id || generatedSisId}
              {...register("sis_id")}
            />
          )}
        </Stack>

        <Box sx={mt35}>
          <Typography sx={font20}>Choose an AE-Connect course</Typography>
          <Box sx={{ borderBottom: "1px solid #BFC7CA", py: "15px" }}>
            <FormControl sx={my15} fullWidth>
              <InputLabel sx={bgWhite} id="ae_connect_course">
                AE-Connect Course
              </InputLabel>
              <Select
                labelId="ae_connect_course"
                id="ae_connect_course"
                value={selectedAeCourse || null}
                onChange={(e) => {
                  setSelectedAeCourse(e.target.value);
                }}
              >
                {courses.map((course) => (
                  <MenuItem value={course.id}>{course.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {klasses.length > 0 && (
            <Box sx={mt15}>
              <Grid
                spacing={2}
                container
                sx={{
                  borderBottom: "1px solid #BFC7CA",
                  mb: "15px",
                  pb: "15px",
                  mx: 0,
                }}
              >
                <Grid item>
                  <RadioGroup
                    row
                    aria-labelledby="canvas_term"
                    name="canvas_term"
                  >
                    <FormControlLabel
                      value="include_all_klasses"
                      control={<Radio />}
                      label="Automatically select"
                      checked={
                        activeCourse ? allKlassesChecked : teachedByOneTeacher
                      }
                      onChange={() => {
                        setTeachedByOneTeacher(true);
                        setSelectedKlasses(klasses);
                      }}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item>
                  <RadioGroup
                    row
                    aria-labelledby="canvas_term"
                    name="canvas_term"
                  >
                    <FormControlLabel
                      value="select_specific_klasses"
                      control={<Radio />}
                      label="Custom selection"
                      checked={
                        activeCourse ? !allKlassesChecked : !teachedByOneTeacher
                      }
                      onChange={() => {
                        setTeachedByOneTeacher(false);
                        setSelectedKlasses(
                          activeCourse
                            ? klasses.filter((k) =>
                                activeCourse?.canvas_course_klasses?.find(
                                  (ck) => ck.klass_id === k.id
                                )
                              )
                            : []
                        );
                      }}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Typography sx={font20}>Choose one or more classes</Typography>
              <TableContainer>
                <Table size="medium">
                  <TableBody>
                    {klasses.map((klass) => (
                      <CanvasCoursesKlassRow
                        key={klass.id}
                        klass={klass}
                        setSelectedKlasses={setSelectedKlasses}
                        selectedKlasses={selectedKlasses}
                        activeCourse={activeCourse}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>

        <Stack direction="row" justifyContent="center" sx={mt15}>
          {activeCourse ? (
            <Box sx={{ width: "100%" }}>
              <Button
                type="submit"
                variant="contained"
                sx={canvasCourseFormSubmitBtn}
              >
                Update
              </Button>
              <Button
                onClick={() => setDeleteDialogOpen(true)}
                variant="outlined"
                color="error"
                sx={canvasCourseFormSubmitBtn}
              >
                Delete
              </Button>
            </Box>
          ) : (
            <Button
              type="submit"
              sx={canvasCourseFormSubmitBtn}
              variant="contained"
            >
              Create
            </Button>
          )}
        </Stack>
      </Box>
    </form>
  );
}
