const draftsDescription = {
  mx: "25px",
  fontSize: "14px",
  textOverflow: "ellipsis",
  maxWidth: "850px",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const draftsAttachmentContainer = {
  ml: "20px",
  width: "30px",
};

export { draftsDescription, draftsAttachmentContainer };
