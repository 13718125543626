import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Search } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { font28, mb10 } from "../../../sharedStyles";

export default function CanvasSearch({
  query,
  setQuery,
  searchParams,
  setSearchParams,
}) {
  const { register, watch, handleSubmit } = useForm();
  const handleSearch = (data) => {
    searchParams.set("query", data.search);
    setQuery(data.search);
    setSearchParams(searchParams);
  };

  const handleInputChange = () => {
    const currentSearch = watch("search");
    handleSubmit(() => handleSearch({ search: currentSearch }))();
  };

  useEffect(() => {
    if (watch("search").length > 2) {
      handleInputChange();
    }

    if (watch("search").length === 0) {
      handleInputChange();
    }
  }, [watch("search")]);

  return (
    <FormProvider>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Typography sx={[font28, mb10]}>Canvas Courses</Typography>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Search</InputLabel>
          <OutlinedInput
            label="Search"
            type="text"
            name="search"
            defaultValue={query}
            {...register("search")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton type="submit" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
    </FormProvider>
  );
}
