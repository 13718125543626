import React, { useContext, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
// eslint-disable-next-line import/no-unresolved
import { stripHtml } from "string-strip-html";
import GenericSubmitButton from "../../../GenericSubmitButton";
import TextEditor from "../../../TextEditor/TextEditor";
import { danger, formButtons, formHeader } from "../../../sharedStyles";
import admissionProcessService from "../../../../service/admissionProcessService";
import { SnackbarContext } from "../../../../context/SnackbarContext";

export default function AddNote({
  setAddNoteDrawerOpen,
  processInstanceId,
  noteItem,
  setNoteItem,
}) {
  const [descriptionError, setDescriptionError] = useState(false);
  const snackbarContext = useContext(SnackbarContext);
  const editorRef = useRef(null);
  const methods = useForm();

  const handleClose = () => {
    setAddNoteDrawerOpen(false);
    setNoteItem(null);
  };
  const onSubmit = (d) => {
    const data = d;
    if (!descriptionError) {
      if (noteItem) {
        data.id = noteItem.id;

        admissionProcessService
          .updateNote(data)
          .then(() => {
            snackbarContext.setSnackbar({
              message: "Note updated successfully.",
              severity: "success",
              open: true,
            });
            handleClose();
          })
          .catch(() => {
            snackbarContext.setSnackbar({
              message: "Failed to update a note",
              severity: "error",
              open: true,
            });
          });
      } else {
        data.process_instance_id = processInstanceId;
        admissionProcessService
          .postNotes(data)
          .then(() => {
            snackbarContext.setSnackbar({
              message: "Note added successfully.",
              severity: "success",
              open: true,
            });
            handleClose();
          })
          .catch(() => {
            snackbarContext.setSnackbar({
              message: "Failed to add a note",
              severity: "error",
              open: true,
            });
          });
      }
    } else {
      editorRef?.current?.focus();
    }
  };

  const handleBodyValidation = (hasText) =>
    hasText ? setDescriptionError(false) : setDescriptionError(true);

  const handleDeleteNote = (noteId) => {
    admissionProcessService
      .deleteNote(noteId)
      .then(() => {
        snackbarContext.setSnackbar({
          message: "Note deleted successfully.",
          severity: "success",
          open: true,
        });
        handleClose();
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to delete a note",
          severity: "error",
          open: true,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Grid
          container
          item
          sx={formHeader}
          direction="row"
          columnSpacing={4}
          rowSpacing={0}
        >
          <Grid item sm={7}>
            <Grid item>
              <Typography variant="h2">Add Note</Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="end" sm={5}>
            <Button variant="outlined" sx={formButtons} onClick={handleClose}>
              Cancel
            </Button>
            {noteItem && (
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => handleDeleteNote(noteItem.id)}
              />
            )}

            <GenericSubmitButton text="Save" submittingText="Saving..." />
          </Grid>
        </Grid>
        <Box p={3}>
          <Controller
            name="description"
            control={methods.control}
            render={({ field }) => (
              <TextEditor
                {...field}
                isSimplified
                hasError={methods.formState.errors?.description}
                handleBodyValidation={handleBodyValidation}
                descriptionError={descriptionError}
                editorRef={editorRef}
              />
            )}
            rules={{
              validate: {
                required: (v) =>
                  (v && stripHtml(v).result.length > 0) ||
                  "Please write some notes.",
              },
            }}
            defaultValue={noteItem?.description || ""}
          />
          {methods.formState.errors?.description && (
            <Typography sx={danger}>This field is required.</Typography>
          )}
        </Box>
      </form>
    </FormProvider>
  );
}
