// ALIGNMENT
const alignCenter = {
  alignItems: "center",
};
const justifySpaceBetween = { justifyContent: "space-between" };
const textCenter = { textAlign: "center" };
const textLeft = { textAlign: "left" };
const textRight = { textAlign: "right" };

// DISPLAY
const flex = { display: "flex" };

// FONT SIZE
const font11 = { fontSize: 11 };
const font12 = { fontSize: 12 };
const font14 = { fontSize: 14 };
const font15 = { fontSize: 15 };
const font16 = { fontSize: 16 };
const font17 = { fontSize: 17 };
const font18 = { fontSize: 18 };
const font20 = { fontSize: 20 };
const font22 = { fontSize: 22 };
const font25 = { fontSize: 25 };
const font24 = { fontSize: 24 };
const font26 = { fontSize: 26 };
const font28 = { fontSize: 28 };
const font32 = { fontSize: 32 };
const font36 = { fontSize: 36 };
const font40 = { fontSize: 40 };
const font45 = { fontSize: 45 };

// FONT WEIGHT
const fbold = { fontWeight: "bold" };
const fw600 = { fontWeight: 600 };

// COLORS
const danger = { color: "red" };
const black = { color: "black" };
const link = { color: "#5077A4" };
const white = { color: "white" };

// BG COLORS
const bgWhite = { backgroundColor: "white" };

// MARGINS
const m0 = { m: 0 };
const mt70 = { mt: "70px" };
const mt35 = { mt: "35px" };
const mt25 = { mt: "25px" };
const mt15 = { mt: "15px" };
const mt5 = { mt: "5px" };
const mb3 = { mb: "3px" };
const mb6 = { mb: "6px" };
const mb10 = { mb: "10px" };
const mb15 = { mb: "15px" };
const mb20 = { mb: "20px" };
const mb25 = { mb: "25px" };
const mb30 = { mb: "30px" };
const mb45 = { mb: "45px" };
const mb55 = { mb: "55px" };
const ml5 = { ml: "5px" };
const mr5 = { mr: "5px" };
const ml15 = { ml: "15px" };
const ml20 = { ml: "20px" };
const ml30 = { ml: "30px" };
const mr2 = { mr: 2 };
const mr10 = { mr: "10px" };
const mr15 = { mr: "15px" };
const mr30 = { mr: "30px" };
const mr55 = { mr: "55px" };
const my30 = { my: "30px" };
const my15 = { my: "15px" };
const my5 = { my: "5px" };
const my60 = { my: "60px" };
const mx10 = { mx: "10px" };
const mx25 = { mx: "25px" };
const mx5 = { mx: "5px" };

// PADDING
const pb0 = { pb: "0 !important" };
const p10 = { p: "10px" };
const px0 = { px: "0px !important" };
const px10 = { px: "10px" };
const px20 = { px: "20px" };
const pr25 = { pr: "25px" };
const pr10 = { pr: "10px" };
const pt0 = { pt: "0px !important" };
const pt30 = { pt: "30px" };
const pt20 = { pt: "20px" };
const pt50 = { pt: "50px" };
const py10 = { py: "10px" };
const py30 = { py: "30px" };
const py45 = { py: "45px" };
const pb15 = { pb: "15px" };
const pb20 = { pb: "20px" };
const pl20 = { pl: "20px" };
const pl10 = { pl: "10px" };
const py5 = { py: "5px" };

// MISC
const cursorPointer = {
  cursor: "pointer",
};

const gcLogoContainer = {
  position: "relative",
  width: "30px",
  height: "30px",
  cursor: "pointer",
  "& img": {
    position: "absolute",
    width: "100%",
  },
};

const dBlock = {
  display: "block",
};

const borderless = {
  border: 0,
};

// COMPONENTS
const rightDrawerContainer = {
  width: "800px",
};

const rightDrawerContainerNoScroll = {
  width: "800px",
  overflow: "hidden",
};

const loader = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "transition(-50%, -50%)",
};

const spin = {
  animation: "spin 1.5s linear infinite",
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
};

const pageHeaderTitle = {
  fontSize: "52px",
  color: "black",
  mr: "20px",
};

const pageTabsContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const pageTabs = {
  mt: "10px",
  width: "100%",
  "& .MuiTab-root": {
    padding: 0,
    textTransform: "none",
    minWidth: "65px",
    mr: "20px",
    fontSize: "22px",
    fontWeight: "normal",
    color: "black",
  },
  "& .MuiTabs-scroller": {
    width: "100%",
    "& .MuiTabs-flexContainer": {
      width: "100%",
    },
  },
};

const daySelectButton = {
  padding: 0,
  fontSize: "30px",
};

const daySelectInner = {
  alignItems: "center",
  color: "black",
};

const formHeader = {
  position: "sticky",
  top: 0,
  zIndex: 999,
  backgroundColor: "#F2F1ECE5",
  justifyContent: "space-between",
  alignItems: "center",
  pt: "50px",
  pb: "15px",
  px: "55px",
};
const formButtonsContainer = {
  flexGrow: 1,
  justifyContent: "flex-end",
};
const formButtons = {
  backgroundColor: "transparent",
  border: "1px solid #79747E",
  color: "#5077A4",
  fontSize: "14px",
  textTransform: "none",
  "&:not(:last-of-type)": {
    mr: "10px",
  },
  whiteSpace: "nowrap",
};

const formTitle = {
  fontSize: "24px",
  color: "black",
};

const formContainer = {
  padding: "55px",
  color: "black",
};

const formCheckBox = {
  mb: "30px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};

const tableAvatar = {
  backgroundColor: "#5077A4",
  fontSize: "16px",
  cursor: "pointer",
};

const minW200 = {
  minWidth: "200px",
};

const paginationContent = {
  display: "flex",
  justifyContent: "center",
};

const chipStyles = {
  "& .MuiChip-label": {
    fontSize: "14px",
    color: "black",
  },
  "& .MuiChip-deleteIcon": {
    color: "black",
  },
};

const switchBtn = {
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#1976d2",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#1976d2",
  },
};

const blueOutlinedButton = {
  color: "#2196F3",
  textTransform: "none",
  borderColor: "#2196F3",
  fontWeight: 400,
};

const submitButton = {
  backgroundColor: "#2196F3",
  color: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#2196F3",
  },
};

const buttonWithStartIcon = {
  textTransform: "none",
  color: "#2196F3",
  borderColor: "#2196F3",
};

const textUpper = {
  textTransform: "uppercase",
};

export {
  textUpper,
  tableAvatar,
  formContainer,
  formHeader,
  formButtons,
  formTitle,
  formCheckBox,
  borderless,
  justifySpaceBetween,
  daySelectButton,
  daySelectInner,
  pb0,
  pb15,
  pb20,
  pageHeaderTitle,
  pageTabs,
  pageTabsContainer,
  formButtonsContainer,
  alignCenter,
  flex,
  textCenter,
  textLeft,
  textRight,
  font11,
  font12,
  font14,
  font15,
  font16,
  font17,
  font18,
  font20,
  font22,
  font25,
  font24,
  font26,
  font28,
  font32,
  font36,
  font45,
  font40,
  fbold,
  fw600,
  danger,
  mt70,
  mt35,
  mt25,
  mt15,
  mt5,
  mb3,
  mb6,
  mb10,
  mb20,
  mb25,
  mb30,
  mb15,
  ml5,
  ml15,
  ml20,
  ml30,
  mr2,
  mr10,
  mr15,
  mr30,
  mb45,
  mr55,
  my30,
  my15,
  my60,
  my5,
  mx10,
  mx25,
  mr5,
  mx5,
  m0,
  p10,
  pt0,
  pt20,
  pt30,
  pt50,
  px0,
  px10,
  px20,
  pr25,
  pr10,
  py10,
  py30,
  py45,
  pl20,
  pl10,
  py5,
  cursorPointer,
  rightDrawerContainer,
  rightDrawerContainerNoScroll,
  loader,
  dBlock,
  bgWhite,
  black,
  mb55,
  minW200,
  link,
  paginationContent,
  gcLogoContainer,
  spin,
  white,
  chipStyles,
  switchBtn,
  blueOutlinedButton,
  submitButton,
  buttonWithStartIcon,
};
