import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import { pageTabs } from "../../sharedStyles";
import { parameterizeString } from "../../../utils/constants/utils";

export default function CanvasTabs() {
  const [, currentSchool] = useOutletContext();
  const navigate = useNavigate();

  const tabs = [
    {
      index: 0,
      label: "Settings",
      url: `/school/${currentSchool.slug}/school/canvas-integration/setup`,
    },
    {
      index: 1,
      label: "Courses",
      url: `/school/${currentSchool.slug}/school/canvas-integration/courses`,
    },
  ];

  const location = useLocation();
  const [tabValue, setTabValue] = useState(tabs[0]);

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);
    navigate(path);
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      parameterizeString(location.pathname).includes(
        parameterizeString(tab.label)
      )
    );

    setTabValue(getTabFromUrl || tabs[0]);
  }, [location]);

  return (
    <Box display="flex" justifyContent="space-between">
      <Tabs value={tabValue.index} sx={pageTabs}>
        {tabs.map((tab) => (
          <Tab
            key={tab.index}
            label={tab.label}
            onClick={() => handleTabChange(tab.index, tab.url)}
          />
        ))}
      </Tabs>
    </Box>
  );
}
