import axios from "../utils/axios-util";

const endpoint = "/api/canvas_courses";

const fetchCanvasCourses = async (params) => {
  const { data } = await axios.get(`${endpoint}`, params);

  return data;
};

const fetchCanvasCourse = async (courseId) => {
  const { data } = await axios.get(`${endpoint}/${courseId}`);

  return data;
};

const createCanvasCourse = async (params) => {
  const { data } = await axios.post(`${endpoint}`, params);

  if (!data.data) {
    console.error("Error creating course");
    return [];
  }
  return data;
};

const updateCanvasCourse = async (params) => {
  const { data } = await axios.patch(`${endpoint}/${params.id}`, params);

  if (!data.data) {
    console.error("Error updating course");
    return [];
  }
  return data;
};

const deleteCanvasCourse = async (courseId) => {
  const { data } = await axios.delete(`${endpoint}/${courseId}`);

  if (!data.data) {
    console.error("Error deleting course");
    return [];
  }
  return data;
};

const cloneCanvasCourses = async (params) => {
  const { data } = await axios.post(`${endpoint}/clone_canvas_courses`, params);

  return data;
};

export default {
  createCanvasCourse,
  fetchCanvasCourses,
  updateCanvasCourse,
  deleteCanvasCourse,
  cloneCanvasCourses,
  fetchCanvasCourse,
};
