import React, { useRef, useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Input,
  Button,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useParams } from "react-router-dom";
import {
  accountVerticalContainer,
  academicsImg,
  replaceButton,
  profilePicContainer,
  fieldsContainer,
} from "./StaffInfoAccount.style";
import ErasableTextField from "../../../ErasableTextField";
import { danger } from "../../../sharedStyles";
import lookupService from "../../../../service/lookupService";
import { convertBase64 } from "../../../../utils/constants/utils";
import IOSSwitch from "../../../IOSSwitch";
import ContactInformation from "./ContactInformation";
import Demographics from "./Demographics";
import moment from "../../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../../utils/constants/dateTimeFormats";
import staffsService from "../../../../service/staffsService";
import StaffType from "../../../../utils/constants/staffTypeEnum";

function GeneralInfoAccount({ teacher, managePermission, schoolId }) {
  const [nameTitles, setNameTitles] = useState([]);
  const [hireDate, setHireDate] = useState(teacher.hire_date);
  const [beginDate, setBeginDate] = useState(teacher.begin_date);
  const [endDate, setEndDate] = useState(teacher.end_date);
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext();
  const [profilePictureSrc, setProfilePictureSrc] = useState(
    "/assets/default_avatar.jpg"
  );
  const fileInputRef = useRef(null);
  const [staffs, setStaffs] = useState([]);
  const params = useParams();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePictureSrc(URL.createObjectURL(file));
      const imageData = await convertBase64(e.target.files[0]);
      setValue("profile_picture", imageData, { shouldDirty: true });
    }
  };

  useEffect(() => {
    const loadStaffs = async () => {
      const response = await staffsService.fetchAllStaffs({
        params: { school_id: params.school_id, staff_type: StaffType.TEACHER },
      });
      setStaffs(response);
    };

    loadStaffs();
  }, [params.school_id]);

  useEffect(() => {
    if (teacher?.image_url) {
      setProfilePictureSrc(teacher?.image_url.url);
    }
    lookupService.fetchNameTitles().then((response) => {
      setNameTitles(response.data.data);
    });
    register("profile_picture");

    setValue("first_name", teacher.first_name, { shouldDirty: true });
    setValue("last_name", teacher.last_name, { shouldDirty: true });
  }, [teacher]);

  const onDateChanged = (control, date) => {
    setValue(control, date.format("MM/DD/YYYY"), {
      shouldDirty: true,
    });
    trigger(control);
  };

  return (
    <Grid container item marginY={5} xs={12} sm={12} md={12}>
      <Grid item xs={12} sm={12} md={9} sx={accountVerticalContainer}>
        <Grid
          container
          item
          direction="column"
          rowSpacing={3}
          pl={3}
          sx={fieldsContainer}
        >
          <Grid
            container
            item
            direction="column"
            rowSpacing={8}
            columnSpacing={8}
          >
            <Grid container item columnSpacing={2} rowSpacing={2}>
              <Grid
                container
                item
                sm={12}
                xs={12}
                md={12}
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                paddingRight={3}
              >
                <Grid container item sm={3}>
                  <TextField
                    id="educator_id"
                    label="Educator ID"
                    defaultValue={teacher.educator_id}
                    disabled
                    {...register("educator_id")}
                  />
                </Grid>
                <Grid
                  container
                  item
                  sm={4}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <IOSSwitch
                    defaultChecked={
                      teacher.employments.find(
                        (employment) => employment.school_slug === schoolId
                      )?.active
                    }
                    onChange={(event) => {
                      setValue("employment_active", event.target.checked, {
                        shouldDirty: true,
                      });
                    }}
                  />
                  <Typography
                    style={{ color: "#BFC7CA", marginLeft: "1rem" }}
                    variant="subtitle1"
                  >
                    Account Active
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                sm={12}
                xs={12}
                md={12}
                spacing={2}
                paddingRight={3}
              >
                <Grid container item xs={12}>
                  <TextField
                    id="fullLegalName"
                    label="Full Legal Name"
                    fullWidth
                    defaultValue={`${teacher.first_name} ${
                      teacher.middle_name || ""
                    } ${teacher.last_name}`}
                    inputProps={{
                      readOnly: true,
                    }}
                    disabled={!managePermission}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                sm={12}
                xs={12}
                md={12}
                spacing={2}
                paddingRight={3}
              >
                <Grid container item sm={1.5}>
                  <FormControl fullWidth>
                    <InputLabel id="nameTitleLabel">Title</InputLabel>
                    <Select
                      labelId="nameTitleLabel"
                      id="name_title"
                      label="Title"
                      defaultValue={teacher.name_title}
                      {...register("name_title")}
                    >
                      {nameTitles.map((nameTitle) => (
                        <MenuItem key={nameTitle.id} value={nameTitle.name}>
                          {nameTitle.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3.5}>
                  <TextField
                    id="first_name"
                    label="First Name"
                    fullWidth
                    defaultValue={teacher.first_name}
                    error={errors.first_name}
                    disabled
                    required
                    {...register("first_name", {
                      required: "This field is required.",
                    })}
                  />
                  {errors.first_name && (
                    <Typography sx={danger}>This field is required.</Typography>
                  )}
                </Grid>
                <Grid item sm={3.5}>
                  <TextField
                    id="middle_name"
                    label="Middle Name"
                    fullWidth
                    defaultValue={teacher.middle_name}
                    disabled
                  />
                </Grid>
                <Grid item sm={3.5}>
                  <TextField
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    defaultValue={teacher.last_name}
                    error={errors.last_name}
                    disabled
                    required
                    {...register("last_name", {
                      required: "This field is required.",
                    })}
                  />
                  {errors.last_name && (
                    <Typography sx={danger}>This field is required.</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                sm={12}
                xs={12}
                md={12}
                spacing={2}
                justifyContent="space-between"
                paddingRight={3}
              >
                <Grid item sm={11} xs={11} md={5}>
                  <TextField
                    id="preferred_name"
                    label="Preferred Name"
                    fullWidth
                    defaultValue={teacher.preferred_name}
                    {...register("preferred_name")}
                  />
                </Grid>
                <Grid item sm={3.5}>
                  <DesktopDatePicker
                    id="date_of_birth"
                    label="Date of Birth"
                    inputFormat="MM/DD/YYYY"
                    disabled
                    onChange={() => {}}
                    value={moment
                      .utc(teacher.date_of_birth)
                      .format(dateTimeFormats.MMDDYYYY)
                      .toString()}
                    disableOpenPicker
                    /* eslint-disable-next-line no-shadow */
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        required
                        error={Boolean(errors.date_of_birth)}
                      />
                    )}
                  />
                  {errors.date_of_birth && (
                    <Typography sx={danger}>
                      {errors.date_of_birth.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                sm={12}
                xs={12}
                md={12}
                spacing={2}
                paddingRight={3}
              >
                <Grid item sm={4}>
                  <ErasableTextField
                    id="position"
                    label="Position*"
                    defaultValue={teacher.position}
                    fullWidth
                    isRequired
                    hasError={errors.position}
                    disabled={!managePermission}
                    required
                  />
                  {errors.position && (
                    <Typography sx={danger}>
                      {errors.position.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={2.66}>
                  <DesktopDatePicker
                    id="hire_date"
                    label="Hire Date"
                    inputFormat="MM/DD/YYYY"
                    disabled={!managePermission}
                    onChange={(date) => {
                      if (date) {
                        setHireDate(date);
                        onDateChanged("hire_date", date);
                      }
                    }}
                    value={moment
                      .utc(hireDate)
                      .format(dateTimeFormats.MMDDYYYY)
                      .toString()}
                    /* eslint-disable-next-line no-shadow */
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.hire_date)}
                        {...register("hire_date", {
                          pattern: {
                            value:
                              /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                            message: "Invalid date format.",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.hire_date && (
                    <Typography sx={danger}>
                      {errors.hire_date.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={2.66}>
                  <DesktopDatePicker
                    id="begin_date"
                    label="Begin Date"
                    inputFormat="MM/DD/YYYY"
                    disabled={!managePermission}
                    onChange={(date) => {
                      if (date) {
                        setBeginDate(date);
                        onDateChanged("begin_date", date);
                      }
                    }}
                    value={moment
                      .utc(beginDate)
                      .format(dateTimeFormats.MMDDYYYY)
                      .toString()}
                    /* eslint-disable-next-line no-shadow */
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        error={Boolean(errors.begin_date)}
                        {...register("begin_date", {
                          required: "This field is required.",
                          pattern: {
                            value:
                              /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                            message: "Invalid date format.",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.begin_date && (
                    <Typography sx={danger}>
                      {errors.begin_date.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={2.66}>
                  <DesktopDatePicker
                    id="end_date"
                    label="End Date"
                    inputFormat="MM/DD/YYYY"
                    disabled={!managePermission}
                    onChange={(date) => {
                      if (date) {
                        setEndDate(date);
                        onDateChanged("end_date", date);
                      }
                    }}
                    value={moment
                      .utc(endDate)
                      .format(dateTimeFormats.MMDDYYYY)
                      .toString()}
                    /* eslint-disable-next-line no-shadow */
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.end_date)}
                        {...register("end_date", {
                          pattern: {
                            value:
                              /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                            message: "Invalid date format.",
                          },
                        })}
                      />
                    )}
                  />
                  {errors.end_date && (
                    <Typography sx={danger}>
                      {errors.end_date.message}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  spacing={2}
                  paddingRight={3}
                >
                  <Grid container item sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="backupStaffMember">
                        Backup Staff Member
                      </InputLabel>
                      <Select
                        labelId="backupStaffMember"
                        id="backup_staff"
                        label="Backup Staff Member"
                        defaultValue={teacher.backup_staff}
                        {...register("backup_staff")}
                      >
                        <MenuItem key="" value="">
                          None
                        </MenuItem>
                        {staffs
                          .filter((s) => Number(s.id) !== Number(params.id))
                          .map((staff) => (
                            <MenuItem key={staff.id} value={staff.id}>
                              {staff.first_name} {staff.last_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ContactInformation
          teacher={teacher}
          managePermission={managePermission}
        />
      </Grid>
      <Grid item xs={12} sm={11} md={3}>
        <Grid
          item
          xs={12}
          ml={{ sx: "10%", sm: "5%" }}
          sx={profilePicContainer}
        >
          <img alt="profile" src={profilePictureSrc} style={academicsImg} />
          <Button
            variant="outlined"
            sx={replaceButton}
            onClick={handleButtonClick}
          >
            Replace
          </Button>
          <Input
            type="file"
            inputRef={fileInputRef}
            style={{ display: "none" }}
            accept=".jpeg, .jpg, .png"
            onChange={handleProfilePictureChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Demographics teacher={teacher} managePermission={managePermission} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GeneralInfoAccount;
