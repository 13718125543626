import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function ProgressStudentSelect({
  teacherStudents,
  selectedTeacherStudent,
  setSelectedTeacherStudent,
}) {
  return (
    <Grid item md={8} pt={6} pl={4}>
      {teacherStudents?.length > 0 && (
        <FormControl fullWidth>
          <InputLabel id="student_label">Student</InputLabel>
          <Select
            labelId="student_label"
            id="student"
            label="Student"
            value={selectedTeacherStudent}
            onChange={(e) => setSelectedTeacherStudent(e.target.value)}
          >
            <MenuItem value="all">Select</MenuItem>
            {teacherStudents?.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {`${option.last_name}, ${option.first_name}`}{" "}
                {option.middle_name || ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}
