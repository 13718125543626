import React, { useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Box, Grid, Typography, Badge, Tooltip, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { longShortDateTime } from "../../utils/dateTimePresets";
import { notificationsRow } from "../../views/Notifications.styles";
import { submitButton } from "../sharedStyles";
import notificationsService from "../../service/notificationsService";

export default function Row({ notification }) {
  const { t, i18n } = useTranslation("notifications");
  const location = useLocation();
  const params = useParams();
  const [readAt, setReadAt] = useState(notification.read_at);

  const portalLink = () => {
    if (location.pathname.includes("/school/"))
      return `/school/${params.school_id}/notifications/${notification.id}/details`;

    if (params.school_id)
      return `/student/${params.id}/schools/${params.school_id}/notifications/${notification.id}/details`;

    return `/guardian/${params.id}/notifications/${notification.id}/details`;
  };

  const getNotification = async () => {
    await notificationsService.fetchNotification(notification.id);
  };

  return (
    <Link to={portalLink()}>
      <Box sx={notificationsRow} className={readAt ? undefined : "unread"}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={9}>
            <Typography className="category">
              {t(notification.event_category, { keyPrefix: "categories" })}
            </Typography>

            <Typography className="title">
              {notification.event_name === "report_card_download"
                ? notification.data?.title
                : t("name", { keyPrefix: `events.${notification.event_name}` })}
            </Typography>

            <Box display="flex" alignItems="center" gap={2}>
              <Typography>
                {notification.event_name === "report_card_download" &&
                notification.data?.error
                  ? notification.data?.error
                  : t("description", {
                      keyPrefix: `events.${notification.event_name}`,
                      data: notification.data,
                    })}
              </Typography>
              {notification.data?.download_url && (
                <Button
                  variant="contained"
                  sx={submitButton}
                  href={notification.data.download_url}
                  download
                  onClick={(event) => {
                    event.stopPropagation();
                    if (!readAt) {
                      getNotification();
                      setReadAt("read");
                    }
                  }}
                >
                  Download
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className="timestamp">
              <Badge color="error" variant="dot" invisible={!!readAt} />
              <Tooltip
                title={Intl.DateTimeFormat().resolvedOptions().timeZone}
                placement="top"
                arrow
              >
                <span>
                  {longShortDateTime(notification.created_at, {
                    language: i18n.language,
                  })}
                </span>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Link>
  );
}
