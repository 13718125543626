import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  Stack,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from "@mui/material";
import { formCheckBox, fbold, danger } from "../../../sharedStyles";
import ErasableTextField from "../../../ErasableTextField";
import {
  overrideGradeInput,
  overrideGradeContainer,
  overrideGradeInfoInput,
  overrideGradeWarning,
} from "./OverrideGrade.styles";
import useGrade from "../../../../hooks/useGrade";
import { renderError } from "../../../../utils/constants/forms";
import { isNumber } from "../../../../utils/constants/utils";

export default function OverrideGrade({
  activeGrade,
  specialMarks,
  setOverrideError,
  overrideError,
}) {
  const isOverridden = activeGrade?.termGrade?.overridden;
  const [isSelected, setSelected] = useState(isOverridden || false);

  const {
    register,
    watch,
    setError,
    formState: { errors },
    setValue,
  } = useFormContext();

  const overriddenGradeValue = watch("overriddenGrade");
  const isIncomplete = watch("overrideIncomplete");

  const grade = useGrade(
    overriddenGradeValue,
    100,
    activeGrade?.termGrade?.klass?.grading_scale?.marking_codes,
    specialMarks
  );

  const handleOverrideIncomplete = (e) => {
    setValue("overrideIncomplete", e.target.checked);
  };

  useEffect(() => {
    if (
      watch("overridden") &&
      (overriddenGradeValue > 100 || !isNumber(overriddenGradeValue)) &&
      !isIncomplete
    ) {
      setOverrideError(true);
      setError("overriddenGrade", {
        type: "custom",
        message: "Overridden grade must be between 0 and 100.",
      });
    } else {
      setOverrideError(false);
    }
  }, [overriddenGradeValue, isIncomplete]);

  useEffect(() => {
    if (activeGrade?.termGrade?.override_incomplete) {
      setValue("overrideIncomplete", true);
    }
  }, [activeGrade?.termGrade?.override_incomplete]);

  useEffect(() => {
    if (isIncomplete) {
      setValue("overriddenGrade", "");
    }
  }, [isIncomplete]);

  return (
    <Box>
      <FormControl fullWidth sx={formCheckBox}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={activeGrade?.termGrade?.overridden || false}
              onClick={() => setSelected(!isSelected)}
              {...register("overridden")}
            />
          }
          label={
            <Box>
              <Typography>Override Grade</Typography>
            </Box>
          }
          disabled={activeGrade?.readOnly}
        />
      </FormControl>
      {isSelected && (
        <Box>
          <Stack direction="row" sx={overrideGradeContainer}>
            <Typography>Publish final grade as</Typography>

            {!isIncomplete ? (
              <ErasableTextField
                id="overriddenGrade"
                label="Grade"
                type="text"
                inputSx={overrideGradeInput}
                isRequired
                hasError={false}
                defaultValue={
                  isOverridden ? activeGrade?.termGrade?.posted_grade : ""
                }
              />
            ) : (
              <Typography sx={{ ...fbold, ml: 1 }}>Incomplete</Typography>
            )}

            {activeGrade?.termGrade?.klass?.grading_scale?.name === "3-8" && (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                  <Grid item xs={3} alignItems="center" display="flex">
                    {!isIncomplete && (
                      <Typography sx={fbold}>{grade}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={
                            typeof isIncomplete === "undefined"
                              ? activeGrade?.termGrade?.override_incomplete
                              : isIncomplete
                          }
                          onChange={handleOverrideIncomplete}
                        />
                      }
                      label="Mark class as Incomplete"
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Stack>

          {overrideError && (
            <Typography sx={{ ...danger, mb: 2 }}>
              Overridden grade must be between 0 and 100.
            </Typography>
          )}

          <Box>
            <ErasableTextField
              id="override_comment"
              label="Override Information"
              fullWidth
              type="text"
              isMultiline
              lines={3}
              inputSx={overrideGradeInfoInput}
              isRequired
              hasError={errors?.override_comment && true}
              defaultValue={
                isOverridden ? activeGrade?.termGrade?.override_comment : ""
              }
            />
            <Typography sx={overrideGradeWarning}>
              Only visible to administration
            </Typography>
            {errors?.override_comment &&
              renderError(errors?.override_comment.message)}
          </Box>
        </Box>
      )}
    </Box>
  );
}
