import axios from "../utils/axios-util";

const classScheduleEndpoint = "/api/klass_schedules";

const fetchAllKlassSchedules = async (params) => {
  const { data } = await axios.get(classScheduleEndpoint, params);

  if (!data.data) {
    console.error("Error fetching Class Schedules from the API");
    return [];
  }
  return data;
};

const fetchAllBySchoolYear = async (params) => {
  const { data } = await axios.get(
    `${classScheduleEndpoint}/school-year`,
    params
  );

  if (!data.data) {
    console.error("Error fetching Class Schedules from API");
    return [];
  }
  return data;
};

const fetchClassSchedule = async (
  schoolId,
  classScheduleId,
  date,
  classId,
  teacherId,
  studentId,
  order,
  orderBy,
  track,
  gradingScale
) => {
  const url = new URL(
    `${window.location.origin}${classScheduleEndpoint}/${classScheduleId}`
  );
  if (date) {
    url.searchParams.append("date", date);
  }

  if (classId) {
    url.searchParams.append("klass_id", classId);
  }

  if (teacherId) {
    url.searchParams.append("teacher_id", teacherId);
  }

  if (studentId) {
    url.searchParams.append("student_id", studentId);
  }

  if (order) {
    url.searchParams.append("order", order);
  }

  if (orderBy) {
    url.searchParams.append("order_by", orderBy);
  }

  if (track) {
    url.searchParams.append("track", track);
  }

  const { data } = await axios.get(`${url.href}`, {
    params: { school_id: schoolId, grading_scale: gradingScale },
  });

  if (!data.data) {
    console.error("Error fetching Class Schedules from the API");
    return [];
  }
  return data;
};

const fetchFiltersBySchedule = async (classScheduleId, schoolId) => {
  const { data } = await axios.get(
    `${classScheduleEndpoint}/${classScheduleId}/filters`,
    {
      params: { school_id: schoolId },
    }
  );

  if (!data.data) {
    console.error("Error fetching Class Schedules from the API");
    return [];
  }
  return data;
};

const create = async (params) => {
  const { data } = await axios.post(classScheduleEndpoint, params);
  if (!data.data) {
    console.error("Error creating class schedule");
    return [];
  }
  return data;
};

const update = async (classSchedule) => {
  const { data } = await axios.put(
    `${classScheduleEndpoint}/${classSchedule.id}`,
    classSchedule
  );
  if (!data.data) {
    console.error("Error updating class schedule");
    return [];
  }
  return data;
};

const remove = async (id) => {
  const { data } = await axios.delete(`${classScheduleEndpoint}/${id}`);
  if (!data.data) {
    console.error("Error deleting class schedule");
    return [];
  }
  return data;
};

const fetchKlasses = async (klassScheduleId, params) => {
  const { data } = await axios.get(
    `${classScheduleEndpoint}/${klassScheduleId}/klasses`,
    params
  );
  if (!data.data) {
    console.error("Error fetching class from the API");
    return [];
  }
  return data;
};

export default {
  fetchAllKlassSchedules,
  fetchClassSchedule,
  fetchFiltersBySchedule,
  fetchAllBySchoolYear,
  create,
  update,
  remove,
  fetchKlasses,
};
