import { Grid, Typography } from "@mui/material";
import React from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export default function FieldLayout({ field }) {
  if (!!field.label && field.type === "checkbox-field") {
    return (
      <Grid item container xs={12} key={`${field.id}-${field.fieldId}`}>
        <Grid item xs={3.5} display="flex">
          {field.response ? (
            <CheckBoxIcon fontSize="small" sx={{ mr: 1, color: "#2196F3" }} />
          ) : (
            <CheckBoxOutlineBlankIcon fontSize="small" sx={{ mr: 1 }} />
          )}{" "}
          <Typography variant="body2" fontWeight={600}>
            {field.label}
          </Typography>
        </Grid>
        <Grid item xs={8.5} />
      </Grid>
    );
  }

  if (!!field.label && field.type === "signature-field") {
    return (
      <Grid item container xs={12} key={`${field.id}-${field.fieldId}`}>
        <Grid item xs={3.5} display="flex">
          <Typography variant="body2" fontWeight={600}>
            {field.label}
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <img
            src={field?.response?.url}
            style={{ width: "100px" }}
            alt="signature"
          />
        </Grid>
      </Grid>
    );
  }

  if (!!field.label && field.type === "description-area") {
    return (
      <Grid item container xs={12} key={`${field.id}-${field.fieldId}`}>
        <Grid item xs={3.5}>
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{ wordBreak: "break-word" }}
          >
            {field.label}:
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: field.properties.descriptionText,
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    !!field.label && (
      <Grid item container xs={12} key={`${field.id}-${field.fieldId}`}>
        <Grid item xs={3.5}>
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{ wordBreak: "break-word" }}
          >
            {field.label}:
          </Typography>
        </Grid>
        <Grid item xs={8.5}>
          {field.label.toLowerCase().includes("date") ? (
            <Typography>{field.response?.split("T")[0]}</Typography>
          ) : (
            <Typography>{field.response}</Typography>
          )}
        </Grid>
      </Grid>
    )
  );
}
