import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import {
  cancelButton,
  submitButton,
} from "../../FormBuilder/FormBuilder.style";
import { renderError } from "../../../../../utils/constants/forms";

export default function SignatureField({ field, properties, isReadOnly }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [signURL, setSignURL] = useState(null);
  const {
    setValue,
    register,
    trigger,
    formState: { errors, isSubmitted },
  } = useFormContext();

  let sigCanvas = {};

  const handleModal = (status) => {
    setModalOpen(status);
  };

  const clear = () => {
    sigCanvas.clear();
  };

  const add = () => {
    const file = sigCanvas.getTrimmedCanvas().toDataURL("image/png");
    setSignURL(file);
    setValue(field.fieldId, file);
    setModalOpen(false);
  };

  const remove = () => {
    setSignURL(null);
    setValue(field.fieldId, null);
  };

  useEffect(() => {
    setSignURL(field?.response?.url);
  }, []);

  useEffect(() => {
    if (isSubmitted) trigger(field.fieldId);
  }, [field.isValid]);

  return (
    <Box padding={1}>
      {signURL ? (
        <Stack justifyContent="center" alignItems="center">
          <img src={signURL} style={{ width: "100px" }} alt="signature" />
          {!isReadOnly && (
            <Typography
              onClick={() => remove()}
              sx={{ cursor: "pointer" }}
              align="center"
            >
              Remove
            </Typography>
          )}
        </Stack>
      ) : (
        <Button sx={submitButton} onClick={() => handleModal(true)}>
          {field.properties.name || "Add Digital Signature"}
        </Button>
      )}

      <Modal open={modalOpen} onClose={() => handleModal(false)}>
        <Box
          sx={{
            width: 1000,
            height: 600,
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            {...register(field.fieldId, {
              required:
                (field.response === null ||
                  signURL === null ||
                  signURL === undefined) &&
                properties.isRequired &&
                "This field is required.",
              validate: properties.isRequired
                ? () => signURL !== null && signURL !== undefined
                : () => true,
            })}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <SignatureCanvas
              ref={(ref) => {
                sigCanvas = ref;
              }}
              canvasProps={{
                width: 800,
                height: 400,
                style: {
                  border: "1px solid black",
                },
              }}
            />

            <Stack direction="row">
              <Button sx={submitButton} onClick={() => add()}>
                Add Signature
              </Button>
              <Button sx={cancelButton} onClick={() => clear()}>
                Clear
              </Button>
              <Button sx={cancelButton} onClick={() => handleModal(false)}>
                Cancel
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>

      {errors[field.fieldId] &&
        (signURL === null || signURL === undefined) &&
        renderError("Signature is required")}
    </Box>
  );
}
