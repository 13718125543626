import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { formatDateTime } from "../../../../utils/constants/utils";
import QontoStepIcon from "../../../QontoStepIcon";

export default function NoteItem({
  note,
  flagNote,
  index,
  key,
  setNoteItem,
  setAddNoteDrawerOpen,
}) {
  const [hovering, setHovering] = useState(false);

  return (
    <Grid
      key={key}
      item
      md={12}
      container
      alignItems="center"
      columnSpacing={2}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Grid
        container
        item
        md={11}
        p={2}
        border="1px solid #F6F6F6"
        boxShadow="9px 0px 24px -21px rgba(0, 0, 0, 0.25)"
        borderRadius={1}
      >
        <Grid item md={10}>
          <Typography
            fontSize={14}
          >{`${note.staff.first_name} ${note.staff.last_name}`}</Typography>
          <Typography>
            <strong>Date:</strong> {formatDateTime(note.created_at)}
          </Typography>
        </Grid>
        <Grid item md={2} sx={{ opacity: hovering ? 1 : 0 }}>
          <Button
            variant="text"
            sx={{
              ml: 1,
              opacity: hovering ? 1 : 0,
              transition: "0.3s",
              color: "#2196F3",
              textTransform: "none",
              fontSize: "15px",
            }}
            onClick={() => {
              setNoteItem(note);
              setAddNoteDrawerOpen(true);
            }}
          >
            Edit
          </Button>
        </Grid>
        <Grid item md={12}>
          <Typography
            fontSize={14}
            mt={1}
            dangerouslySetInnerHTML={{ __html: note.description }}
          />
        </Grid>
      </Grid>
      <Grid item md={1}>
        <Box
          onClick={() => {
            flagNote(note, index);
          }}
        >
          <QontoStepIcon checked={note.flag} />
        </Box>
      </Grid>
    </Grid>
  );
}
