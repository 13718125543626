import React from "react";
import { Box, Grid } from "@mui/material";
import { my30 } from "../../../sharedStyles";
import { container } from "../../../../views/SocialDevelopment.styles";
import CanvasSettingsAbbreviation from "./CanvasSettingsAbbreviation";
import CanvasSettingsExportMode from "./CanvasSettingsExportMode";

export default function CanvasSettings({ integrationInfo, currentSchool }) {
  return (
    <Box sx={{ ...my30, width: "50%", justifyContent: "center" }}>
      <Box sx={[container, { borderTop: "none" }]}>
        <Grid
          container
          item
          xs={12}
          mt={2}
          sx={{ py: "30px", mt: 0, borderBottom: "1px solid #BFC7CA" }}
        >
          <CanvasSettingsAbbreviation currentSchool={currentSchool} />
        </Grid>

        <CanvasSettingsExportMode integrationInfo={integrationInfo} />
      </Box>
    </Box>
  );
}
