import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { CircularProgress, Container, Stack } from "@mui/material";
import IntegrationSetup from "./IntegrationSetup";
import CanvasSettings from "./CanvasSettings/CanvasSettings";
import canvasService from "../../../service/canvasService";
import { loader } from "../../sharedStyles";

export default function CanvasIntegration() {
  const [loading, setLoading] = useState(true);
  const [integrationInfo, setIntegrationInfo] = useState(null);
  const [currentSchool] = useOutletContext();

  const getIntegrationInfo = async () => {
    const response = await canvasService.returnIntegrationInfo();

    if (response) {
      setIntegrationInfo(response);
    }

    setLoading(false);
  };

  useEffect(() => {
    getIntegrationInfo();
  }, []);

  if (loading) {
    return <CircularProgress size={100} sx={loader} />;
  }

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between">
        <IntegrationSetup
          integrationInfo={integrationInfo}
          currentSchool={currentSchool}
        />
        {integrationInfo && (
          <CanvasSettings
            integrationInfo={integrationInfo}
            currentSchool={currentSchool}
          />
        )}
      </Stack>
    </Container>
  );
}
