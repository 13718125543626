import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import GenericSubmitButton from "../../../GenericSubmitButton";
import {
  bgWhite,
  font20,
  formButtons,
  formContainer,
  formHeader,
  formTitle,
  loader,
  my15,
} from "../../../sharedStyles";
import schoolYearService from "../../../../service/schoolYearService";
import canvasCoursesService from "../../../../service/canvasCoursesService";

export default function CanvasCloneCourseForm({
  handleCloseDrawer,
  snackbarContext,
}) {
  const [currentSchool] = useOutletContext();
  const [schoolYears, setSchoolYears] = useState([]);
  const [currentSchoolYear, setCurrentSchoolYear] = useState(null);
  const methods = useForm();

  const onSubmit = (data) => {
    const response = canvasCoursesService.cloneCanvasCourses(data);

    if (response) {
      handleCloseDrawer();
      snackbarContext.setSnackbar({
        message: "Courses cloned successfully.",
        severity: "success",
        open: true,
      });
      window.location.reload();
    }
  };

  const getSchoolYears = async () => {
    const response = await schoolYearService.fetchAll({
      params: {
        school_id: currentSchool.id,
      },
    });
    if (response.data) {
      setSchoolYears(response.data.school_years);
    }
  };

  const getCurrentSchoolYear = async () => {
    const response = await schoolYearService.activeSchoolYear();

    if (response.data) {
      setCurrentSchoolYear(response.data);
    }
  };

  useEffect(() => {
    getSchoolYears();
    getCurrentSchoolYear();
  }, []);

  if (schoolYears.length === 0 && !currentSchoolYear) {
    return <CircularProgress size={100} sx={loader} />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Typography sx={formTitle}>Clone Canvas Courses</Typography>
          <Stack direction="row">
            <Button sx={formButtons} onClick={() => handleCloseDrawer()}>
              Cancel
            </Button>

            <GenericSubmitButton text="Save" submittingText="Saving..." />
          </Stack>
        </Stack>

        <Box sx={formContainer}>
          <Stack
            direction="row"
            sx={{ borderBottom: "1px solid #BFC7CA", py: "15px" }}
          >
            <Box sx={{ width: "50%", mr: "10px" }}>
              <Typography sx={font20}>Clone from</Typography>
              <Box>
                <FormControl sx={my15} fullWidth>
                  <InputLabel sx={bgWhite} id="from">
                    Previous School year
                  </InputLabel>
                  <Select
                    labelId="from"
                    id="from"
                    {...methods.register("from", { required: true })}
                    required
                  >
                    {schoolYears
                      .filter((sy) => sy.id !== currentSchoolYear.id)
                      .map((schoolYear) => (
                        <MenuItem key={schoolYear.id} value={schoolYear.id}>
                          {schoolYear.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography sx={font20}>Target</Typography>
              <Box>
                <FormControl sx={my15} fullWidth>
                  <InputLabel sx={bgWhite} id="target">
                    Target School Year
                  </InputLabel>
                  <Select
                    labelId="target"
                    id="target"
                    {...methods.register("target", { required: true })}
                    defaultValue={currentSchoolYear.id}
                  >
                    <MenuItem value={currentSchoolYear.id}>
                      {currentSchoolYear.name}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Stack>
        </Box>
      </form>
    </FormProvider>
  );
}
