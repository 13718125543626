import React, { useContext } from "react";
import { Box, SpeedDial, SpeedDialAction } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import ClassIcon from "@mui/icons-material/Class";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import { useLocation } from "react-router-dom";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EventIcon from "@mui/icons-material/Event";
import {
  Add,
  Bolt,
  DynamicFormOutlined,
  PersonAddAlt,
  Print,
  Sync,
  Delete,
  TextIncrease,
  VisibilityOutlined,
} from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  quickBarSpeedDial,
  quickBarSpeedDialAction,
  quickBarSpeedDialActionDisabled,
  quickBarSpeedDialActionFab,
  quickBarSpeedDialFab,
  quickBarSpeedDialActionFabDisabled,
} from "./QuickBar.style";
import { QuickBarContext } from "../context/QuickBarContext";
import { spin } from "./sharedStyles";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";

export default function QuickBar({
  handleKlassesDrawer,
  handleAssignmentsDrawer,
  handleTermsDrawer,
  handleDayTemplateDrawer,
  handleSchoolDaysDrawer,
  handleClassScheduleDrawer,
  handleBulkEnrollDrawer,
  handleMessagesDrawer,
  handleCourseDrawer,
  handleTranscriptDrawer,
  handleGenerateTranscriptDrawer,
  handleGraduationPlanDrawer,
  handlePlanRequirementDrawer,
  currentSchool,
}) {
  const [open, setOpen] = React.useState(false);
  let speedDialCloseTimeout = null;
  const handleOpen = () => setOpen(true);
  const handleClose = (_, reason) => {
    if (reason === "mouseLeave") {
      speedDialCloseTimeout = setTimeout(() => setOpen(false), 2000);
      return;
    }

    setOpen(false);
  };
  const handleMouseEnter = () => clearTimeout(speedDialCloseTimeout);
  const location = useLocation();
  const quickBarContext = useContext(QuickBarContext);
  const studentPathRegex = /^.*\/students\/([^/]+).*/;
  const isSyncing = quickBarContext.syncAssignments;
  const isFloridaConferenceSchool =
    currentSchool?.conference?.org_id === "ANTB11";

  const { hasPermission } = useContext(PermissionsContext);

  const actionsByRoute = () => {
    if (location.pathname.includes("/data-view")) {
      return [
        {
          icon: <Print />,
          name: "Print",
          handleClick: () => {
            quickBarContext.firePrintReport();
          },
          permission: true,
        },
      ];
    }

    if (location.pathname.includes("/admissions/students")) {
      return [
        {
          icon: <Bolt />,
          name: "Re-Enroll Students",
          handleClick: () => {
            quickBarContext.fireReEnrollStudents();
          },
          permission: true,
        },
        {
          icon: <Add />,
          name: "Add new inquiry",
          handleClick: () => {
            quickBarContext.fireAddNewInquiry();
          },
          permission: true,
        },
      ];
    }

    if (
      location.pathname.match(studentPathRegex) &&
      !location.pathname.includes("admissions") &&
      !location.pathname.includes("/subjects")
    ) {
      const studentQuickBarActions = [
        {
          icon: <MailOutlineIcon />,
          name: "Send Message",
          handleClick: () => handleMessagesDrawer(true),
        },
        {
          icon: <EventIcon />,
          name: "Add Event",
          handleClick: () => quickBarContext.fireAddStudentEvent(true),
        },
        {
          icon: <LocalHospitalIcon />,
          name: "Add Medical Record",
          handleClick: () => quickBarContext.fireAddStudentMedicalRecord(true),
          permission: hasPermission(
            APP_PERMISSIONS.STUDENT_MEDICAL,
            PERMISSION_TYPES.MANAGE
          ),
        },
        {
          icon: <ContactPhoneIcon />,
          name: "Add Emergency Contact",
          handleClick: () => quickBarContext.showAddEmergencyContacts(true),
          permission: hasPermission(
            APP_PERMISSIONS.STUDENT_ACCOUNT,
            PERMISSION_TYPES.MANAGE
          ),
        },
        {
          icon: <SupervisorAccountIcon />,
          name: "Add Parent/Guardian",
          handleClick: () => quickBarContext.showAddParents(true),
          permission: hasPermission(
            APP_PERMISSIONS.STUDENT_ACCOUNT,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];

      if (
        location.pathname.includes("students") &&
        location.pathname.includes("transcripts")
      )
        studentQuickBarActions.push(
          {
            icon: <AssignmentIcon />,
            name: "Add Transcript Record",
            handleClick: () => handleTranscriptDrawer(true),
            permission: hasPermission(
              APP_PERMISSIONS.STUDENT_TRANSCRIPTS,
              PERMISSION_TYPES.MANAGE
            ),
          },
          {
            icon: <TextIncrease />,
            name: "Generate from Term Grades",
            handleClick: () => handleGenerateTranscriptDrawer(true),
            permission: hasPermission(
              APP_PERMISSIONS.STUDENT_TRANSCRIPTS,
              PERMISSION_TYPES.MANAGE
            ),
          },
          {
            icon: <VisibilityOutlined />,
            name: "Preview transcript",
            handleClick: () => quickBarContext.firePreviewTranscript(true),
            permission: hasPermission(
              APP_PERMISSIONS.STUDENT_TRANSCRIPTS,
              PERMISSION_TYPES.MANAGE
            ),
          }
        );

      if (
        location.pathname.includes("students") &&
        location.pathname.includes("profile")
      )
        studentQuickBarActions.push({
          icon: <DescriptionOutlinedIcon />,
          name: "Add Files",
          handleClick: () => quickBarContext.fireAddStudentFiles(true),
          permission: hasPermission(
            APP_PERMISSIONS.STUDENT_PROFILE,
            PERMISSION_TYPES.MANAGE
          ),
        });
      if (
        location.pathname.includes("students") &&
        location.pathname.includes("portfolio")
      )
        studentQuickBarActions.push({
          icon: <DescriptionOutlinedIcon />,
          name: "Add Files",
          handleClick: () => quickBarContext.fireAddStudentPortfolioFiles(true),
        });

      if (
        location.pathname.includes("students") &&
        location.pathname.includes("community-service")
      )
        studentQuickBarActions.push({
          icon: <AddIcon />,
          name: "Add Community Service",
          handleClick: () => quickBarContext.fireAddCommunityService(true),
          permission: hasPermission(
            APP_PERMISSIONS.STUDENT_COMMUNITY_SERVICE,
            PERMISSION_TYPES.MANAGE
          ),
        });
      return studentQuickBarActions;
    }
    if (location.pathname.includes("/term-grade/class-grade")) {
      return [
        {
          icon: <AssignmentIcon />,
          name: "Post term grades",
          handleClick: () => quickBarContext.firePostTermGrades(),
          permission: hasPermission(
            APP_PERMISSIONS.KLASSES_TERM_GRADES,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/term-grade/skills")) {
      return [
        {
          icon: <AssignmentIcon />,
          name: "Post skills",
          handleClick: () => quickBarContext.firePostSkills(),
          permission: hasPermission(
            APP_PERMISSIONS.KLASSES_TERM_GRADES,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/social-development")) {
      return [
        {
          icon: <AssignmentIcon />,
          name: "Post skills",
          handleClick: () => quickBarContext.firePostSdSkills(),
          permission: hasPermission(
            APP_PERMISSIONS.SOCIAL_DEVELOPMENT_WORK_ETHIC,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/messages")) {
      const newMessageBtn = {
        icon: <MailOutlineIcon />,
        name: "New Message",
        handleClick: () => handleMessagesDrawer(true),
      };

      if (location.pathname.includes("/conversation")) {
        return [
          {
            icon: <FolderOutlinedIcon />,
            name: "Delete Conversation",
            handleClick: () => quickBarContext.fireDeleteConversation(),
          },
          newMessageBtn,
        ];
      }

      return [newMessageBtn];
    }

    if (location.pathname.includes("/terms")) {
      return [
        {
          icon: <DescriptionOutlinedIcon />,
          name: "New Term",
          handleClick: () => handleTermsDrawer(true),
          permission: hasPermission(
            APP_PERMISSIONS.TERMS,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/day-templates")) {
      return [
        {
          icon: <DescriptionOutlinedIcon />,
          name: "New Day Template",
          handleClick: () => handleDayTemplateDrawer(true),
          permission: hasPermission(
            APP_PERMISSIONS.DAY_TEMPLATE,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (
      location.pathname.includes("/report-cards") &&
      !location.pathname.includes("dashboard")
    ) {
      return [
        {
          icon: <PersonOutlinedIcon />,
          name: "Publish Report Card",
          handleClick: () => quickBarContext.firePublishReportCards(),
        },
        {
          icon: <AccountCircleIcon />,
          name: "Unpublish Report Card",
          handleClick: () => quickBarContext.fireUnpublishReportCards(),
        },
        // {
        //   icon: <DescriptionOutlinedIcon />,
        //   name: "Distribute Report Cards",
        //   handleClick: () => quickBarContext.fireDistributeReportCards(),
        // },
        {
          icon: <FileDownloadOutlinedIcon />,
          name: "Download Report Cards",
          handleClick: () => quickBarContext.fireDownloadReportCards(),
        },
      ];
    }

    if (location.pathname.includes("/pk-development-tracker")) {
      return [
        {
          icon: <AssignmentIcon />,
          name: "Post term grades",
          handleClick: () => quickBarContext.firePostPKTermGrades(),
          permission: hasPermission(
            APP_PERMISSIONS.PK_TRACKER,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (
      location.pathname.includes("/students") &&
      location.pathname.includes("profile")
    ) {
      return [
        {
          icon: <ClassIcon />,
          name: "Add Files",
          handleClick: () => quickBarContext.fireAddStudentFiles(true),
        },
      ];
    }

    if (location.pathname.includes("/conversation")) {
      return [
        {
          icon: <PersonOutlinedIcon />,
          name: "Publish Report Card",
          handleClick: () => quickBarContext.firePublishReportCards(),
        },
        {
          name: "Unpublish Report Card",
          handleClick: () => quickBarContext.fireUnpublishReportCards(),
        },
      ];
    }

    if (location.pathname.includes("/school-days")) {
      return [
        {
          icon: <DescriptionOutlinedIcon />,
          name: "Bulk Edit Days",
          handleClick: () => handleSchoolDaysDrawer(true),
          permission: hasPermission(
            APP_PERMISSIONS.SCHOOL_DAYS,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/class-schedules")) {
      if (location.pathname.includes("/classes")) {
        return [
          {
            icon: <DescriptionOutlinedIcon />,
            name: "Bulk Enroll",
            handleClick: () => handleBulkEnrollDrawer(true),
            permission: hasPermission(
              APP_PERMISSIONS.KLASS_SCHEDULES,
              PERMISSION_TYPES.MANAGE
            ),
          },
          {
            icon: <PushPinOutlinedIcon />,
            name: "Add Class",
            handleClick: () => handleKlassesDrawer(true),
            permission: hasPermission(
              APP_PERMISSIONS.KLASS_SCHEDULES,
              PERMISSION_TYPES.MANAGE
            ),
          },
        ];
      }
      return [
        {
          icon: <DescriptionOutlinedIcon />,
          name: "New Class Schedule",
          handleClick: () => handleClassScheduleDrawer(true),
        },
      ];
    }

    if (
      location.pathname.includes("/courses") &&
      !location.pathname.includes("/school/canvas-integration/courses")
    ) {
      if (currentSchool?.has_secondary_students) {
        return [
          {
            icon: <DescriptionOutlinedIcon />,
            name: "Add Course",
            handleClick: () => handleCourseDrawer(true),
            permission: hasPermission(
              APP_PERMISSIONS.COURSES,
              PERMISSION_TYPES.MANAGE
            ),
          },
        ];
      }
      return [];
    }

    if (
      location.pathname.includes("/assignments") ||
      location.pathname.includes("/current-subjects") ||
      location.pathname.includes("/current-classes") ||
      (location.pathname.includes("/subjects") &&
        (location.pathname.includes("/students") ||
          location.pathname.includes("/gradebook")))
    ) {
      const assignmentActions = [
        {
          icon: <AddIcon />,
          name: "Add Assignment",
          handleClick: () => handleAssignmentsDrawer(true),
          permission: hasPermission(
            APP_PERMISSIONS.KLASSES_ASSIGNMENTS,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];

      if (quickBarContext.hasGoogleAssignments) {
        assignmentActions.push({
          icon: <SyncIcon {...(isSyncing && { sx: spin })} />,
          isSync: true,
          name: isSyncing
            ? "Syncing assignments, please wait..."
            : "Refresh Google Classroom",
          handleClick: () =>
            isSyncing ? null : quickBarContext.fireSyncAssignments(),
        });
      }

      if (
        location.pathname.includes("/subjects") &&
        !location.pathname.includes("/students") &&
        !location.pathname.includes("/gradebook")
      ) {
        assignmentActions.push({
          icon: <Delete />,
          name: "Delete Assignments",
          handleClick: () => quickBarContext.fireDeleteAssignments(),
          permission: hasPermission(
            APP_PERMISSIONS.KLASSES_ASSIGNMENTS,
            PERMISSION_TYPES.MANAGE
          ),
        });
      }

      return assignmentActions.reverse();
    }

    if (location.pathname.includes("/new-gradebook")) {
      return [
        {
          icon: <AddIcon />,
          name: "Add Assignment",
          handleClick: () => handleAssignmentsDrawer(true),
          permission: hasPermission(
            APP_PERMISSIONS.KLASSES_ASSIGNMENTS,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (
      location.pathname.includes("/staff/staffs") ||
      location.pathname.includes("/staff/temporary-staff")
    ) {
      const actionsList = [
        {
          icon: <Sync {...(quickBarContext.syncStaffs && { sx: spin })} />,
          name: quickBarContext.syncStaffs
            ? "Syncing Staffs, please wait..."
            : "Sync Staffs",
          isSync: true,
          handleClick: () =>
            quickBarContext.syncStaffs
              ? {}
              : quickBarContext.fireSyncStaffs(true),
          permission: hasPermission(
            APP_PERMISSIONS.STAFF_LIST,
            PERMISSION_TYPES.MANAGE
          ),
        },
        {
          icon: <PersonAddAlt />,
          name: "Add Temporary Staff",
          handleClick: () => quickBarContext.fireAddTemporaryStaff(),
          permission: hasPermission(
            APP_PERMISSIONS.ADD_TEMPORARY_STAFF,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
      if (isFloridaConferenceSchool) {
        actionsList.push({
          icon: <FileDownloadOutlinedIcon />,
          name: "Export Staff Data",
          handleClick: () => quickBarContext.fireExportStaff(),
          permission: hasPermission(
            APP_PERMISSIONS.STAFF_LIST,
            PERMISSION_TYPES.MANAGE
          ),
        });
        actionsList.push({
          icon: <CloudUploadIcon />,
          name: "Upload Staff Data",
          handleClick: () => quickBarContext.fireUploadStaff(),
          permission: hasPermission(
            APP_PERMISSIONS.STAFF_LIST,
            PERMISSION_TYPES.MANAGE
          ),
        });
      }

      return actionsList;
    }

    if (location.pathname.includes("/staff/")) {
      return [
        {
          icon: <ContactPhoneIcon />,
          name: "Add Emergency Contact",
          handleClick: () => quickBarContext.showAddEmergencyContacts(true),
          permission: hasPermission(
            APP_PERMISSIONS.STAFF_ACCOUNT,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (
      location.pathname.includes("/admissions/enrollments/enrolled-students") ||
      location.pathname.includes("/admissions/enrollments/unenrolled-students")
    ) {
      return [
        {
          icon: <Sync {...(quickBarContext.syncStudents && { sx: spin })} />,
          name: quickBarContext.syncStudents
            ? "Syncing Students, please wait..."
            : "Sync Students",
          isSync: true,
          handleClick: () =>
            quickBarContext.syncStudents
              ? {}
              : quickBarContext.fireSyncStudents(true),
        },
      ];
    }

    if (location.pathname.includes("/reports")) {
      return [
        {
          icon: <ContactPhoneIcon />,
          name: "Print Report",
          handleClick: () => quickBarContext.firePrintReport(true),
          permission: hasPermission(
            APP_PERMISSIONS.REPORT_SCREEN,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/school/security-groups")) {
      return [
        {
          icon: <AddIcon />,
          name: "Add Security Group",
          handleClick: () => {
            quickBarContext.showAddSecurityGroups();
          },
          permission: hasPermission(
            APP_PERMISSIONS.SECURITY,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/school/groups")) {
      return [
        {
          icon: <AddIcon />,
          name: "Add New Group",
          handleClick: () => {
            quickBarContext.showAddGroups();
          },
        },
      ];
    }

    if (location.pathname.includes("/admissions/setup")) {
      return [
        {
          icon: <FileCopyIcon />,
          name: "Duplicate Processes",
          handleClick: () => {
            quickBarContext.fireDuplicateProcesses();
          },
          permission: true,
        },
        {
          icon: <DynamicFormOutlined />,
          name: "Create new form",
          handleClick: () => {
            quickBarContext.fireCreateNewForm();
          },
          permission: true,
        },
        {
          icon: <Bolt />,
          name: "Create new process",
          handleClick: () => {
            quickBarContext.fireCreateNewProcess();
          },
          permission: true,
        },
      ];
    }

    if (location.pathname.includes("/school/gpa")) {
      return [
        {
          icon: <AddIcon />,
          name: "Add New Scale",
          handleClick: () => {
            quickBarContext.showAddGPAScale();
          },
          permission: hasPermission(
            APP_PERMISSIONS.GPA,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (
      location.pathname.includes("/school/graduation-plans/") &&
      location.pathname.includes("/requirements/")
    ) {
      return [];
    }

    if (location.pathname.includes("/school/graduation-plans/")) {
      return [
        {
          icon: <AddIcon />,
          name: "Add subject",
          handleClick: () => handlePlanRequirementDrawer(true),
          permission: hasPermission(
            APP_PERMISSIONS.GRADUATION_PLANS,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/school/graduation-plans")) {
      return [
        {
          icon: <AddIcon />,
          name: "Add plan",
          handleClick: () => handleGraduationPlanDrawer(true),
          permission: hasPermission(
            APP_PERMISSIONS.GRADUATION_PLANS,
            PERMISSION_TYPES.MANAGE
          ),
        },
      ];
    }

    if (location.pathname.includes("/school/canvas-integration/courses")) {
      return [
        {
          icon: <FileCopyIcon />,
          name: "Clone courses from previous year",
          handleClick: () => {
            quickBarContext.showCloneCanvasCourse();
          },
        },
      ];
    }

    return [];
  };

  if (
    (!location.pathname.includes("admissions") &&
      !location.pathname.includes("/subjects") &&
      location.pathname.split("/")?.slice(-1)[0]?.includes("students")) ||
    (location.pathname.includes("admissions") &&
      location.pathname.includes("student-details"))
  ) {
    return [];
  }

  const actions = actionsByRoute();

  if (actions?.length === 0) return <span />;

  return (
    <Box>
      <SpeedDial
        ariaLabel="Action Bar"
        direction="up"
        sx={quickBarSpeedDial}
        FabProps={{
          sx: quickBarSpeedDialFab,
        }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onMouseEnter={handleMouseEnter}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map(
          (action) =>
            (action.permission === undefined || action.permission) && (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.handleClick}
                tooltipOpen
                tooltipPlacement="right"
                sx={
                  (isSyncing && action.isSync) || quickBarContext.isAutoSaving
                    ? quickBarSpeedDialActionDisabled
                    : quickBarSpeedDialAction
                }
                FabProps={
                  (isSyncing && action.isSync) || quickBarContext.isAutoSaving
                    ? quickBarSpeedDialActionFabDisabled
                    : quickBarSpeedDialActionFab(action.handleClick)
                }
              />
            )
        )}
      </SpeedDial>
    </Box>
  );
}
