import React, { useState, useEffect, useContext } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { Container, Box, Pagination, Typography, Drawer } from "@mui/material";
import messagesService from "../../../service/messagesService";
import {
  mt25,
  paginationContent,
  rightDrawerContainer,
  textCenter,
} from "../../sharedStyles";
import DraftsRow from "./DraftsRow";
import NewMessage from "../NewMessage/NewMessage";
import SnackBarNotification from "../../SnackBarNotification";
import { QuickBarContext } from "../../../context/QuickBarContext";

export default function Drafts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [drafts, setDrafts] = useState([]);
  const { query } = useOutletContext();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(10);
  const [messageDrawerStatus, setMessageDrawerStatus] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });
  const quickbarContext = useContext(QuickBarContext);

  const params = useParams();
  const schoolId = params.school_id;

  const handleMessagesDrawer = (open = false) => {
    setMessageDrawerStatus(open);
  };

  const getDrafts = async () => {
    const response = await messagesService.listSentMessages({
      params: {
        query,
        count,
        page,
        type: "drafts",
        school_id: schoolId,
      },
    });
    if (response) {
      const queryPage = searchParams.get("page") || 1;
      const lastPage = response.pagination.total_pages;
      const currentPage = queryPage > lastPage ? lastPage : queryPage;
      const countPerPage = searchParams.get("count") || 10;
      setTotalPages(lastPage);
      setPage(currentPage || 1);
      setCount(countPerPage);
      setDrafts(response.data);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (page > 0 && query != null) {
      getDrafts();
    }
  }, [page, query, messageDrawerStatus]);

  useEffect(() => {
    if (!quickbarContext.messagesDrawer) {
      getDrafts();
    }
  }, [quickbarContext.messagesDrawer]);

  return (
    <Container maxWidth={false} sx={mt25}>
      <Container maxWidth="xl">
        {drafts.length > 0 ? (
          <>
            {drafts.map((message) => (
              <Box
                onClick={() => {
                  handleMessagesDrawer(true);
                  setSelectedDraft(message);
                }}
                key={message.id}
              >
                <DraftsRow message={message} />
              </Box>
            ))}

            <Pagination
              count={totalPages}
              page={parseInt(page, 10)}
              onChange={handleChangePage}
              sx={paginationContent}
            />
          </>
        ) : (
          <Box sx={textCenter}>
            <Typography>No messages</Typography>
          </Box>
        )}
      </Container>
      <Drawer anchor="right" open={messageDrawerStatus}>
        <Box sx={rightDrawerContainer}>
          <NewMessage
            handleMessagesDrawer={handleMessagesDrawer}
            schoolId={schoolId}
            setSnackBarOpen={setSnackBarOpen}
            messageDrawerStatus={messageDrawerStatus}
            draft={selectedDraft}
          />
        </Box>
      </Drawer>
      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Container>
  );
}
